import { request } from '@/utils/request';
import { ApiResponse, WrappedResult } from './types';

export default {
  getAll(params?: mpg.api.roles.GetParams): ApiResponse<WrappedResult<mpg.api.roles.Role[]>> {
    return request({
      method: 'get',
      url: '/roles',
      headers: {
        'Content-Type': 'application/json',
      },
      params,
    });
  },
  getOne(id: number): ApiResponse<WrappedResult<any>> {
    return request({
      method: 'get',
      url: `/roles/${id}`,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  },
  assignPermissions(id: number, permissionIds: number[]) {
    return request({
      method: 'post',
      url: `/roles/${id}/assign-permissions`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: { permissionIds },
    });
  },
  updatePermissions(id: number, permissionIds: number[]) {
    return request({
      method: 'post',
      url: `/roles/${id}/update-permissions`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: { permissionIds },
    });
  },
  revokePermissions(id: number, permissionIds: number[]) {
    return request({
      method: 'post',
      url: `/roles/${id}/revoke-permissions`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: { permissionIds },
    });
  },
  create(data: mpg.api.roles.RolesParams): ApiResponse<WrappedResult<mpg.api.roles.Role>> {
    return request({
      method: 'post',
      url: '/roles',
      headers: {
        'Content-Type': 'application/json',
      },
      data,
    });
  },
  update(id: number, data: mpg.api.roles.RolesParams): ApiResponse<WrappedResult<mpg.api.roles.Role>> {
    return request({
      method: 'patch',
      url: `/roles/${id}`,
      headers: {
        'Content-Type': 'application/json',
      },
      data,
    });
  },
  delete(roleId: number): ApiResponse<WrappedResult<any>> {
    return request({
      method: 'delete',
      url: `/roles/${roleId}`,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  },
};
