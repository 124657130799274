import { ApplicationSettings } from './components/table';

import {
  PERMISSION_OBJ_APPLICATION_SETTINGS,
  PERMISSION_READ,
} from '@/constants/permissions';

export const states = [
  {
    name: 'base-layout.applicationSettings',
    url: '/application-settings',
    component: ApplicationSettings,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_APPLICATION_SETTINGS, PERMISSION_READ],
      ],
    },
  },
];
