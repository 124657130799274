import { BulkAssignColorsForm } from './components/form';
import { ScheduleCalendar } from './components/calendar';
import { PERMISSION_CREATE, PERMISSION_OBJ_COLOR_CALENDAR, PERMISSION_READ } from '@/constants/permissions';

export const states = [
  {
    name: 'base-layout.color-calendar',
    url: '/color-calendar',
    component: ScheduleCalendar,
    data: {
      requiresAuth: true,
    },
    permissions: [[PERMISSION_OBJ_COLOR_CALENDAR, PERMISSION_READ]],
  },
  {
    name: 'base-layout.color-calendar.create',
    url: '/bulk-assign-colors',
    component: BulkAssignColorsForm,
    data: {
      requiresAuth: true,
    },
    permissions: [[PERMISSION_OBJ_COLOR_CALENDAR, PERMISSION_CREATE]],
  },
];
