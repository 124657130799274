import { FormattedMessage } from 'react-intl';
import { Space } from '@/components/antd';

import { DownloadAction } from '@/components/table/actions';
import { withPermissions } from '@/hocs/permission';
import { getFormattedDate } from '@/utils/moment';

const PermissionedDownloadAction = withPermissions([])(DownloadAction);

const Actions = (_: any, { id, name }: Omit<mpg.api.agreements.SignedAgreements, 'createdAt'>) => (
  <Space>
    <PermissionedDownloadAction id={id} name={name} />
  </Space>
);
export const columns = () => [
  {
    title: <FormattedMessage id="users.id" />,
    dataIndex: 'id',
  },
  {
    title: <FormattedMessage id="users.file-name" />,
    dataIndex: 'name',
  },
  {
    title: <FormattedMessage id="users.signed-date" />,
    dataIndex: 'createdAt',
    render: (value: string) => getFormattedDate(value),
  },
  {
    title: <FormattedMessage id="table.actions" />,
    render: Actions,
  },
];
