import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { PageHeader } from '@/components/antd';
import { applicationSettingsStore as store } from '@/stores';
import { ApplicationSettingsForm } from './form';

export const ApplicationSettings = observer(() => {
  const { loading, items, getApplicationSettings } = store;
  useEffect(() => {
    getApplicationSettings();
  }, []);

  return (
    <>
      <PageHeader className="r-antd-page-header" />
      <ApplicationSettingsForm loading={loading} items={items} />
    </>
  );
});
