import { UIViewInjectedProps } from '@uirouter/react';
import { observer } from 'mobx-react-lite';
import React from 'react';

import {
  PERMISSION_CREATE,
  PERMISSION_READ,
  PERMISSION_UPDATE,
} from '@/constants/permissions';

import { DictionariesForm, DictionaryTable } from './common';

export const getStates = (props: any) => {
  const {
    permissionObj,
    createState,
    editState,
    bulkEditState,
    listState,
    mainUrl,
  } = props;

  const Table = observer(({ transition }: any) => (
    <DictionaryTable
      {...{ ...props, transition }}
    />
  ));

  const Form = observer(({ transition }: UIViewInjectedProps) => (
    <DictionariesForm
      {...{ ...props, transition }}
    />
  ));

  return [
    {
      name: listState,
      url: mainUrl,
      component: Table,
      data: {
        requiresAuth: true,
        permissions: [
          [permissionObj, PERMISSION_READ],
        ],
      },
    },
    {
      name: createState,
      url: '/new',
      component: Form,
      data: {
        requiresAuth: true,
        permissions: [
          [permissionObj, PERMISSION_CREATE],
        ],
      },
    },
    {
      name: editState,
      url: '/:id/edit',
      component: Form,
      data: {
        requiresAuth: true,
        permissions: [
          [permissionObj, PERMISSION_UPDATE],
        ],
      },
    },
    ...bulkEditState ? [{
      name: bulkEditState,
      url: '/bulk-edit',
      component: Form,
      data: {
        requiresAuth: true,
        permissions: [
          [permissionObj, PERMISSION_UPDATE],
        ],
      },
    }] : [],
  ];
};
