import {
  useEffect, useState, DependencyList, EffectCallback,
} from 'react';

import { CollectionStore, RelCollectionStore } from '@/stores/collection';

interface LoadItemsOptions {
  limit: number;
}

export const defaultMassUpdateCashedStores = (cachedStores: CollectionStore<string | number, any, any>[], options: LoadItemsOptions) => {
  // TODO remove limit when will refactor store
  const promises = cachedStores.map(((cachedStore) => cachedStore.asyncItems(options)));
  return Promise.all(promises);
};

export const useMassUpdateCashedStores = (
  cachedStores: CollectionStore<string | number, any, any>[],
  deps: DependencyList = [],
  callback: () => any = () => undefined,
  settings?: any,
) => {
  const [loading, setLoading] = useState<boolean>(false);

  const effect: EffectCallback = () => {
    setLoading(true);

    defaultMassUpdateCashedStores(cachedStores, settings || { limit: 1000, sort: 'name:1' } as any)
      .then(() => setLoading(false))
      .finally(callback);
  };

  useEffect(effect, deps);

  return { loading, setLoading };
};

export const useMassUpdateCashedRelStores = (cachedStores: RelCollectionStore<string | number, any, any>[], relId: string | number, deps: DependencyList = []) => {
  const [loading, setLoading] = useState<boolean>(false);

  const effect: EffectCallback = () => {
    setLoading(true);

    Promise.all(cachedStores.map(((cachedStore) => cachedStore.asyncItems(relId, { limit: 10 }))))
      .then(() => setLoading(false));
  };

  useEffect(effect, deps);

  return { loading, setLoading };
};
