import React from 'react';
import { Tag } from 'antd';
import { Form, FormItemProps, Select, SelectProps } from '@/components/antd';

import { Ii18nLabel } from '@/types/common';

import { getFormItemLabel } from './utils';
import { CloseOutlined } from '@/components/icons';

const { Option } = Select;

interface DropdownProps extends SelectProps<any>, FormItemProps, Ii18nLabel {
  children: any;
  formState: any;
  description?: React.ReactNode;
  isDisabledTagsRemovable?: boolean;
}

const TagRender = ({ closable, label, ...props }) => (
  <Tag
    color="blue"
    className="ant-select-selection-item"
    closable
    closeIcon={
      <span className="ant-select-selection-item-remove">
        <CloseOutlined />
      </span>
    }
    {...props}
  >
    <span className="ant-select-selection-item-content">{label}</span>
  </Tag>
);

// tslint:disable-next-line:function-name
export function Dropdown({
  allowClear = true,
  options,
  name,
  label,
  i18nLabel,
  rules,
  showSearch = true,
  optionFilterProp = 'children',
  formState,
  description,
  isDisabledTagsRemovable,
  ...props
}: DropdownProps) {
  const fieldLabel = getFormItemLabel(i18nLabel, label);
  const getPopupContainer = trigger => trigger.parentElement;
  return (
    <Form.Item name={name} label={fieldLabel} rules={rules} extra={description}>
      <Select
        {...props}
        getPopupContainer={getPopupContainer}
        allowClear={allowClear}
        showSearch={showSearch}
        optionFilterProp={optionFilterProp}
        tagRender={isDisabledTagsRemovable ? null : tagProps => <TagRender {...tagProps} />}
      >
        {options.map((item: any) => (
          <Option key={item.value} disabled={item?.disabled} value={item.value}>
            {item.label}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
}
