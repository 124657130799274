import { UISref } from '@uirouter/react';
import React from 'react';
import { Button, Popconfirm, Tooltip } from '@/components/antd';

import { DeleteOutlined, DownloadOutlined, EditOutlined, EyeOutlined, HomeOutlined } from '@/components/icons';
import { VoidFnType } from '@/types/common';
import { getTranslatedString } from '@/utils/intl';
import { api } from '@/api';
import { downloadBlobFile } from '@/utils';

export interface EditActionProps {
  className?: string;
  icon?: any;
  uiSref: {
    to: string;
    params: Record<string, unknown>;
  };
  title?: string;
  tooltipTitle?: string;
}

export interface DeleteActionProps {
  onConfirm: VoidFnType;
  title?: string;
  disabled?: boolean;
}

const stopPropagationHandler = (event: React.MouseEvent) => {
  event.stopPropagation();
};

export const AddAction = ({ icon, uiSref: { to, params }, title, tooltipTitle }: EditActionProps) => (
  <UISref to={to} params={params}>
    <a onClick={stopPropagationHandler}>
      <Tooltip title={tooltipTitle ? getTranslatedString(`${tooltipTitle}`) : ''}>
        <Button className={title ? 'action-button' : ''} type="primary" shape={title ? 'round' : 'circle'} icon={icon}>
          {title}
        </Button>
      </Tooltip>
    </a>
  </UISref>
);

export const EditAction = ({ uiSref: { to, params }, title }: EditActionProps) => (
  <UISref to={to} params={params}>
    <a onClick={stopPropagationHandler}>
      <Tooltip title={getTranslatedString('table.edit')}>
        <Button type="primary" shape={title ? 'round' : 'circle'} icon={<EditOutlined />}>
          {title}
        </Button>
      </Tooltip>
    </a>
  </UISref>
);

export const DownloadAction = ({ id, name }: Omit<mpg.api.agreements.SignedAgreements, 'createdAt'>) => {
  const handleDownload = async () => {
    const resp = await api.agreements.downloadAgreement(id).source;
    downloadBlobFile(resp, name);
  };
  return (
    <Tooltip title={getTranslatedString('table.edit')}>
      <Button onClick={() => handleDownload()} type="primary" shape="circle" icon={<DownloadOutlined />} />
    </Tooltip>
  );
};

export const ReadAction = ({ uiSref: { to, params }, title }: EditActionProps) => (
  <UISref to={to} params={params}>
    <a onClick={stopPropagationHandler}>
      <Tooltip title={getTranslatedString('table.view')}>
        <Button type="primary" shape={title ? 'round' : 'circle'} icon={<EyeOutlined />}>
          {title}
        </Button>
      </Tooltip>
    </a>
  </UISref>
);

export const DeleteAction = ({ disabled = false, onConfirm, title }: DeleteActionProps) => (
  <Popconfirm
    title={title || 'Sure to delete?'}
    onConfirm={onConfirm}
    okButtonProps={{ disabled }}
    onCancel={stopPropagationHandler}
  >
    <Tooltip title={getTranslatedString('table.delete')}>
      <Button
        onClick={stopPropagationHandler}
        type="primary"
        danger
        shape="circle"
        icon={<DeleteOutlined />}
        disabled={disabled}
      />
    </Tooltip>
  </Popconfirm>
);

export const AttachmentAction = ({ uiSref: { to, params } }: EditActionProps) => (
  <UISref to={to} params={params}>
    <a onClick={stopPropagationHandler}>
      <Tooltip title={getTranslatedString('attachments.attachments')}>
        <Button type="primary" shape="circle" icon={<HomeOutlined />} />
      </Tooltip>
    </a>
  </UISref>
);

export const ReportAction = ({ onClick }: any) => (
  <a onClick={stopPropagationHandler}>
    <Tooltip title={getTranslatedString('table.report')}>
      <Button type="primary" shape="circle" onClick={onClick} icon={<HomeOutlined />} />
    </Tooltip>
  </a>
);

export const NotesAction = ({ uiSref: { to, params } }: EditActionProps) => (
  <UISref to={to} params={params}>
    <a onClick={stopPropagationHandler}>
      <Tooltip title={getTranslatedString('table.notes')}>
        <Button type="primary" shape="circle" icon={<HomeOutlined />} />
      </Tooltip>
    </a>
  </UISref>
);

export const SelectorAction = ({ uiSref: { to, params }, title }: any) => (
  <UISref to={to} params={params}>
    <a onClick={stopPropagationHandler}>
      <Button type="primary" shape="round" icon={<HomeOutlined />}>
        {title}
      </Button>
    </a>
  </UISref>
);
