import React from 'react';
import { UIViewInjectedProps } from '@uirouter/react';
import { observer } from 'mobx-react-lite';
import { PERMISSION_CREATE, PERMISSION_READ, PERMISSION_UPDATE } from '@/constants/permissions';
import { DictionaryTable } from '../common';
import props from './constants';

import { Form as ApproverConfigsForm } from './form';

const Table = observer(({ transition }: any) => <DictionaryTable {...{ ...props, transition }} />);

const Form = observer(({ transition }: UIViewInjectedProps) => <ApproverConfigsForm {...{ ...props, transition }} />);

export const states = [
  {
    name: props.listState,
    url: props.mainUrl,
    component: Table,
    data: {
      requiresAuth: true,
      permissions: [[props.permissionObj, PERMISSION_READ]],
    },
  },
  {
    name: props.createState,
    url: '/new',
    component: Form,
    data: {
      requiresAuth: true,
      permissions: [[props.permissionObj, PERMISSION_CREATE]],
    },
  },
  {
    name: props.editState,
    url: '/:id/edit',
    component: Form,
    data: {
      requiresAuth: true,
      permissions: [[props.permissionObj, PERMISSION_UPDATE]],
    },
  },
];
