import { useState } from 'react';
import { RcFile } from 'antd/lib/upload';
import { notification, UploadChangeParam } from '@/components/antd';
import { api } from '@/api';
import { downloadBlobFile, getTranslatedString } from '@/utils';

export const useAgreementUpload = () => {
  const [fileList, setFileList] = useState<any>([]);

  const beforeUpload = (file: RcFile) => {
    const isPdf = file.type === 'application/pdf';
    if (!isPdf) {
      notification.error({ message: getTranslatedString('applicationSettings.file-type-error') });
    }
    const isLt10M = file.size / 1024 / 1024 < 10;
    if (!isLt10M) {
      notification.error({ message: getTranslatedString('applicationSettings.file-size-error') });
    }
    return isPdf && isLt10M;
  };

  const initLastAgreement = async () => {
    try {
      const resp = await api.agreements.get().source;
      resp.data && setFileList([resp.data]);
    } catch (error) {
      notification.error({ message: error });
    }
  };

  const handleChange = ({ fileList: selectedFileList, file }: UploadChangeParam) => {
    if (file.status === 'done') {
      const FileWithId = [{ ...selectedFileList[0], id: file.response.data.id }];
      setFileList(FileWithId);
      return;
    }
    if (selectedFileList.length) {
      const isPdf = selectedFileList[0].type === 'application/pdf';
      const isLt10M = selectedFileList[0].size / 1024 / 1024 < 10;
      if (isPdf && isLt10M) {
        setFileList(selectedFileList);
      }
      return;
    }
    setFileList([]);
  };

  const onPreview = async () => {
    const resp = await api.agreements.downloadAgreement(fileList[0].id).source;
    downloadBlobFile(resp, fileList[0].name);
  };

  return { initLastAgreement, fileList, handleChange, beforeUpload, onPreview };
};
