import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Space } from '@/components/antd';

import { DeleteAction, EditAction } from '@/components/table/actions';
import { PERMISSION_DELETE, PERMISSION_UPDATE } from '@/constants/permissions';
import { WithPermissions } from '@/hocs/permission';
import { showError } from '@/utils/common';

export const getColumns = ({ columns, permissionObj, apiMethods, editState, store, disabledDeleteProps }) => {
  const Actions = (_: any, record: any) => {
    const { checkForDisabledDelete, disabledTitle } = disabledDeleteProps;
    const { id } = record
    const isDisabledDelete = checkForDisabledDelete && checkForDisabledDelete(record);

    const onDelete = () => {
      apiMethods
        .delete(id)
        .source.then(() => store.refresh())
        .catch(showError);
    };

    return (
      <Space>
        <WithPermissions permissions={[[permissionObj, PERMISSION_UPDATE]]}>
          <EditAction uiSref={{ to: editState, params: { id } }} />
        </WithPermissions>
        <WithPermissions permissions={[[permissionObj, PERMISSION_DELETE]]}>
          <DeleteAction
            onConfirm={!isDisabledDelete && onDelete}
            disabled={isDisabledDelete}
            title={isDisabledDelete && disabledTitle}
          />
        </WithPermissions>
      </Space>
    );
  };

  return [
    ...(Array.isArray(columns) ? columns : columns()),
    {
      title: <FormattedMessage id="table.actions" />,
      render: Actions,
    },
  ];
};
