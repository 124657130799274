import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Space } from '@/components/antd';

import { api } from '@/api';
import {
  DeleteAction,
  EditAction,
  ReadAction,
} from '@/components/table/actions';
import {
  PERMISSION_DELETE,
  PERMISSION_OBJ_ROLE,
  PERMISSION_READ,
  PERMISSION_UPDATE,
} from '@/constants/permissions';
import { withPermissions } from '@/hocs/permission';
import { rolesStore } from '@/stores';
import { showError } from '@/utils/common';

const PermissionedReadAction = withPermissions([[PERMISSION_OBJ_ROLE, PERMISSION_READ]])(ReadAction);
const PermissionedEditAction = withPermissions([[PERMISSION_OBJ_ROLE, PERMISSION_UPDATE]])(EditAction);
const PermissionedDeleteAction = withPermissions([[PERMISSION_OBJ_ROLE, PERMISSION_DELETE]])(DeleteAction);

const Actions = (_: any, { id, isSystem }: any) => {
  const onDelete = () => {
    api.roles.delete(id).source
      .then(() => rolesStore.deleteItem(id))
      .catch(showError);
  };

  if (isSystem) {
    return (
      <Space>
        <PermissionedReadAction uiSref={{ to: 'base-layout.roles.edit', params: { roleId: id } }} />
      </Space>
    );
  }

  return (
    <Space>
      <PermissionedEditAction uiSref={{ to: 'base-layout.roles.edit', params: { roleId: id } }} />
      <PermissionedDeleteAction onConfirm={onDelete} />
    </Space>
  );
};

const isSystem = (_: any, record: any) => (
  <Space>
    {record.isSystem ? 'Yes' : 'No'}
  </Space>
);

export const columns = [
  {
    title: 'id',
    dataIndex: 'id',
    sorter: true,
  },
  {
    title: <FormattedMessage id="roles.name" />,
    dataIndex: 'name',
    sorter: true,
  },
  {
    title: <FormattedMessage id="roles.slugname" />,
    dataIndex: 'slugname',
    sorter: true,
  },
  {
    title: <FormattedMessage id="roles.isSystem" />,
    dataIndex: 'isSystem',
    sorter: true,
    render: isSystem,
  },
  {
    title: <FormattedMessage id="roles.numberOfPermissions" />,
    dataIndex: 'numberOfPermissions',
  },
  {
    title: <FormattedMessage id="table.actions" />,
    render: Actions,
  },
];
