import { UISref } from '@uirouter/react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Avatar, Col, Dropdown, Layout as AntdLayout, Menu, Row } from '@/components/antd';
import { UserOutlined, LogoutOutlined } from '@/components/icons';

import { Search } from '@/components/header/search';
import { PERMISSION_OBJ_ROLE, PERMISSION_READ } from '@/constants/permissions';
import { withPermissions } from '@/hocs/permission';
import { VoidFnType } from '@/types/common';

import { RoleSwitcher } from '../role-switcher';

interface HeaderProps {
  collapsed: boolean;
  toggle: VoidFnType;
}

const menu = (
  <Menu>
    <Menu.Item key="profile" icon={<UserOutlined />}>
      <UISref to="base-layout.profile">
        <a className="header-profile-link">
          <span>
            <FormattedMessage id="profile.header" />
          </span>
        </a>
      </UISref>
    </Menu.Item>

    <Menu.Item key="logout" icon={<LogoutOutlined />}>
      <UISref to="auth-logout">
        <a className="header-profile-link">
          <FormattedMessage id="profile.logout" />
        </a>
      </UISref>
    </Menu.Item>
  </Menu>
);

const PermissionedRoleSwitcher = withPermissions([[PERMISSION_OBJ_ROLE, PERMISSION_READ]])(() => (
  <Col>
    <RoleSwitcher />
  </Col>
));

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const Header = (props: HeaderProps) => (
  <AntdLayout.Header className="header">
    <Row align="middle" wrap={false} gutter={16}>
      <Col flex="1 1 450px">
        <Search />
      </Col>

      <PermissionedRoleSwitcher />

      <Col flex="0 0 56px">
        <Dropdown overlay={menu}>
          <Avatar size="large" icon={<UserOutlined />} />
        </Dropdown>
      </Col>
    </Row>
  </AntdLayout.Header>
);
