export const getFormFields = () => [
  {
    name: 'errorResponse',
    componentName: 'input',
    params: {
      i18nLabel: 'employeesSyncErrors.errorResponse',
    },
    rules: [{ required: true }],
  },
  {
    name: 'type',
    componentName: 'input',
    params: {
      i18nLabel: 'employeesSyncErrors.type',
    },
    rules: [{ required: true }],
  },
  {
    name: 'ranAt',
    componentName: 'datepicker',
    params: {
      showTime: true,
      i18nLabel: 'employeesSyncErrors.ranAt',
    },
    rules: [{ required: true }],
  },
  {
    name: 'params',
    componentName: 'textarea',
    params: {
      i18nLabel: 'employeesSyncErrors.params',
    },
    rules: [{ required: true }],
  },
];
