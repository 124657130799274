import { useRouter } from '@uirouter/react';
import React, { useState, Dispatch, SetStateAction } from 'react';
import {
  notification, Col, Row, Select, Text,
} from '@/components/antd';

import { api } from '@/api';
import { WrappedItemsResult } from '@/api/types';
import { authStore } from '@/stores';
import { getTranslatedString } from '@/utils';
import { LoadingOutlined, SearchOutlined } from '@/components/icons';

const { Option } = Select;
const SEARCH_TIMEOUT_MS: number = 500;

let timeout: NodeJS.Timeout;
let currentValue: string;

const routes = {
  employee: [],
};

const deferredRequest = (
  value: string,
  callback: Dispatch<SetStateAction<mpg.api.search.ResponseItem[]>>,
  setLoading: Dispatch<SetStateAction<boolean>>,
) => {
  if (timeout) {
    clearTimeout(timeout);
    timeout = null;
  }

  currentValue = value;
  setLoading(true);

  const request = () => {
    api.search.get({ q: value }).source
      .then(({ data }: WrappedItemsResult<mpg.api.search.ResponseItem[]>) => {
        if (currentValue === value) {
          callback(data.items);
        }
      })
      .finally(() => setLoading(false));
  };

  timeout = setTimeout(request, SEARCH_TIMEOUT_MS);
};

export const Search = () => {
  const [data, setData] = useState<mpg.api.search.ResponseItem[]>([]);
  const [value, setValue] = useState<string>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const router = useRouter();

  const handleSearch = (searchText: string) => {
    searchText.length >= 3 ? deferredRequest(searchText, setData, setLoading) : setData([]);
  };

  const handleChange = (selectedValue: string, option: any) => {
    setValue(selectedValue);
    const typedRoutes = routes[option.data.type];
    const allowRoutes = (typedRoutes || []).filter(({ permissions }) => authStore.hasPermissions(permissions));

    if (!allowRoutes.length) {
      return;
    }

    router.stateService
      .go(allowRoutes[0].routeName, { [allowRoutes[0].paramName]: option.data.id })
      .catch((e) => {
        notification.warn({
          message: getTranslatedString('common.warning'),
          description: e.message,
        });
      });
  };

  // todo for different entities (now only for employees)
  const options = data
    // .filter(({ name }: any) => name)
    .map(({
      id, type, name, lastname,
    }: any) => (
      <Option
        key={`${id}${type}`}
        data={{
          id, type, name, lastname,
        }}
        value={`${id}${type}`}
      >
        <Col>
          <Row>
            {name}
            {' '}
            {lastname}
          </Row>
          <Row><Text type="secondary">{type}</Text></Row>
        </Col>
      </Option>
    ));

  const suffixIcon = loading ? <LoadingOutlined /> : <SearchOutlined />;

  return (
    <Select
      suffixIcon={suffixIcon}
      showSearch
      value={value}
      placeholder={getTranslatedString('common.search')}
      style={{ width: '100%', maxWidth: 450 }}
      defaultActiveFirstOption={false}
      filterOption={false}
      onSearch={handleSearch}
      onChange={handleChange}
      notFoundContent={null}
      virtual
    >
      {options}
    </Select>
  );
};
