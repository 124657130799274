import { FormattedMessage } from 'react-intl';

import { api } from '@/api';
import { languagesStore } from '@/stores';
import { PERMISSION_OBJ_LANGUAGE } from '@/constants/permissions';

export const createState = 'base-layout.languages.create';
export const editState = 'base-layout.languages.edit';
export const bulkEditState = 'base-layout.languages.bulk-edit';
export const listState = 'base-layout.languages';

export const i18nPrefix = 'languages';

export const mainUrl = '/languages';

export const permissionObj = PERMISSION_OBJ_LANGUAGE;
export const store = languagesStore;
export const apiMethods = api.languages;

export const fields = [
  {
    name: 'name',
    componentName: 'input',
    params: {
      i18nLabel: 'languages.name',
    },
    rules: [{ required: true }],
  },
];

export const columns = [
  {
    title: 'id',
    dataIndex: 'id',
    sorter: true,
  },
  {
    title: <FormattedMessage id="languages.name" />,
    dataIndex: 'name',
    sorter: true,
  },
];

export default {
  listState,
  createState,
  bulkEditState,
  editState,
  i18nPrefix,
  permissionObj,
  store,
  apiMethods,
  columns,
  fields,
  mainUrl,
};
