import { FormattedMessage } from 'react-intl';

import { api } from '@/api';
import { PERMISSION_OBJ_MUSTER_STATIONS } from '@/constants/permissions';
import { buildingsStore, musterStationsStore, musterStationCaptainsStore } from '@/stores';
import { buildDropdownOptionsFromState, buildStoreOptions, getStorePropById } from '@/utils/common';
import { getColumnSearchFields } from '@/components/table/filters';

export const createState = 'base-layout.musterStations.create';
export const editState = 'base-layout.musterStations.edit';
export const bulkEditState = 'base-layout.musterStations.bulk-edit';
export const listState = 'base-layout.musterStations';

export const i18nPrefix = 'musterStations';

export const mainUrl = '/muster-stations';

export const permissionObj = PERMISSION_OBJ_MUSTER_STATIONS;
export const store = musterStationsStore;
export const apiMethods = api.musterStations;

export const getFields = (isBulkUpdate: boolean) => {
  const fields = [
    {
      name: 'name',
      componentName: 'input',
      params: {
        i18nLabel: 'buildings.name',
      },
      rules: [{ required: true }],
    },
    {
      name: 'buildingId',
      componentName: 'dropdown',
      params: {
        i18nLabel: 'musterStations.building',
        options: buildDropdownOptionsFromState(buildingsStore.items),
      },
      rules: [{ required: true }],
    },
    {
      name: 'captainId',
      componentName: 'dropdown',
      params: {
        i18nLabel: 'musterStations.captain',
        options: buildDropdownOptionsFromState(musterStationCaptainsStore.items),
      },
      rules: [{ required: true }],
    },
  ];

  return fields
    .map((field) => {
      if (isBulkUpdate) {
        field.rules = field.rules.filter(({ required }) => !required);
      }
      return field;
    });
};

export const columns = () => [
  {
    title: 'id',
    dataIndex: 'id',
    sorter: true,
  },
  {
    title: <FormattedMessage id="musterStations.name" />,
    dataIndex: 'name',
    ...getColumnSearchFields(),
    sorter: true,
  },
  {
    title: <FormattedMessage id="musterStations.building" />,
    dataIndex: 'buildingId',
    filters: buildStoreOptions(buildingsStore, 'name'),
    filterMultiple: true,
    sorter: true,
    render: (itemId: string) => getStorePropById<mpg.api.buildings.Building>(itemId, buildingsStore),
  },
  {
    title: <FormattedMessage id="musterStations.captain" />,
    dataIndex: 'captainId',
    filters: buildStoreOptions(musterStationCaptainsStore, 'name'),
    filterMultiple: true,
    sorter: true,
    render: (itemId: string) => getStorePropById<mpg.api.musterStationCaptains.MusterStationCaptain>(itemId, musterStationCaptainsStore),
  },
];

export default {
  listState,
  createState,
  bulkEditState,
  editState,
  i18nPrefix,
  permissionObj,
  store,
  apiMethods,
  columns,
  mainUrl,
  relatedStores: [buildingsStore, musterStationCaptainsStore],
  getFields,
};
