import React from 'react';
import { CloseOutlined, CheckOutlined, MinusOutlined } from '@/components/icons';

export const BooleanValueComponent = ({ value }: any) => {
  if (value) {
    return <CheckOutlined style={{ color: 'green' }} />;
  }

  if (value === false) {
    return <CloseOutlined style={{ color: 'red' }} />;
  }

  if (value === null) {
    return <MinusOutlined />;
  }
};

export const BooleanValueRender = (value: any) => <BooleanValueComponent value={value} />;
