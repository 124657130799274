export const billableFields = [
  'workingBillableTime',
  'workingBillableCorrectedTime',
  'transitPaidTime',
  'transitPaidCorrectedTime',
  'lunchUnpaidTime',
  'lunchUnpaidCorrectedTime',
  'lunchPaidTime',
  'lunchPaidCorrectedTime',
  'brakePaidCorrectedTime',
  'brakePaidTime',
  'bathPaidTime',
  'bathPaidCorrectedTime',
];
export const dateFields = [
  'updatedAt',
  'createdAt',
  'correctedAt',
  'firstCheckIn',
  'eventEnd',
  'eventStart',
  'statusUpdatedOn',
  'approvedAt',
  'deletedAt',
  'syncedAt',
  'emailCodeExpiresAt',
  'Next Email Available At',
  'Next Sms Available At',
  'Sms Code Expires At',
  'dateLockerAssigned',
  'expireDate',
];
export const dateWithoutTimeFields = [
  'timesheetDate',
  'weekOf',
  'birthday',
  'firstWorkingLogDate',
  'startDate',
  'effectiveDate',
  'assignedDate',
  'from',
  'to',
];
export const booleanFields = [
  'enableShiftPlanning',
  'hasEmployeeUser',
  'term',
  'isEdited',
  'isSystem',
  'isMusterStationCaptain',
  'allowToChangeForgottenPassword',
  'isTwoFactorAuthEnabled',
  'blocked',
  'onVacation',
  'isNonBillable',
  'isAssigned',
  'trainedOverwrap',
  'trainedSlitter',
];
export const numberDateFormat = ['timesheetDate', 'weekOf'];
