import React from 'react';
import {
  Form, FormItemProps, Input as AntdInput, InputProps as AntdInputProps,
} from '@/components/antd';

import { Ii18nLabel } from '@/types/common';

import { getFormItemLabel } from './utils';

interface InputProps extends FormItemProps, AntdInputProps, Ii18nLabel {
  name: string;
  children: any;
  onReset: any;
  formState: any;
}

export const Password = (
  {
    name,
    label,
    i18nLabel,
    rules,
    disabled,
    formState,
    ...props
  }: InputProps,
) => {
  const fieldLabel = getFormItemLabel(i18nLabel, label);

  return (
    <Form.Item {...props} name={name} label={fieldLabel} rules={rules}>
      <AntdInput.Password type="password" />
    </Form.Item>
  );
};
