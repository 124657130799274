import { makeAutoObservable } from 'mobx';

import { LOCAL_STORAGE_KEY_LOCALE } from '@/constants';

export class LocaleStore {
  language = localStorage.getItem(LOCAL_STORAGE_KEY_LOCALE) || 'en';

  availableLanguages = ['en', 'fr'];

  constructor() {
    makeAutoObservable(this);
  }

  change(language) {
    if (this.availableLanguages.includes(language)) {
      localStorage.setItem(LOCAL_STORAGE_KEY_LOCALE, language);
      this.language = '';
      setTimeout(() => {
        this.language = language;
      }, 0);
    }
  }
}
