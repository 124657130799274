import { UIView } from '@uirouter/react';
import { observer } from 'mobx-react-lite';
import { useRef } from 'react';

import { Button, PageHeader } from '@/components/antd';
import { SettingOutlined } from '@/components/icons';
import { Table } from '@/components/table';
import { employeesSyncErrorsStore } from '@/stores';
import { useMassUpdateCashedStores } from '@/utils/store';

import { columns } from './setup';

export const EmployeesSyncErrors = observer(() => {
  const { items } = employeesSyncErrorsStore;
  const tableRef: any = useRef({});
  const defaultQueryParams = { limit: 50, sort: 'ranAt:-1' };

  useMassUpdateCashedStores([employeesSyncErrorsStore], [], () => null, defaultQueryParams);

  return (
    <>
      <PageHeader
        className="r-antd-page-header"
        extra={[
          // <Button key="print" icon={<PrinterOutlined />} type="primary" shape="circle" onClick={onPrint} />,
          <Button
            key="setting"
            icon={<SettingOutlined />}
            type="primary"
            shape="circle"
            onClick={tableRef.current.showSettingsModal}
          />,
        ]}
      />
      <Table
        tableRef={tableRef}
        rowKey="id"
        columns={columns}
        dataSource={[...items]}
        store={employeesSyncErrorsStore}
        defaultQueryParams={defaultQueryParams}
        scroll={{ y: '100%' }}
      />
      <UIView />
    </>
  );
});
