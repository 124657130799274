import { request } from '@/utils/request';
import { ApiResponse, WrappedResult } from './types';

export default {
  getAll(params?: mpg.api.colorCalendar.GetParams): ApiResponse<WrappedResult<mpg.api.colorCalendar.ColorCalendar[]>> {
    return request({
      method: 'get',
      url: '/color-calendar',
      params,
    });
  },
  create(
    data: mpg.api.colorCalendar.CalendarParams[],
  ): ApiResponse<WrappedResult<mpg.api.colorCalendar.ColorCalendar[]>> {
    return request({
      method: 'post',
      url: '/color-calendar',
      data,
    });
  },
};
