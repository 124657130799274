import { observer } from 'mobx-react-lite';
import React from 'react';
import { injectIntl, IntlProvider } from 'react-intl';
import { ConfigProvider, enEn, frFR } from '@/components/antd';

import { locales } from '@/i18n';
import { localeStore } from '@/stores';

import { preserveIntl } from '@/utils/intl';

const IntlInjector = injectIntl(({ children, intl }: any) => {
  preserveIntl(intl);
  return children;
});

export const IntlWrapper = observer(({ children }) => {
  const { language } = localeStore;

  if (!language) {
    return null;
  }

  const antdLocale = language === 'fr' ? frFR : enEn;

  return (
    <IntlProvider messages={locales[language]} locale={language} defaultLocale="en">
      <IntlInjector>
        <ConfigProvider locale={antdLocale} componentSize="middle">
          {children}
        </ConfigProvider>
      </IntlInjector>
    </IntlProvider>
  );
});
