import { states as layoutStates } from './components/layout';
import { states as applicationSettingsState } from './pages/application-settings';
import { states as auditStates } from './pages/audit';
import { states as authStates } from './pages/auth';
import { states as commonStates } from './pages/common';
import { states as dictionariesStates } from './pages/dictionaries';
import { states as employeesSyncErrorsStates } from './pages/employees-sync-errors';
import { states as homeStates } from './pages/home';
import { states as permissionsObjectsStates } from './pages/permissions-objects';
import { states as profileStates } from './pages/profile';
import { states as rolesStates } from './pages/roles';
import { states as usersStates } from './pages/users';
import { states as agreementsState } from './pages/agreement';
import { states as scheduleCalendar } from './pages/dictionaries/color-calendar';

export const states = [
  ...agreementsState,
  ...authStates,
  ...homeStates,
  ...profileStates,
  ...usersStates,
  ...rolesStates,
  ...commonStates,
  ...layoutStates,
  ...dictionariesStates,
  ...permissionsObjectsStates,
  ...auditStates,
  ...employeesSyncErrorsStates,
  ...applicationSettingsState,
  ...scheduleCalendar,
];
