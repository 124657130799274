export const getFormFields = () => [
  {
    name: 'name',
    componentName: 'input',
    params: {
      i18nLabel: 'roles.name',
    },
    rules: [
      { required: true },
      { max: 50 },
    ],
  },
  {
    name: 'slugname',
    componentName: 'input',
    params: {
      i18nLabel: 'roles.slugname',
    },
    rules: [
      { required: true },
      { max: 50 },
    ],
  },
  {
    name: 'permissions',
    componentName: 'permissions-table',
    params: {
      i18nLabel: 'roles.permissions',
    },
    // rules: [{ required: true }],
  },
];
