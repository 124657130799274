import moment from 'moment';
import zxcvbn from 'zxcvbn';
import { RuleObject } from 'rc-field-form/lib/interface';
import { FormInstance } from '@/components/antd';

export const emailOrPhoneRequired = ({ getFieldValue }: FormInstance): RuleObject => ({
  validator(): Promise<void | any> | void {
    if (getFieldValue('email') || getFieldValue('phone')) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('Email or phone should be required (one of two)'));
  },
});

export const phone = (): RuleObject => ({
  validator(_, value: any): Promise<void | any> | void {
    if (!value || value.replace(/[0-9]/g, 'X') === 'XXX-XXX-XXXX') {
      return Promise.resolve();
    }
    return Promise.reject(new Error('Please use following format: XXX-XXX-XXXX'));
  },
});

export const notPastDateIfEdit = (initValues = {}) => () => ({
  validator(_, value: any): Promise<void | any> | void {
    const { field } = _;

    if (!value || initValues[field] === value) {
      return Promise.resolve();
    }

    if (moment(value).startOf('day').isBefore(moment(Date.now()).startOf('day'))) {
      return Promise.reject(new Error('Date cannot be past'));
    }

    return Promise.resolve();
  },
});

export const password = () => ({
  validator(_, value: any): Promise<void | any> | void {
    if (!value) {
      return Promise.resolve();
    }

    const { feedback = {}, score } = zxcvbn(value);
    const { warning = '' } = feedback as any;

    if (warning || score < 3) {
      return Promise.reject(new Error(warning || 'Too weak password'));
    }

    return Promise.resolve();
  },
});

export const toBeEqual = (fieldName, message) => (f) => ({
  validator(_, value: any): Promise<void | any> | void {
    if (!value) {
      return Promise.resolve();
    }

    if (f.getFieldValue(fieldName) === value) {
      return Promise.resolve();
    }

    return Promise.reject(new Error(message));
  },
});
