export const PERMISSION_READ = 1;
export const PERMISSION_CREATE = 2;
export const PERMISSION_UPDATE = 4;
export const PERMISSION_DELETE = 8;

export const PERMISSION_OBJ_ADDRESS = 'address';
export const PERMISSION_OBJ_COLOR = 'color';
export const PERMISSION_OBJ_AGENCY = 'agency';
export const PERMISSION_OBJ_AGENCY_MARKUP = 'agencyMarkup';
export const PERMISSION_OBJ_POSITION = 'position';
export const PERMISSION_OBJ_LANGUAGE = 'language';
export const PERMISSION_OBJ_AREA = 'area';
export const PERMISSION_OBJ_BUILDINGS_AREAS = 'buildingsAreas';
export const PERMISSION_OBJ_BREAKTIMES = 'break_time';
export const PERMISSION_OBJ_BUILDING_PAIRS = 'buildingPair';
export const PERMISSION_OBJ_COLOR_CALENDAR = 'colorCalendar';


export const PERMISSION_OBJ_BUILDINGS = 'building';
export const PERMISSION_OBJ_BUILDING_CONFIGS = 'buildingConfig';
export const PERMISSION_OBJ_AUDIT = 'audit';
export const PERMISSION_OBJ_EMPLOYEE = 'employee';
export const PERMISSION_OBJ_SHIFT = 'shift';
export const PERMISSION_OBJ_SUPERVISOR = 'supervisor';
export const PERMISSION_OBJ_WORKING_LOG = 'working_log';
export const PERMISSION_OBJ_BV = 'bv';
export const PERMISSION_OBJ_CAMPAIGN = 'campaign';
export const PERMISSION_OBJ_COUNTERPARTY = 'counterparty';
export const PERMISSION_OBJ_COUNTERPARTY_CONTRACT = 'counterparty_contract';
export const PERMISSION_OBJ_CONTRACT_LINE_ITEM = 'contract_line_item';
export const PERMISSION_OBJ_CV_EXECUTION = 'cv_execution';
export const PERMISSION_OBJ_CV = 'cv';
export const PERMISSION_OBJ_LOT = 'lot';
export const PERMISSION_OBJ_LOT_ANALYSIS = 'lot_analysis';
export const PERMISSION_OBJ_LOT_FUMIGATION = 'lot_fumigation';
export const PERMISSION_OBJ_LOT_PLEDGE = 'lot_pledge';
export const PERMISSION_OBJ_LOT_WEIGHING = 'lot_weighing';
export const PERMISSION_OBJ_PROPOSAL = 'lot_proposal';
export const PERMISSION_OBJ_PRODUCT = 'product';
export const PERMISSION_OBJ_PERMISSION = 'permission';
export const PERMISSION_OBJ_PERMISSION_OBJECT = 'permission_object';

export const PERMISSION_OBJ_APPLICATION_SETTINGS = 'applicationSettings';

export const PERMISSION_OBJ_ROLE = 'role';
export const PERMISSION_OBJ_USER = 'user';
export const PERMISSION_OBJ_APPROVER_CONFIGS = 'approverConfig';
export const PERMISSION_OBJ_BOARDING = 'boarding';
export const PERMISSION_OBJ_TRANSIT_ORDER = 'transit_order';
export const PERMISSION_OBJ_CONTAINER = 'container';
export const PERMISSION_OBJ_TRANSFER = 'transfer';
export const PERMISSION_OBJ_COORDINATOR_STAFFING = 'ui.mpg.staffing';
export const PERMISSION_OBJ_AGENCY_STAFFING = 'ui.agency.staffing';
export const PERMISSION_OBJ_EMPLOYEES_SYNC_ERRORS = 'friendlyWaySyncErrors';
export const PERMISSION_OBJ_MUSTER_STATIONS = 'musterStation';
export const PERMISSION_OBJ_LINES = 'line';
export const PERMISSION_OBJ_LOCKERS = 'lockerConfig';
export const PERMISSION_OBJ_DASHBOARD = '';

export const PERMISSION_OBJ_COORDINATOR_BILLING = 'ui.mpg.billing';
export const PERMISSION_OBJ_COORDINATOR_REPORTING = 'ui.mpg.workLogReports';
export const PERMISSION_OBJ_AGENCY_BILLING = 'ui.agency.billing';
export const PERMISSION_OBJ_AGENCY_REPORTING = 'ui.agency.workLogReports';
