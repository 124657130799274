import { FormattedMessage } from 'react-intl';
import { PlusOutlined } from '@/components/icons';
import { Button } from '@/components/antd';

import { ImportButton } from '@/components/import-button';
import {
  PERMISSION_CREATE,
  PERMISSION_UPDATE,
  PERMISSION_DELETE,
} from '@/constants/permissions';
import { WithPermissions } from '@/hocs/permission';

export const CreateButton = ({ onClick, permissionObj, i18nPrefix }: any) => (
  <WithPermissions permissions={[[permissionObj, PERMISSION_CREATE]]}>
    <Button onClick={onClick} type="primary" shape="round" icon={<PlusOutlined />}>
      <FormattedMessage id={`${i18nPrefix}.add-new`} />
    </Button>
  </WithPermissions>
);

export const BulkCreateButton = ({
  request, settings, storeRefreshRequest, permissionObj,
}: any) => (
  <WithPermissions permissions={[[permissionObj, PERMISSION_CREATE]]}>
    <ImportButton request={request} parseSettings={settings} storeRefreshRequest={storeRefreshRequest} />
  </WithPermissions>
);

export const BulkEditButton = ({
  onClick, disabled, permissionObj, i18nPrefix,
}: any) => (
  <WithPermissions permissions={[[permissionObj, PERMISSION_UPDATE]]}>
    <Button onClick={onClick} type="primary" shape="round" icon={<PlusOutlined />} disabled={disabled}>
      <FormattedMessage id={`${i18nPrefix}.edit-bulk`} />
    </Button>
  </WithPermissions>
);

export const BulkDeleteButton = ({
  onClick, disabled, permissionObj, i18nPrefix,
}: any) => (
  <WithPermissions permissions={[[permissionObj, PERMISSION_DELETE]]}>
    <Button onClick={onClick} type="primary" shape="round" icon={<PlusOutlined />} disabled={disabled}>
      <FormattedMessage id={`${i18nPrefix}.delete-bulk`} />
    </Button>
  </WithPermissions>
);
