import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Space } from '@/components/antd';

import { api } from '@/api';
import { DeleteAction, EditAction, ReadAction } from '@/components/table/actions';
import {
  PERMISSION_DELETE,
  PERMISSION_OBJ_PERMISSION_OBJECT,
  PERMISSION_READ,
  PERMISSION_UPDATE,
} from '@/constants/permissions';
import { withPermissions } from '@/hocs/permission';
import { permissionsObjectsStore } from '@/stores';
import { showError } from '@/utils/common';

const PermissionedReadAction = withPermissions([[PERMISSION_OBJ_PERMISSION_OBJECT, PERMISSION_READ]])(ReadAction);
const PermissionedEditAction = withPermissions([[PERMISSION_OBJ_PERMISSION_OBJECT, PERMISSION_UPDATE]])(EditAction);
const PermissionedDeleteAction = withPermissions([[PERMISSION_OBJ_PERMISSION_OBJECT, PERMISSION_DELETE]])(DeleteAction);

const Actions = (_: any, { id, isSystem }: any) => {
  const onDelete = () => {
    api.permissionsObjects
      .delete(id)
      .source.then(() => permissionsObjectsStore.refresh())
      .catch(showError);
  };

  if (isSystem) {
    return (
      <Space>
        <PermissionedReadAction
          uiSref={{ to: 'base-layout.permissions-objects.edit', params: { permissionObjectId: id } }}
        />
      </Space>
    );
  }

  return (
    <Space>
      <PermissionedEditAction
        uiSref={{ to: 'base-layout.permissions-objects.edit', params: { permissionObjectId: id } }}
      />
      <PermissionedDeleteAction onConfirm={onDelete} />
    </Space>
  );
};

const Methods = (_: any, { methods }: any) => <Space>{methods.join(', ')}</Space>;

const MethodsIds = (_: any, { methodsIds }: any) => <Space>{methodsIds.join(', ')}</Space>;

const isSystem = (_: any, record: any) => <Space>{record.isSystem ? 'Yes' : 'No'}</Space>;

export const columns = [
  {
    title: 'id',
    dataIndex: 'id',
  },
  {
    title: <FormattedMessage id="permissions.name" />,
    dataIndex: 'name',
  },
  {
    title: <FormattedMessage id="permissions.slugname" />,
    dataIndex: 'slugname',
  },
  {
    title: <FormattedMessage id="permissions.isSystem" />,
    dataIndex: 'isSystem',
    render: isSystem,
  },
  {
    title: <FormattedMessage id="permissions.methods" />,
    dataIndex: 'methods',
    render: Methods,
  },
  {
    title: 'Methods Ids',
    dataIndex: 'methodsIds',
    render: MethodsIds,
  },
  {
    title: <FormattedMessage id="table.actions" />,
    render: Actions,
  },
];
