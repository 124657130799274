import { useEffect, useMemo, useState } from 'react';

import { getDefaultSettings, getGenericFilters, toQueryFormat } from '@/components/table/utils';
import { authStore, settingsStore } from '@/stores';

import {
  defaultMinWidth,
  getInitialTableSettings,
  saveSettingToLocalStorage,
} from './utils';

export const useStoreIntegration = (props) => {
  const {
    columns, store, fillStore, relId, dataSource, defaultQueryParams,
  } = props;
  const genericFilters = useMemo(() => getGenericFilters(columns), []);

  const onChangeTable = (...args) => {
    const params = {
      ...(defaultQueryParams || {}),
      ...toQueryFormat.apply(null, [genericFilters, ...args]),
    };

    const fillStoreArgs = relId ? [relId, params] : [params];

    if (fillStore) {
      fillStore.apply(null, fillStoreArgs);
    } else if (store) {
      store.forceLoad.apply(store, fillStoreArgs);
    }
  };

  const onChange = props.onChange || onChangeTable;
  const pagination = props.pagination !== undefined
    ? props.pagination
    : {
      total: store
        ? store.count
        : dataSource.length,
      showSizeChanger: true,
      onShowSizeChange: (current, size) => {
        settingsStore.changeItemsPerPage(size);
      },
      pageSize: settingsStore.itemsPerPage,
      showQuickJumper: true,
    };

  if (pagination && !pagination.pageSize) {
    pagination.pageSize = settingsStore.itemsPerPage;
  }

  return {
    pagination,
    onChange,
  };
};

export const usePermissions = (props) => {
  const { columns, checkPermissions } = props;

  let allowedColumns = [...columns];

  if (checkPermissions) {
    allowedColumns = allowedColumns
      .filter(({ permissions }: any) => {
        if (!permissions) {
          return true;
        }

        // if (permissionObj) {
        //   return authStore.hasPermissions([[`${permissionObj}.${dataIndex}`, PERMISSION_READ]]);
        // }

        return authStore.hasPermissions(permissions);
      });
  }

  return {
    allowedColumns,
  };
};

export const useTableSettings = (props) => {
  const { allowedColumns, localStorageKey, tableRef } = props;

  const defaultSettings = getDefaultSettings(allowedColumns);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [settings, setSettings] = useState(getInitialTableSettings(localStorageKey, allowedColumns));

  useEffect(() => {
    saveSettingToLocalStorage(localStorageKey, settings);
  }, [settings]);

  const handleResize = (dataIndex) => (e, { size }) => {
    const { minWidth } = (allowedColumns.find((col: any) => col.dataIndex === dataIndex) || {}) as any;

    if (size.width < (minWidth || defaultMinWidth)) {
      return;
    }

    const nextColumns = [...settings];
    const index = nextColumns.findIndex((col) => col.dataIndex === dataIndex);

    nextColumns[index] = {
      ...nextColumns[index],
      width: size.width,
    };

    setSettings(nextColumns);
  };

  const shownColumns = settings
    .map((col) => {
      const found = allowedColumns.find((c: any) => c.dataIndex === col.dataIndex);

      if (found) {
        return {
          ...found,
          ...col,
        };
      }
      return null;
    })
    .filter((col) => col && col.visible)
    .map(((col) => ({
      ...col,
      key: col.dataIndex,
      width: col.width || col.minWidth || defaultMinWidth,
      onHeaderCell: (column) => ({
        width: column.width,
        onResize: handleResize(col.dataIndex),
      }),
    })));

  if (tableRef) {
    tableRef.current.showSettingsModal = () => {
      setIsModalOpen(true);
    };
  }

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return {
    closeModal,
    isModalOpen,
    defaultSettings,
    settings,
    setSettings,
    shownColumns,
  };
};
