import { UIView } from '@uirouter/react';
import { observer } from 'mobx-react-lite';
import { useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { PlusOutlined, PrinterOutlined, SettingOutlined } from '@/components/icons';
import { Button, PageHeader } from '@/components/antd';

import { Table } from '@/components/table';
import { PERMISSION_CREATE, PERMISSION_OBJ_USER } from '@/constants/permissions';
import { withPermissions } from '@/hocs/permission';
import { agenciesStore, usersStore, rolesStore } from '@/stores';
import { onPrint } from '@/utils/common';
import { useMassUpdateCashedStores } from '@/utils/store';

import { columns } from './setup';

const users = usersStore;

const CreateButton = withPermissions([[PERMISSION_OBJ_USER, PERMISSION_CREATE]])(({ onClick }: any) => (
  <Button onClick={onClick} type="primary" shape="round" icon={<PlusOutlined />}>
    <FormattedMessage id="users.add-new" />
  </Button>
));

export const Users = observer(({ transition }: any) => {
  const { items } = users;

  const tableRef: any = useRef({});

  useMassUpdateCashedStores([agenciesStore, users, rolesStore]);

  const onAdd = () => {
    transition.router.stateService.go('base-layout.users.create');
  };
  return (
    <>
      <PageHeader
        className="r-antd-page-header"
        extra={[
          <Button key="print" icon={<PrinterOutlined />} type="primary" shape="circle" onClick={onPrint} />,
          <Button
            key="setting"
            icon={<SettingOutlined />}
            type="primary"
            shape="circle"
            onClick={tableRef.current.showSettingsModal}
          />,
          <CreateButton key="create" onClick={onAdd} />,
        ]}
      />
      <Table
        tableRef={tableRef}
        rowKey="id"
        columns={Array.isArray(columns) ? columns : columns()}
        dataSource={items.map(i => ({ ...i }))}
        store={users}
        loading={users.loading}
        scroll={{ y: '100%' }}
      />
      <UIView />
    </>
  );
});
