import { FormattedMessage } from 'react-intl';

import { api } from '@/api';
import { areasStore } from '@/stores';
import { PERMISSION_OBJ_AGENCY } from '@/constants/permissions';
import { getColumnBooleanFields, getColumnSearchFields } from '@/components/table/filters';
import { BooleanValueComponent } from "@/components/table/renders";

export const createState = 'base-layout.areas.create';
export const editState = 'base-layout.areas.edit';
export const bulkEditState = 'base-layout.areas.bulk-edit';
export const listState = 'base-layout.areas';

export const i18nPrefix = 'areas';

export const mainUrl = '/areas';

export const permissionObj = PERMISSION_OBJ_AGENCY;
export const store = areasStore;
export const apiMethods = api.areas;

export const fields = [
  {
    name: 'name',
    componentName: 'input',
    params: {
      i18nLabel: 'areas.name',
    },
    rules: [{ required: true }],
  },
  {
    name: 'active',
    componentName: 'switch',
    params: {
      i18nLabel: 'areas.status',
      initialValue: true,
    },
    rules: [
      { required: true },
    ],
  }
];

export const columns = [
  {
    title: 'id',
    dataIndex: 'id',
    sorter: true,
  },
  {
    title: <FormattedMessage id="areas.name" />,
    dataIndex: 'name',
    ...getColumnSearchFields(),
    sorter: true,
  },
  {
    title: <FormattedMessage id="areas.status" />,
    dataIndex: 'active',
    sorter: true,
    ...getColumnBooleanFields(),
    render: (value) => <BooleanValueComponent value={value} />,
  }

];

export default {
  listState,
  createState,
  bulkEditState,
  editState,
  i18nPrefix,
  permissionObj,
  store,
  apiMethods,
  columns,
  fields,
  mainUrl,
};
