import { PickerMode } from 'rc-picker/lib/interface';
import React, { useState } from 'react';

import { isArray } from '@uirouter/core';
import moment from 'moment/moment';
import { Ii18nLabel } from '@/types/common';
import { getFormattedMinutes, getTimeFromMinutes } from '@/utils/moment';
import {
  DatePicker as AntdDatePicker, Form, FormItemProps, InputNumber,
} from '@/components/antd';
import { getFormItemLabel } from './utils';

interface DatePickerProps extends FormItemProps, Ii18nLabel {
  showTime?: boolean;
  disabled?: boolean;
  picker: Exclude<PickerMode, 'date' | 'time'>;
  formState: any;
}

export const TimePicker = ({
  name, label, i18nLabel, rules, formState, ...props
}: DatePickerProps) => {
  const { form } = formState;
  const fieldLabel = getFormItemLabel(i18nLabel, label);
  const initialValue = form.getFieldValue(name)
    ? moment(getTimeFromMinutes(form.getFieldValue(name)), 'hh:mm')
    : undefined;
  const [date, setDate] = useState(initialValue);
  const getPopupContainer = (trigger) => trigger.parentElement;
  const onChange = (value: any, dateString: string) => {
    setDate(value);
    const minutes = getFormattedMinutes(dateString);
    if (!isArray(name)) {
      form.setFieldsValue({
        [name]: minutes,
      });
    }
    form.validateFields([name]);
  };
  return (
    <>
      <Form.Item style={{ display: 'none' }} name={name} label={fieldLabel} rules={rules}>
        <InputNumber value={form.getFieldValue(name)} />
      </Form.Item>

      <Form.Item label={fieldLabel} rules={rules}>
        <AntdDatePicker
          value={date}
          onChange={onChange}
          mode="time"
          getPopupContainer={getPopupContainer}
          {...props}
        />
      </Form.Item>
    </>
  );
};
