export const getI18nPrefix = (tableName: string) => {
  switch (tableName) {
    case 'building_configs':
      return 'buildingConfigs';
    case 'building_pairs':
      return 'buildingPairs';
    case 'buildings_areas':
      return 'buildingsAreas';
    case 'addresses':
      return tableName;
    case 'agencies':
      return tableName;
    case 'approver_configs':
      return 'approverConfigs';
    case 'areas':
      return tableName;
    case 'buildingsAreas':
      return tableName;
    case 'buildingConfigs':
      return tableName;
    case 'audit':
      return tableName;
    case 'break_times':
      return 'breaktimes';
    case 'buildings':
      return tableName;
    case 'colors':
      return tableName;
    case 'employees':
      return tableName;
    case 'employees_sync_errors':
      return 'employeesSyncErrors';
    case 'languages':
      return tableName;
    case 'permission_objects':
      return 'permissions-objects';
    case 'permissions':
      return tableName;
    case 'positions':
      return tableName;
    case 'roles':
      return tableName;
    case 'shifts':
      return tableName;
    case 'supervisors':
      return tableName;
    case 'users':
      return tableName;
    case 'working_logs':
      return 'workingLogs';
    case 'application_settings':
      return 'application-settings';
  }
};
