import { Checkbox } from 'antd';
import React from 'react';
import { Form } from '@/components/antd';
import { getFormItemLabel } from '@/components/form-fields/utils';

export const CheckboxGroup = ({ options, i18nLabel, label, rules, name, ...props }) => {
  const fieldLabel = getFormItemLabel(i18nLabel, label);

  return (
    <>
      <Form.Item name={name} label={fieldLabel} rules={rules}>
        <Checkbox.Group options={options} {...props} />
      </Form.Item>
    </>
  );
};
