import { FormattedMessage } from 'react-intl';

import { api } from '@/api';
import { agenciesStore } from '@/stores';
import { PERMISSION_OBJ_AGENCY } from '@/constants/permissions';
import { getColumnBooleanFields, getColumnSearchFields } from '@/components/table/filters';
import { BooleanValueRender } from '@/components/table/renders';

export const createState = 'base-layout.agencies.create';
export const editState = 'base-layout.agencies.edit';
export const bulkEditState = 'base-layout.agencies.bulk-edit';
export const listState = 'base-layout.agencies';

export const i18nPrefix = 'agencies';

export const mainUrl = '/agencies';

export const permissionObj = PERMISSION_OBJ_AGENCY;
export const store = agenciesStore;
export const apiMethods = api.agencies;

export const fields = [
  {
    name: 'name',
    componentName: 'input',
    params: {
      i18nLabel: 'agencies.name',
    },
    rules: [{ required: true }],
  },
  {
    name: 'info',
    componentName: 'input',
    params: {
      i18nLabel: 'agencies.info',
    },
    rules: [{ required: true }],
  },
  {
    name: 'location',
    componentName: 'input',
    params: {
      i18nLabel: 'agencies.location',
    },
    rules: [{ required: true }],
  },
  {
    name: 'isNonBillable',
    componentName: 'switch',
    params: {
      i18nLabel: 'agencies.non-billable',
    },
    rules: [],
  },
];

export const columns = [
  {
    title: 'id',
    dataIndex: 'id',
    sorter: true,
  },
  {
    title: <FormattedMessage id="agencies.name" />,
    dataIndex: 'name',
    ...getColumnSearchFields(),
    sorter: true,
  },
  {
    title: <FormattedMessage id="agencies.info" />,
    dataIndex: 'info',
    ...getColumnSearchFields(),
    sorter: true,
  },
  {
    title: <FormattedMessage id="agencies.location" />,
    dataIndex: 'location',
    ...getColumnSearchFields(),
    sorter: true,
  },
  {
    title: <FormattedMessage id="agencies.non-billable" />,
    dataIndex: 'isNonBillable',
    ...getColumnBooleanFields(),
    sorter: true,
    render: BooleanValueRender,
  },
];

export default {
  listState,
  createState,
  bulkEditState,
  editState,
  i18nPrefix,
  permissionObj,
  store,
  apiMethods,
  columns,
  fields,
  mainUrl,
};
