import { UIView } from '@uirouter/react';
import { useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import {
  Button, Space, PageHeader,
} from '@/components/antd';
import { SettingOutlined } from '@/components/icons';

import { Table } from '@/components/table';
import { StateStatus } from '@/types/enums';
import { useMassUpdateCashedStores } from '@/utils/store';

import { CreateButton } from './components';
import { getColumns } from './table-setup';

export const DictionaryTable = observer(({
  additionalStore = null,
  relatedStores = [],
  disabledDeleteProps = {},
  permissionObj,
  store,
  createState,
  editState,
  i18nPrefix,
  apiMethods,
  columns,
  transition,
}: any) => {
  const { items } = store;
  const tableRef: any = useRef({});

  const { loading } = useMassUpdateCashedStores(additionalStore ? [store, additionalStore, ...relatedStores] : [store, ...relatedStores]);

  useEffect(() => {
    if (!loading) {
      store.asyncItems({ limit: 0, sort: 'name:1' });
    }
  }, [loading]);

  const onAdd = () => {
    createState !== StateStatus.Unavailable && transition.router.stateService.go(createState);
  };

  return (
    <>
      <PageHeader
        className="r-antd-page-header"
        extra={[
          <Space>
            <Button
              key="setting"
              icon={<SettingOutlined />}
              type="primary"
              shape="circle"
              onClick={tableRef.current.showSettingsModal}
            />
            {createState !== StateStatus.Unavailable && (
            <CreateButton
              onClick={onAdd}
              permissionObj={permissionObj}
              i18nPrefix={i18nPrefix}
            />
            )}
          </Space>,
        ]}
      />
      <Table
        tableRef={tableRef}
        rowKey="id"
        columns={getColumns({
          columns, permissionObj, apiMethods, editState, store, disabledDeleteProps,
        })}
        dataSource={!loading && [...items]}
        store={store}
        scroll={{ y: '100%' }}
        loading={store.loading || loading}
      />
      <UIView />
    </>
  );
});
