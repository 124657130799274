import { request } from '@/utils/request';
import { ApiResponse, WrappedResult } from './types';

export default {
  getAll(params?: mpg.api.musterStations.GetParams): ApiResponse<WrappedResult<mpg.api.musterStations.MusterStation[]>> {
    return request({
      method: 'get',
      url: '/muster-stations',
      params,
    });
  },
  get(id): ApiResponse<WrappedResult<mpg.api.musterStations.MusterStationParams>> {
    return request({
      method: 'get',
      url: `/muster-stations/${id}`,
    });
  },
  create(data: mpg.api.musterStations.MusterStationParams): ApiResponse<WrappedResult<mpg.api.musterStations.MusterStation>> {
    return request({
      method: 'post',
      url: '/muster-stations',
      data,
    });
  },
  update(id: number, data: mpg.api.musterStations.MusterStationParams): ApiResponse<WrappedResult<mpg.api.musterStations.MusterStation>> {
    return request({
      method: 'patch',
      url: `/muster-stations/${id}`,
      data,
    });
  },
  delete(id: number): ApiResponse<WrappedResult<any>> {
    return request({
      method: 'delete',
      url: `/muster-stations/${id}`,
    });
  },
  bulkCreate(data: { items: mpg.api.musterStations.MusterStation[] }): ApiResponse<WrappedResult<mpg.api.musterStations.MusterStation>> {
    return request({
      method: 'post',
      url: '/muster-stations/bulk/create',
      data,
    });
  },
  bulkUpdate(data: { items: mpg.api.musterStations.MusterStation[] }): ApiResponse<WrappedResult<mpg.api.musterStations.MusterStation>> {
    return request({
      method: 'post',
      url: '/muster-stations/bulk/update',
      data,
    });
  },
  bulkDelete(data: { ids: string[] }): ApiResponse<WrappedResult<mpg.api.musterStations.MusterStation>> {
    return request({
      method: 'post',
      url: '/muster-stations/bulk/delete',
      data,
    });
  },
};
