import { agenciesStore } from '@/stores';
import { buildDropdownOptionsFromState } from '@/utils/common';

export const getFormFields = (roles, isCreate) => {
  const fields: any[] = [
    {
      name: 'email',
      componentName: 'input',
      params: {
        i18nLabel: 'users.email',
      },
      rules: [
        { required: true },
      ],
    },
    {
      name: 'name',
      componentName: 'input',
      params: {
        i18nLabel: 'users.name',
      },
      rules: [
        // { required: true },
      ],
    },
    {
      name: 'password',
      componentName: 'input',
      params: {
        i18nLabel: 'users.password',
      },
      rules: [
        { min: 8 },
        { max: 32 },
      ],
    },
  ];

  if (isCreate) {
    fields.push({
      name: 'sendInvitationEmail',
      componentName: 'switch',
      params: {
        i18nLabel: 'users.send-invitation',
      },
    });
  } else {
    fields.push({
      name: 'repeatPassword',
      componentName: 'input',
      params: {
        i18nLabel: 'users.repeat-password',
      },
      rules: [
        { min: 8 },
        { max: 32 },
      ],
    });
  }

  fields.push({
    name: 'agencyId',
    componentName: 'dropdown',
    params: {
      i18nLabel: 'users.agencyId',
      options: buildDropdownOptionsFromState(agenciesStore.items),
    },
    rules: [],
  });
  fields.push({
    name: 'roles',
    componentName: 'dropdown',
    params: {
      i18nLabel: 'users.roles',
      options: buildDropdownOptionsFromState(roles),
      mode: 'multiple',
    },
    rules: [
      // { required: true }
    ],
  });

  return fields;
};
