import { FormattedMessage } from 'react-intl';
import { buildDropdownOptionsFromState, buildStoreOptions, getStorePropById } from '@/utils/common';

import { api } from '@/api';
import { PERMISSION_OBJ_AGENCY_MARKUP } from '@/constants/permissions';
import { agenciesMarkupStore, agenciesStore } from '@/stores';

export const createState = 'base-layout.agenciesMarkup.create';
export const editState = 'base-layout.agenciesMarkup.edit';
export const bulkEditState = 'base-layout.agenciesMarkup.bulk-edit';
export const listState = 'base-layout.agenciesMarkup';

export const i18nPrefix = 'agenciesMarkup';

export const mainUrl = '/agenciesMarkup';

export const permissionObj = PERMISSION_OBJ_AGENCY_MARKUP;
export const store = agenciesMarkupStore;
export const apiMethods = api.agenciesMarkup;

const createInitValues = {
  overtimeRate: 1.5,
};
export const getFields = ({ isCreate = false }) => {
  const fields = [
    {
      name: 'agencyId',
      componentName: 'dropdown',
      params: {
        i18nLabel: 'agenciesMarkup.agencyId',
        options: buildDropdownOptionsFromState(
          agenciesStore.items.filter(item => !item.isNonBillable),
        ),
      },
      rules: [{ required: true }],
    },
    {
      name: 'markup',
      componentName: 'input-number',
      params: {
        i18nLabel: 'agenciesMarkup.markup',
      },
      rules: [{ required: true }],
    },
    {
      name: 'overtimeRate',
      componentName: 'input-number',
      params: {
        i18nLabel: 'agenciesMarkup.overtimeRate',
        disabled: true,
      },
      rules: [{ required: true }],
    },
    {
      name: 'overtimeMarkup',
      componentName: 'input-number',
      params: {
        i18nLabel: 'agenciesMarkup.overtimeMarkup',
      },
      rules: [{ required: true }],
    },
    {
      name: 'effectiveDate',
      componentName: 'datepicker',
      params: {
        i18nLabel: 'agenciesMarkup.effectiveDate',
        type: 'date',
        format: 'YYYY-MM-DD',
      },
      rules: [{ required: true }],
    },
  ];
  return fields
    .map((field) => {
      if (field.name === 'agencyId' && !isCreate) {
        return {
          ...field,
          params: {
            ...field.params,
            disabled: true,
          },
        };
      }
      return field;
    });
};

export const columns = () => [
  {
    title: 'id',
    dataIndex: 'id',
    sorter: true,
  },
  {
    title: <FormattedMessage id="agenciesMarkup.agencyId" />,
    dataIndex: 'agencyId',
    filters: buildStoreOptions(agenciesStore, 'name'),
    filterMultiple: true,
    sorter: true,
    render: (itemId: string) => getStorePropById<mpg.api.agencies.Agency>(itemId, agenciesStore),
  },
  {
    title: <FormattedMessage id="agenciesMarkup.markup" />,
    dataIndex: 'markup',
    sorter: true,
  },
  {
    title: <FormattedMessage id="agenciesMarkup.overtimeRate" />,
    dataIndex: 'overtimeRate',
    sorter: true,
  },
  {
    title: <FormattedMessage id="agenciesMarkup.overtimeMarkup" />,
    dataIndex: 'overtimeMarkup',
    sorter: true,
  },
  {
    title: <FormattedMessage id="agenciesMarkup.effectiveDate" />,
    dataIndex: 'effectiveDate',
    sorter: true,
  },
];
export default {
  additionalStore: agenciesStore,
  listState,
  createState,
  bulkEditState,
  editState,
  i18nPrefix,
  permissionObj,
  store,
  apiMethods,
  columns,
  getFields,
  mainUrl,
  createInitValues,
};
