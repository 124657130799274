import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Space, Spin, Switch } from '@/components/antd';

import { api } from '@/api';
import { DeleteAction, EditAction } from '@/components/table/actions';
import { PERMISSION_DELETE, PERMISSION_OBJ_USER, PERMISSION_UPDATE } from '@/constants/permissions';
import { withPermissions } from '@/hocs/permission';
import { agenciesStore, usersStore } from '@/stores';
import { buildStoreOptions, getStorePropById, showError } from '@/utils/common';
import { getColumnBooleanFields, getColumnSearchFields } from '@/components/table/filters';

const PermissionedEditAction = withPermissions([[PERMISSION_OBJ_USER, PERMISSION_UPDATE]])(EditAction);
const PermissionedDeleteAction = withPermissions([[PERMISSION_OBJ_USER, PERMISSION_DELETE]])(DeleteAction);

const Actions = (_: any, { id }: any) => {
  const onDelete = () => {
    api.users
      .delete(id)
      .source.then(() => usersStore.deleteItem(id))
      .catch(showError);
  };

  return (
    <Space>
      <PermissionedEditAction uiSref={{ to: 'base-layout.users.edit', params: { userId: id } }} />
      <PermissionedDeleteAction onConfirm={onDelete} />
    </Space>
  );
};

const UserBlocking = ({ value, row }) => {
  const [loading, setLoading] = useState(false);

  const onChange = async (checked: boolean) => {
    try {
      setLoading(true);
      if (checked) {
        await api.users.block(row.id).source;
      } else {
        await api.users.unblock(row.id).source;
      }
      await usersStore.refresh();
      setLoading(false);
    } catch (err) {
      showError(err);
      setLoading(false);
    }
  };
  return loading ? <Spin /> : <Switch checked={value} onChange={onChange} />;
};

const UserOnVacation = ({ value, row }) => {
  const [loading, setLoading] = useState(false);

  const onChange = async (checked: boolean) => {
    try {
      setLoading(true);
      if (checked) {
        await api.users.update(row.id, { onVacation: true }).source;
      } else {
        await api.users.update(row.id, { onVacation: false }).source;
      }
      await usersStore.refresh();
      setLoading(false);
    } catch (err) {
      showError(err);
      setLoading(false);
    }
  };
  return loading ? <Spin /> : <Switch checked={value} onChange={onChange} />;
};

export const columns = () => [
  {
    title: 'id',
    dataIndex: 'id',
    sorter: true,
  },
  {
    title: <FormattedMessage id="users.email" />,
    dataIndex: 'email',
    ...getColumnSearchFields(),
    sorter: true,
  },
  {
    title: <FormattedMessage id="users.name" />,
    dataIndex: 'name',
    ...getColumnSearchFields(),
    sorter: true,
  },
  {
    title: <FormattedMessage id="users.agencyId" />,
    dataIndex: 'agencyId',
    filters: buildStoreOptions(agenciesStore, 'name'),
    filterMultiple: true,
    sorter: true,
    render: (id: string) => getStorePropById<mpg.api.agencies.Agency>(id, agenciesStore),
  },
  {
    title: <FormattedMessage id="users.roles" />,
    dataIndex: 'roles',
    render: (text: any) => text && text.map(item => item && item.name).join(', '),
  },
  {
    title: <FormattedMessage id="users.onVacation" />,
    dataIndex: 'onVacation',
    ...getColumnBooleanFields(),
    render: (value: any, row) => <UserOnVacation value={value} row={row} />,
  },
  {
    title: <FormattedMessage id="users.blocked" />,
    dataIndex: 'blocked',
    ...getColumnBooleanFields(),
    render: (value: any, row) => <UserBlocking value={value} row={row} />,
  },
  {
    title: <FormattedMessage id="table.actions" />,
    render: Actions,
  },
];
