import React, { useState } from 'react';
import zxcvbn from 'zxcvbn';
import {
  Form, FormItemProps, Input as AntdInput, InputProps as AntdInputProps,
} from '@/components/antd';

import { Ii18nLabel } from '@/types/common';

import { getFormItemLabel } from './utils';

interface InputProps extends FormItemProps, AntdInputProps, Ii18nLabel {
  name: string;
  children: any;
  onReset: any;
  formState: any;
}

const strength = {
  0: 'too guessable',
  1: 'very guessable',
  2: 'somewhat guessable',
  3: 'safely unguessable',
  4: 'very unguessable',
};

export const CreatePassword = (
  {
    name,
    label,
    i18nLabel,
    rules,
    disabled,
    formState,
    ...props
  }: InputProps,
) => {
  const fieldLabel = getFormItemLabel(i18nLabel, label);
  const value = formState.form.getFieldValue(name) || '';
  const [passwordEstimation, setPasswordEstimation] = useState<any>(zxcvbn(value) || {});
  const { feedback = {}, score = 0 } = passwordEstimation;
  const { suggestions = [] } = feedback as any;

  const onChange = (e) => {
    const newValue = e.target.value;
    setPasswordEstimation(zxcvbn(newValue) || {});
  };

  const passwordStrength = strength[score];

  const help = (
    <>
      {value && (
      <div>
        Password strength:
        {passwordStrength}
      </div>
      )}
      {!!suggestions.length && (
        <div>
          {suggestions.map((suggestion: any) => <div key={suggestion}>{suggestion}</div>)}
        </div>
      )}
    </>
  );

  return (
    <Form.Item {...props} name={name} label={fieldLabel} rules={rules} extra={help}>
      <AntdInput.Password
        type="password"
        onChange={onChange}
      />
    </Form.Item>
  );
};
