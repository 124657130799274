import { Form } from './components/form';
import { Audit } from './components/table';

import {
  PERMISSION_OBJ_AUDIT,
  PERMISSION_READ,
  PERMISSION_UPDATE,
} from '@/constants/permissions';

export const states = [
  {
    name: 'base-layout.audit',
    url: '/audit',
    component: Audit,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_AUDIT, PERMISSION_READ],
      ],
    },
  },
  {
    name: 'base-layout.audit.edit',
    url: '/:itemId/edit',
    component: Form,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_AUDIT, PERMISSION_UPDATE],
      ],
    },
  },
];
