import { Form } from './components/form';
import { EmployeesSyncErrors } from './components/table';

import {
  PERMISSION_CREATE,
  PERMISSION_OBJ_EMPLOYEES_SYNC_ERRORS,
  PERMISSION_READ,
  PERMISSION_UPDATE,
} from '@/constants/permissions';

export const states = [
  {
    name: 'base-layout.employeesSyncErrors',
    url: '/employeesSyncErrors',
    component: EmployeesSyncErrors,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_EMPLOYEES_SYNC_ERRORS, PERMISSION_READ],
      ],
    },
  },
  {
    name: 'base-layout.employeesSyncErrors.create',
    url: '/new',
    component: Form,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_EMPLOYEES_SYNC_ERRORS, PERMISSION_CREATE],
      ],
    },
  },
  {
    name: 'base-layout.employeesSyncErrors.edit',
    url: '/:employeesSyncErrors',
    component: Form,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_EMPLOYEES_SYNC_ERRORS, PERMISSION_UPDATE],
      ],
    },
  },
  {
    name: 'base-layout.employeesSyncErrors.bulk-edit',
    url: '/bulk-edit',
    component: Form,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_EMPLOYEES_SYNC_ERRORS, PERMISSION_UPDATE],
      ],
    },
  },
];
