import { FormattedMessage } from 'react-intl';

import { api } from '@/api';
import { supervisorsStore } from '@/stores';
import { PERMISSION_OBJ_SUPERVISOR } from '@/constants/permissions';
import { getColumnBooleanFields, getColumnSearchFields } from '@/components/table/filters';
import { BooleanValueComponent } from "@/components/table/renders";

export const createState = 'base-layout.supervisors.create';
export const editState = 'base-layout.supervisors.edit';
export const bulkEditState = 'base-layout.supervisors.bulk-edit';
export const listState = 'base-layout.supervisors';

export const i18nPrefix = 'supervisors';

export const mainUrl = '/supervisors';

export const permissionObj = PERMISSION_OBJ_SUPERVISOR;
export const store = supervisorsStore;
export const apiMethods = api.supervisors;

export const fields = [
  {
    name: 'name',
    componentName: 'input',
    params: {
      i18nLabel: 'supervisors.name',
    },
    rules: [{ required: true }],
  },
  {
    name: 'active',
    componentName: 'switch',
    params: {
      i18nLabel: 'supervisors.status',
      initialValue: true,
    },
    rules: [
      { required: true },
    ],
  }
];

export const columns = [
  {
    title: 'id',
    dataIndex: 'id',
    sorter: true,
  },
  {
    title: <FormattedMessage id="supervisors.name" />,
    dataIndex: 'name',
    ...getColumnSearchFields(),
    sorter: true,
  },
  {
    title: <FormattedMessage id="supervisors.status" />,
    dataIndex: 'active',
    sorter: true,
    ...getColumnBooleanFields(),
    render: (value) => <BooleanValueComponent value={value} />,
  }
];

export default {
  listState,
  createState,
  bulkEditState,
  editState,
  i18nPrefix,
  permissionObj,
  store,
  apiMethods,
  columns,
  fields,
  mainUrl,
};
