import React, { useEffect } from 'react';
import moment from 'moment';
import {
  Radio, Button, Menu, DatePicker, Space,
} from '@/components/antd';

const { RangePicker } = DatePicker;

const modes = {
  init: 'init',
  range: 'range',
  notDefined: 'not-defined',
};

const mapToMoment = (arr) => arr.map((e) => moment(e));
const mapToString = (arr) => arr.map((e) => e.toISOString());

export const DateFilter = (props) => {
  const [mode, setMode] = React.useState(modes.init);
  const {
    setSelectedKeys, confirm, clearFilters, selectedKeys,
  } = props;
  const onChange = (e) => {
    setSelectedKeys(mapToString(e));
  };
  useEffect(() => {
    if (mode === modes.notDefined) {
      setSelectedKeys(['null']);
    }

    if (mode === modes.range) {
      const start = moment().startOf('month');
      const end = moment().endOf('month');
      setSelectedKeys(mapToString([start, end]));
    }
  }, [mode]);

  const onChangeMode = (e) => {
    setMode(e.target.value);
  };


  const onApply = () => {
    confirm();
  };


  const resetFilters = () => {
    clearFilters();
    confirm();
    setMode(modes.init);
  };

  return (
    <Menu>
      <Menu.Item key="0">
        <Radio.Group onChange={onChangeMode} value={mode}>
          <Radio value={modes.range}>Range</Radio>
          <Radio value={modes.notDefined}>Not defined</Radio>
        </Radio.Group>
      </Menu.Item>

      {mode === 'range' && (
        <Menu.Item key="1">
          <RangePicker
            value={selectedKeys && mapToMoment(selectedKeys)}
            onChange={onChange}
          />
        </Menu.Item>
      )}
      <Menu.Divider />
      <Menu.Item key="2">
        <Space>
          <Button onClick={resetFilters} type="link" shape="round" disabled={!(selectedKeys && selectedKeys.length)}>
            Reset Filters
          </Button>
          <Button onClick={onApply} type="primary" shape="round">Apply</Button>
        </Space>
      </Menu.Item>
    </Menu>
  );
};
