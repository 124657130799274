import { request } from '@/utils/request';

import { ApiResponse, WrappedResult } from './types';

export default {
  getAll(params?: mpg.api.lockers.GetParams): ApiResponse<WrappedResult<mpg.api.lockers.Locker[]>> {
    return request({
      method: 'get',
      url: '/locker-config',
      params,
    });
  },
  get(id): ApiResponse<WrappedResult<mpg.api.lockers.Locker>> {
    return request({
      method: 'get',
      url: `/locker-config/${id}`,
    });
  },
  update(id: number, data: mpg.api.lockers.LockerParams): ApiResponse<WrappedResult<mpg.api.lockers.Locker>> {
    return request({
      method: 'patch',
      url: `/locker-config/${id}`,
      data,
    });
  },
};
