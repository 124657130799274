import { authStore } from '@/stores';
import { AGREEMENTS_PAGE } from '@/constants';

export const authorizedHook = {
  criteria: {
    to: (state: any) => state.data && state.data.requiresAuth,
  },
  callback: async (transition: any) => {
    const $state = transition?.router?.stateService;

    if (!authStore.isAuthorized()) {
      await authStore.checkSession();

      if (!authStore.loggedIn) {
        return $state.target('auth-login', undefined, { location: true });
      }
    }
    if (authStore.userInfo?.isRecentAgreementSigned === false) {
      return $state.target(AGREEMENTS_PAGE, undefined, { location: true });
    }
    return true;
  },
};
