import { ApiResponse, WrappedResult } from '@/api/types';
import { request } from '@/utils/request';

export default {
  getAll(
    params?: mpg.api.applicationSettings.GetParams,
  ): ApiResponse<WrappedResult<mpg.api.applicationSettings.ApplicationSettings>> {
    return request({
      method: 'get',
      url: '/application-settings',
      params,
    });
  },
  update(
    data: mpg.api.applicationSettings.ApplicationSettingsParams,
  ): ApiResponse<WrappedResult<mpg.api.applicationSettings.ApplicationSetting>> {
    return request({
      method: 'patch',
      url: '/application-settings',
      data,
    });
  },
  updateById(
    id: number,
    data: mpg.api.applicationSettings.ApplicationSettingsParams,
  ): ApiResponse<WrappedResult<mpg.api.applicationSettings.ApplicationSetting>> {
    return request({
      method: 'patch',
      url: `/application-settings/${id}`,
      data,
    });
  },
};
