import { SearchFilter } from './search-filter';
import { DateFilter } from './date-filter';
import { BooleanValueComponent } from '../renders';

/**
 * Copy/Paste from fw-ext-frontend
 */
export const getColumnSearchFields = () => ({
  filterDropdown: SearchFilter,
});

export const getColumnDateFields = () => ({
  filterDropdown: DateFilter,
});

/**
 * Copy/Paste from fw-ext-frontend
 */
export const getColumnBooleanFields = (withNullOption?: boolean) => ({
  filterMultiple: false,
  filters: withNullOption
    ? [
      { label: 'true', value: true, text: <BooleanValueComponent value /> },
      { label: 'false', value: false, text: <BooleanValueComponent value={false} /> },
      { label: 'null', value: 'null', text: <BooleanValueComponent value={null} /> },
    ]
    : [
      { label: 'true', value: true, text: <BooleanValueComponent value /> },
      { label: 'false', value: false, text: <BooleanValueComponent value={false} /> },
    ],
});

export const getColumnAttachmentIdFields = () => ({
  filterMultiple: false,
  filters: [
    { label: 'N/A', value: 'null', text: <BooleanValueComponent value={null} /> },
  ],
});
