import React from 'react';
import { FormattedMessage } from 'react-intl';

import MinusOutlined from '@ant-design/icons/MinusOutlined';
import { Space } from '@/components/antd';
import { en } from '@/i18n/en';
import { minutesToStringHours } from '@/utils/time';
import { getDateWithoutTime, getFormattedDate } from '@/utils/moment';
import { BooleanValueComponent } from '@/components/table/renders';
import { billableFields, dateFields, dateWithoutTimeFields, booleanFields, numberDateFormat } from './constants';

const RenderAuditValue = ({ value, rowKey }) => {
  function convertDateFormat(dateString) {
    const match = dateString.match(/(\d{4})(0[1-9]|1[012])([012][0-9]|3[01])/);
    const [, year, month, day] = match;
    return `${month}/${day}/${year}`;
  }
  if (value === null) {
    return <MinusOutlined />;
  }
  if (value === undefined) {
    return <></>;
  }
  if (numberDateFormat.includes(rowKey)) {
    const formattedDate = convertDateFormat(value.toString())
    return <>{formattedDate}</>;
  }
  if (billableFields.includes(rowKey)) {
    return <>{minutesToStringHours(value)}</>;
  }
  if (dateFields.includes(rowKey)) {
    return <>{getFormattedDate(String(value))}</>;
  }
  if (dateWithoutTimeFields.includes(rowKey)) {
    return <>{getDateWithoutTime(value)}</>;
  }
  if (booleanFields.includes(rowKey)) {
    return <BooleanValueComponent value={value} />;
  }
  return <>{String(value)}</>;
};

export const RenderAuditField = (value: any, props: any) => {
  const { originalData, newData, key } = props;
  const wasChanged = originalData !== newData;
  return (
    <Space
      style={{
        width: '100%',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        background: 'white',
        color: wasChanged ? 'red' : undefined,
      }}
    >
      <RenderAuditValue value={value} rowKey={key} />
    </Space>
  );
};

export const columns = [
  {
    title: <FormattedMessage id="audit.property" />,
    dataIndex: 'key',
    sorter: true,
    render: (value, { i18nPrefix }) => {
      if (en[`${i18nPrefix}.${value}`]) return <FormattedMessage id={`${i18nPrefix}.${value}`} />;

      return <FormattedMessage id={`systemInfo.${value}`} />;
    },
  },
  {
    title: <FormattedMessage id="audit.originalData" />,
    dataIndex: 'originalData',
    render: RenderAuditField,
  },
  {
    title: <FormattedMessage id="audit.newData" />,
    dataIndex: 'newData',
    render: RenderAuditField,
  },
];
