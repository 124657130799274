import { Badge } from 'antd';
import { FormattedMessage } from 'react-intl';

import { api } from '@/api';
import { colorsStore } from '@/stores';
import { PERMISSION_OBJ_COLOR } from '@/constants/permissions';
import { BooleanValueComponent } from '@/components/table/renders';
import { getColumnBooleanFields } from '@/components/table/filters';
import { getTranslatedString } from '@/utils';

export const createState = 'base-layout.colors.create';
export const editState = 'base-layout.colors.edit';
export const bulkEditState = 'base-layout.colors.bulk-edit';
export const listState = 'base-layout.colors';

export const i18nPrefix = 'colors';

export const mainUrl = '/colors';

export const permissionObj = PERMISSION_OBJ_COLOR;
export const store = colorsStore;
export const apiMethods = api.colors;

const createInitValues = {
  color: '#000000',
};
const weekOptions = [
  { label: 'S', value: 'sunday', text: 'Sunday' },
  { label: 'M', value: 'monday', text: 'Monday' },
  { label: 'T', value: 'tuesday', text: 'Tuesday' },
  { label: 'W', value: 'wednesday', text: 'Wednesday' },
  { label: 'T', value: 'thursday', text: 'Thursday' },
  { label: 'F', value: 'friday', text: 'Friday' },
  { label: 'S', value: 'saturday', text: 'Saturday' },
];

export const getFields = ({ formValue }) => {
  const fields = [
    {
      name: 'name',
      componentName: 'input',
      params: {
        i18nLabel: 'colors.name',
      },
      rules: [{ required: true }],
    },
    {
      name: 'color',
      componentName: 'input',
      params: {
        type: 'color',
        inputStyle: { width: 80 },
        i18nLabel: 'colors.color',
      },
      rules: [{ required: true }],
    },
    {
      name: 'active',
      componentName: 'switch',
      params: {
        initialValue: true,
        i18nLabel: 'colors.status',
      },
      rules: [{ required: true }],
    },
    {
      name: 'shiftTypes',
      componentName: 'dropdown',
      params: {
        initialValue: true,
        i18nLabel: 'colors.shiftTypes',
        options: [
          { value: 'night', label: 'Night' },
          { value: 'day', label: 'Day' },
        ],
        mode: 'multiple',
      },
      rules: [{ required: true }],
    },
    {
      name: 'scheduleType',
      componentName: 'dropdown',
      params: {
        initialValue: true,
        i18nLabel: 'colors.scheduleType',
        options: [
          { value: 'calendar', label: 'Calendar' },
          { value: 'weekly', label: 'Weekly' },
        ],
      },
    },
    {
      name: 'weekDays',
      componentName: 'checkbox-group',
      params: {
        initialValue: true,
        i18nLabel: 'colors.weekDays',
        options: weekOptions,
      },
    },
  ];
  return formValue?.scheduleType === 'weekly'
    ? fields
    : fields.map(item => {
        if (item.name !== 'weekDays') {
          return item;
        }
        return { ...item, params: { ...item.params, disabled: true } };
      });
};

export const columns = [
  {
    title: 'id',
    dataIndex: 'id',
    sorter: true,
  },
  {
    title: <FormattedMessage id="colors.name" />,
    dataIndex: 'name',
    sorter: true,
  },
  {
    title: <FormattedMessage id="colors.color" />,
    dataIndex: 'color',
    sorter: true,
    render: color => <Badge color={color} text={color} />,
  },
  {
    title: <FormattedMessage id="colors.status" />,
    dataIndex: 'active',
    sorter: true,
    ...getColumnBooleanFields(),
    render: value => <BooleanValueComponent value={value} />,
  },
  {
    title: <FormattedMessage id="colors.shiftTypes" />,
    dataIndex: 'shiftTypes',
    filters: [
      { value: 'night', label: 'Night', text: 'Night' },
      { value: 'day', label: 'Day', text: 'Day' },
    ],
    render: (values: string[] | null) => {
      const fixedValues = values?.map(item => getTranslatedString(`colors.${item}`));
      return fixedValues?.join('/');
    },
  },
  {
    title: <FormattedMessage id="colors.scheduleType" />,
    dataIndex: 'scheduleType',
    sorter: true,
    filters: [
      { value: 'calendar', label: 'Calendar', text: 'Calendar' },
      { value: 'weekly', label: 'Weekly', text: 'Weekly' },
    ],
    render: (value: string | null) => (value ? <FormattedMessage id={`colors.${value}`} /> : ''),
  },
  {
    title: <FormattedMessage id="colors.weekDays" />,
    dataIndex: 'weekDays',
    render: (values: string[] | null) => {
      const fixedValues = values?.map(item => getTranslatedString(`colors.${item}`));
      return fixedValues?.join(', ');
    },
    filters: weekOptions,
  },
];

export default {
  listState,
  createState,
  bulkEditState,
  editState,
  i18nPrefix,
  permissionObj,
  store,
  apiMethods,
  columns,
  getFields,
  mainUrl,
  createInitValues,
};
