import { UIViewInjectedProps } from '@uirouter/react';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';

import { Tabs } from 'antd';
import { FormattedMessage } from 'react-intl';
import { api } from '@/api';
import { agenciesStore, rolesStore, usersStore } from '@/stores';
import { getTranslatedString } from '@/utils';
import { withoutRepeatValue } from '@/utils/controller';

import { useMassUpdateCashedStores } from '@/utils/store';
import { getFormFields } from './setup';
import { Button, Drawer } from '@/components/antd';
import { Loader } from '@/components/loader';
import { GenericForm } from '@/components/generic-form';
import { useDrawerForm } from '@/components/drawer-form/hooks';
import SignedAgreements from '@/pages/users/components/signed-agreements';

export const UserForm = observer(({ transition }: UIViewInjectedProps) => {
  const isCreate = transition.router.globals.current.name === 'base-layout.users.create';
  const { userId } = transition.router.globals.params;
  const user = usersStore.items.find(({ id }: any) => id === +userId);

  const {
    loadingSubmit,
    form,
    onStartSubmit,
    onFinishSubmit,
    onFieldsChange,
    disableSubmit,
  } = useDrawerForm();

  const [currentRoles, setCurrentRoles] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(!isCreate);

  useMassUpdateCashedStores([agenciesStore, rolesStore]);

  useEffect(() => {
    if (!isCreate) {
      api.users.get(userId).source.then(({ data }) => {
        const role = data.roles.map(item => item.id);
        setCurrentRoles(role);
        setLoading(false);
      });
    }
  }, []);

  const onClose = () => {
    transition.router.stateService.go('base-layout.users');
  };

  if (loading) {
    return <FormattedMessage id="common.loading" />;
  }

  const initValues =
    !isCreate && user
      ? {
          ...user,
          roles: currentRoles,
        }
      : {};

  const title = isCreate
    ? getTranslatedString('users.create-new')
    : getTranslatedString('users.user-number', { 0: userId });

  const formFields = getFormFields(rolesStore.items, isCreate);

  const resourceController = withoutRepeatValue(initValues, {
    create: async (values: any) => {
      const newUserResponse = await api.users.create({
        ...values,
        roles: rolesStore.items.filter(({ id }) => (values.roles || []).includes(id)).map(({ slugname }) => slugname),
      }).source;
      await usersStore.refresh();
      transition.router.stateService.go('base-layout.users.edit', { userId });

      return newUserResponse;
    },
    update: async (values: any) => {
      const { roles, ...userData } = values;

      if (Object.keys(userData).length) {
        await api.users.update(user.id, userData).source;
      }

      if (roles && roles.length) {
        await api.users.updateRoles(user.id, roles).source;
      }

      await usersStore.refresh();
    },
  });
  const DrawerFooter = () => (
    <div className="drawer-footer">
      <Button className="drawer-footer-button" shape="round" onClick={onClose}>
        <FormattedMessage id="button.cancel" />
      </Button>
      <Button
        onClick={form.submit}
        type="primary"
        shape="round"
        loading={loadingSubmit}
        disabled={disableSubmit}
      >
        <FormattedMessage id="button.submit" />
      </Button>
    </div>
  );
  return (
    <Drawer
      onClose={onClose}
      visible
      title={title}
      footer={<DrawerFooter />}
      width={window.innerWidth - 250}
    >
      <Tabs>
        <Tabs.TabPane tab={getTranslatedString('users.user-tab')} key="1">
          {!isCreate && !user ? (
            <Loader />
          ) : (
            <GenericForm
              resourceId={userId}
              title={title}
              formRef={form}
              resourceController={resourceController}
              initialValues={initValues}
              formFields={formFields}
              onSuccessFinish={onClose}
              onStartSubmit={onStartSubmit}
              onFinishSubmit={onFinishSubmit}
              onFieldsChange={onFieldsChange}
            />
          )}
        </Tabs.TabPane>
        <Tabs.TabPane tab={getTranslatedString('users.agreements-tab')} key="2">
          <SignedAgreements userId={userId} />
        </Tabs.TabPane>
      </Tabs>
    </Drawer>
  );
});
