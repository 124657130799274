import { UIView } from '@uirouter/react';
import { observer } from 'mobx-react-lite';
import React, { useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { PlusOutlined, PrinterOutlined, SettingOutlined } from '@/components/icons';
import { Button, PageHeader, Select } from '@/components/antd';

import { Table } from '@/components/table';
import { PERMISSION_CREATE, PERMISSION_OBJ_PERMISSION_OBJECT } from '@/constants/permissions';
import { withPermissions } from '@/hocs/permission';
import { usePermissionsData } from '@/hooks';
import { permissionsObjectsStore, permissionsStore } from '@/stores';
import { useMassUpdateCashedStores } from '@/utils/store';

import { onPrint } from '@/utils/common';
import { columns } from './setup';

const CreateButton = withPermissions([[PERMISSION_OBJ_PERMISSION_OBJECT, PERMISSION_CREATE]])(({ onClick }: any) => (
  <Button onClick={onClick} type="primary" shape="round" icon={<PlusOutlined />}>
    <FormattedMessage id="permissions-objects.add-new" />
  </Button>
));

export const Permissions = observer(({ transition }: any) => {
  useMassUpdateCashedStores([permissionsObjectsStore, permissionsStore]);
  const tableRef: any = useRef({});

  const {
    selectedTab, onChange, permissionsData, options,
  } = usePermissionsData();

  const onAdd = () => {
    transition.router.stateService.go('base-layout.permissions-objects.create');
  };

  return (
    <>
      <PageHeader
        className="r-antd-page-header"
        extra={[
          <Select
            key="filter"
            showSearch
            placeholder="Select an entity"
            onChange={onChange}
            filterOption={(input, option: any) => option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            options={options}
            value={selectedTab}
            style={{ width: 200 }}
          />,
          <Button key="print" icon={<PrinterOutlined />} type="primary" shape="circle" onClick={onPrint} />,
          <Button
            key="setting"
            icon={<SettingOutlined />}
            type="primary"
            shape="circle"
            onClick={tableRef.current.showSettingsModal}
          />,
          <CreateButton key="create" onClick={onAdd} />,
        ]}
      />
      <Table
        tableRef={tableRef}
        rowKey="id"
        columns={columns}
        dataSource={permissionsData[selectedTab] || []}
        scroll={{ y: '100%' }}
      />
      <UIView />
    </>
  );
});
