import { FormattedMessage } from 'react-intl';

import { api } from '@/api';
import { positionsStore } from '@/stores';
import { PERMISSION_OBJ_POSITION } from '@/constants/permissions';
import { BooleanValueComponent } from '@/components/table/renders';
import { getColumnBooleanFields } from '@/components/table/filters';

export const createState = 'base-layout.positions.create';
export const editState = 'base-layout.positions.edit';
export const bulkEditState = 'base-layout.positions.bulk-edit';
export const listState = 'base-layout.positions';

export const i18nPrefix = 'positions';

export const mainUrl = '/positions';

export const permissionObj = PERMISSION_OBJ_POSITION;
export const store = positionsStore;
export const apiMethods = api.positions;

export const fields = [
  {
    name: 'name',
    componentName: 'input',
    params: {
      i18nLabel: 'positions.name',
    },
    rules: [{ required: true }],
  },
  {
    name: 'active',
    componentName: 'switch',
    params: {
      i18nLabel: 'positions.status',
      initialValue: true,
    },
    rules: [{ required: true }],
  },
];

export const columns = [
  {
    title: 'id',
    dataIndex: 'id',
    sorter: true,
  },
  {
    title: <FormattedMessage id="positions.name" />,
    dataIndex: 'name',
    sorter: true,
  },
  {
    title: <FormattedMessage id="positions.status" />,
    dataIndex: 'active',
    sorter: true,
    ...getColumnBooleanFields(),
    render: value => <BooleanValueComponent value={value} />,
  },
];

export default {
  listState,
  createState,
  bulkEditState,
  editState,
  i18nPrefix,
  permissionObj,
  store,
  apiMethods,
  columns,
  fields,
  mainUrl,
};
