import { LOCAL_STORAGE_KEY_ACCESS_TOKEN } from '@/constants';

export abstract class AccessTokenProvider {
  abstract get(): string;
  abstract set(token: string): void;
  abstract remove(): void;
}

export class LocalStorageTokenProvider extends AccessTokenProvider {
  get() {
    return localStorage.getItem(LOCAL_STORAGE_KEY_ACCESS_TOKEN);
  }

  set(token: string) {
    localStorage.setItem(LOCAL_STORAGE_KEY_ACCESS_TOKEN, token);
  }

  remove() {
    return localStorage.removeItem(LOCAL_STORAGE_KEY_ACCESS_TOKEN);
  }
}
