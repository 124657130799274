import { Badge, Calendar } from 'antd';
import React, { useState } from 'react';
import BgColorsOutlined from '@ant-design/icons/BgColorsOutlined';
import { observer } from 'mobx-react-lite';
import { UIView } from '@uirouter/react';
import { Moment } from 'moment/moment';
import moment from 'moment';
import { CustomHeader } from './header';
import { useKeysPressed, useSelect } from './hooks';
import { Button } from '@/components/antd';
import { colorsCalendarStore } from '@/stores';
import { useMassUpdateCashedStores } from '@/utils/store';
import { Loader } from '@/components/loader';

const store = colorsCalendarStore;
export const ScheduleCalendar = observer(({ transition }: any) => {
  const [selectedDates, setSelectedDates] = useState<moment.Moment[]>([]);
  const { loading } = useMassUpdateCashedStores([store]);
  const { items } = store;
  const onMultiSelect = dates => {
    setSelectedDates(dates);
  };
  const { isShiftPressed, isCtrlPressed } = useKeysPressed();
  const { onSelect } = useSelect({ isCtrlPressed, isShiftPressed, onMultiSelect });
  const assignColors = () => {
    transition.router.stateService.go('base-layout.color-calendar.create', {
      selectedDates,
    });
  };
  const dateCellRender = (value: Moment) => {
    const currentDate = value.format('YYYY-MM-DD');
    const currentItems = items?.filter(item => item.date === currentDate);
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {currentItems.map(item => (
          <Badge color={item?.color?.color} text={item?.color?.name} />
        ))}
      </div>
    );
  };
  const isAssignButtonDisabled =
    !(selectedDates?.length > 0) ||
    !selectedDates.some(item => item.isAfter(moment()) || item.isSame(moment(), 'day'));

  return (
    <>
      <div>
        {loading ? (
          <Loader />
        ) : (
          <Calendar
            dateCellRender={dateCellRender}
            disabledDate={currentDate => moment().subtract(1, 'days').isAfter(currentDate)}
            headerRender={props => (
              <CustomHeader
                {...props}
                extra={
                  <Button
                    onClick={assignColors}
                    type="primary"
                    icon={<BgColorsOutlined />}
                    disabled={isAssignButtonDisabled}
                  />
                }
              />
            )}
            onSelect={onSelect}
          />
        )}

        <UIView />
      </div>
    </>
  );
});
