import React from 'react';
import { Form, FormItemProps, Text } from '@/components/antd';

import { Ii18nLabel } from '@/types/common';

import { getFormItemLabel } from './utils';

interface LabeledSpanProps extends FormItemProps, Ii18nLabel {
  children: any;
  value: string;
  formState: any;
}

// tslint:disable-next-line:function-name
export function LabeledSpan(
  {
    name,
    label,
    i18nLabel,
    rules,
    value,
    style,
  }: LabeledSpanProps,
) {
  const fieldLabel = getFormItemLabel(i18nLabel, label);

  return (
    <Form.Item style={style} name={name} label={fieldLabel} rules={rules}>
      <Text type="secondary">{value}</Text>
    </Form.Item>
  );
}
