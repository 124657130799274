import React, { useState } from 'react';
import {
  Checkbox,
  Form,
  Space,
} from '@/components/antd';
import { getFormItemLabel } from '@/components/form-fields/utils';

export const Permissions = (componentProps: any) => {
  const {
    name,
    label,
    i18nLabel,
    rules,
    formState,
    ...props
  } = componentProps;

  const [count, setCount] = useState(true);

  const getValue = () => formState.form.getFieldValue(name) || [];

  const setValue = (newValue) => {
    setTimeout(() => {
      formState.form.setFieldsValue({ [name]: newValue });
      // to trigger rendering the table
      setCount(!count);
    }, 0);
  };

  const value = getValue();
  const fieldLabel = getFormItemLabel(i18nLabel, label);

  return (
    <Form.Item
      {...props}
      name={name}
      label={fieldLabel}
      rules={rules}
    >
      <Space>
        {['Create', 'Read', 'Update', 'Delete'].map((method) => {
          const onChange = (e) => {
            e.preventDefault();
            if (e.target.checked) {
              setValue([...getValue(), method]);
            } else {
              setValue(getValue().filter((m) => m !== method));
            }
          };

          return (
            <Space key={method} style={{ marginRight: '2rem' }}>
              <span>{method}</span>
              <Checkbox disabled={props.disabled} checked={value.includes(method)} onChange={onChange} />
            </Space>
          );
        })}
      </Space>
    </Form.Item>
  );
};
