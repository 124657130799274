import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { Divider, TimePicker } from 'antd';
import Form from 'antd/lib/form';
import { FormattedMessage } from 'react-intl';
import { Option } from 'antd/lib/mentions';
import Col from 'antd/lib/col';
import Row from 'antd/lib/row';
import moment from 'moment/moment';
import { getTranslatedString } from '@/utils';
import { Button, DatePicker as AntdDatePicker, message, Select } from '@/components/antd';
import { useMassUpdateCashedStores } from '@/utils/store';
import { buildingsStore } from '@/stores';
import { buildDropdownOptionsFromState, getValuesDifference, showError } from '@/utils/common';
import { Drawer } from '@/components/drawer';
import { getTimeFromMinutes } from '@/utils/moment';
import { Loader } from '@/components/loader';

export const BuildingConfigsForm = observer(
  ({ store, listState, createState, i18nPrefix, apiMethods, createInitValues = {}, transition }: any) => {
    const isCreate = transition.router.globals.current.name === createState;
    const { id } = transition.router.globals.params;
    const foundItem = store?.items.find((item: any) => item.id === id);
    const [disabled, setDisabled] = useState<boolean>(true);
    const [loadingSubmit, setLoadingSubmit] = useState<boolean>();
    const { loading } = useMassUpdateCashedStores([buildingsStore]);

    const onClose = () => {
      transition.router.stateService.go(listState);
    };

    const onChange = () => {
      if (disabled) {
        setDisabled(false);
      }
    };

    const initValues = foundItem || createInitValues;
    const initialValues = isCreate
      ? {}
      : {
          billingOnPremiseLaunchUnpaidTime: moment(
            getTimeFromMinutes(initValues.billingOnPremiseLaunchUnpaidTime),
            'HH:mm',
          ),
          billingOnPremiseMinBillableTime: moment(
            getTimeFromMinutes(initValues.billingOnPremiseMinBillableTime),
            'HH:mm',
          ),
          productionFloorBathPaidTime: moment(getTimeFromMinutes(initValues.productionFloorBathPaidTime), 'HH:mm'),
          productionFloorBreakPaidTime: moment(getTimeFromMinutes(initValues.productionFloorBreakPaidTime), 'HH:mm'),
          productionFloorLaunchPaidTime: moment(getTimeFromMinutes(initValues.productionFloorLaunchPaidTime), 'HH:mm'),
          productionFloorMinBillableTime: moment(
            getTimeFromMinutes(initValues.productionFloorMinBillableTime),
            'HH:mm',
          ),
          effectiveDate: moment(initValues.effectiveDate, 'YYYY-MM-DD'),
          buildingId: initValues.buildingId,
        };

    const title = isCreate
      ? getTranslatedString(`${i18nPrefix}.create-new`)
      : getTranslatedString(`${i18nPrefix}.${i18nPrefix}-number`, { 0: id });
    const onFinish = async values => {
      try {
        setLoadingSubmit(true);
        if (isCreate) {
          await apiMethods.create(values).source.then(() => store.refresh());
          message.success('Successful Submit.');
          transition.router.stateService.go(listState);
          return await Promise.resolve();
        }
        const { changedItem } = getValuesDifference(initialValues, values);
        await apiMethods.update(foundItem.id, changedItem).source.then(() => store.refresh());
        message.success('Successful Submit.');
        transition.router.stateService.go(listState);
      } catch (e) {
        showError(e);
      } finally {
        setLoadingSubmit(false);
      }
    };
    const [genericForm] = Form.useForm();
    const buildingOptions = buildDropdownOptionsFromState(buildingsStore.items);
    return (
      <>
        <Drawer
          title={title}
          footer={() => (
            <div className="drawer-footer">
              <Button onClick={onClose} className="drawer-footer-button" shape="round">
                <FormattedMessage id="button.cancel" />
              </Button>
              <Button
                onClick={() => {
                  genericForm.submit();
                }}
                htmlType="submit"
                type="primary"
                shape="round"
                disabled={disabled}
                loading={loadingSubmit}
              >
                <FormattedMessage id="button.submit" />
              </Button>
            </div>
          )}
          visible
          onClose={onClose}
          width={window.innerWidth - 250}
        >
          {loading ? (
            <Loader />
          ) : (
            <Form initialValues={initialValues} form={genericForm} onFinish={values => onFinish(values)}>
              <Row align="middle">
                <Col flex="0 0 350px">{getTranslatedString('buildingConfigs.buildingId')}:</Col>
                <Col flex="0 0 160px">
                  <Form.Item key="buildingId" name="buildingId" rules={[{ required: true }]}>
                    <Select onChange={onChange} disabled={!isCreate} style={{ minWidth: '320px' }}>
                      {buildingOptions.map((item: any) => (
                        <Option key={item.value} value={item.value}>
                          {item.label}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row align="middle">
                <Col flex="0 0 350px">{getTranslatedString('buildingConfigs.effectiveDate')}:</Col>
                <Col flex="0 0 160px">
                  <Form.Item style={{ minWidth: '320px' }} name="effectiveDate" rules={[{ required: true }]}>
                    <AntdDatePicker onChange={onChange} style={{ minWidth: '320px' }} picker="date" />
                  </Form.Item>
                </Col>
              </Row>
              <Divider orientation="left" plain>
                <FormattedMessage id="buildingConfigs.billingOnPremise" />
              </Divider>
              <Row align="middle">
                <Col flex="0 0 350px">{getTranslatedString('buildingConfigs.billingOnPremiseLaunchUnpaidTime')}:</Col>
                <Col flex="0 0 160px">
                  <Form.Item
                    style={{ minWidth: '320px' }}
                    name="billingOnPremiseLaunchUnpaidTime"
                    rules={[{ required: true }]}
                  >
                    <TimePicker onChange={onChange} style={{ minWidth: '320px' }} format="HH:mm" />
                  </Form.Item>
                </Col>
              </Row>
              <Row align="middle">
                <Col flex="0 0 350px">{getTranslatedString('buildingConfigs.billingOnPremiseMinBillableTime')}:</Col>
                <Col flex="0 0 160px">
                  <Form.Item
                    style={{ minWidth: '320px' }}
                    name="billingOnPremiseMinBillableTime"
                    rules={[{ required: true }]}
                  >
                    <TimePicker onChange={onChange} style={{ minWidth: '320px' }} format="HH:mm" />
                  </Form.Item>
                </Col>
              </Row>

              <Divider orientation="left" plain>
                <FormattedMessage id="buildingConfigs.productionFloor" />
              </Divider>
              <Row align="middle">
                <Col flex="0 0 350px">{getTranslatedString('buildingConfigs.productionFloorBathPaidTime')}:</Col>
                <Col flex="0 0 160px">
                  <Form.Item
                    style={{ minWidth: '320px' }}
                    name="productionFloorBathPaidTime"
                    rules={[{ required: true }]}
                  >
                    <AntdDatePicker onChange={onChange} style={{ minWidth: '320px' }} picker="time" format="HH:mm" />
                  </Form.Item>
                </Col>
              </Row>
              <Row align="middle">
                <Col flex="0 0 350px">{getTranslatedString('buildingConfigs.productionFloorBreakPaidTime')}:</Col>
                <Col flex="0 0 160px">
                  <Form.Item
                    style={{ minWidth: '320px' }}
                    name="productionFloorBreakPaidTime"
                    rules={[{ required: true }]}
                  >
                    <TimePicker onChange={onChange} style={{ minWidth: '320px' }} format="HH:mm" />
                  </Form.Item>
                </Col>
              </Row>
              <Row align="middle">
                <Col flex="0 0 350px">{getTranslatedString('buildingConfigs.productionFloorLaunchPaidTime')}:</Col>
                <Col flex="0 0 160px">
                  <Form.Item
                    style={{ minWidth: '320px' }}
                    name="productionFloorLaunchPaidTime"
                    rules={[{ required: true }]}
                  >
                    <TimePicker onChange={onChange} style={{ minWidth: '320px' }} format="HH:mm" />
                  </Form.Item>
                </Col>
              </Row>
              <Row align="middle">
                <Col flex="0 0 350px">{getTranslatedString('buildingConfigs.productionFloorMinBillableTime')}:</Col>
                <Col flex="0 0 160px">
                  <Form.Item
                    style={{ minWidth: '320px' }}
                    name="productionFloorMinBillableTime"
                    rules={[{ required: true }]}
                  >
                    <TimePicker onChange={onChange} style={{ minWidth: '320px' }} format="HH:mm" />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          )}
        </Drawer>
      </>
    );
  },
);
