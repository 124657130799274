import { LocalStorageTokenProvider } from '@/utils/access-token-provider';

import { api } from '@/api';
import { AuthStore } from './auth';
import { CachedStorage, CollectionStore, StoreDataLoader } from './collection';
import { LocaleStore } from './locale';
import { SettingsStore } from './settings';
import { ApplicationSettingsStore } from '@/stores/application-settings-store';

export const authStore = new AuthStore(new LocalStorageTokenProvider());
export const localeStore = new LocaleStore();
export const applicationSettingsStore = new ApplicationSettingsStore();

export const usersStore = new CollectionStore<mpg.api.users.UserId, mpg.api.users.User, mpg.api.users.GetParams>(
  new StoreDataLoader(api.users.getAll),
  new CachedStorage(),
);

export const rolesStore = new CollectionStore<mpg.api.roles.RoleId, mpg.api.roles.Role, mpg.api.roles.GetParams>(
  new StoreDataLoader(api.roles.getAll),
  new CachedStorage(),
);

export const permissionsStore = new CollectionStore<
  mpg.api.permissions.PermissionId,
  mpg.api.permissions.Permission,
  mpg.api.permissions.GetParams
>(new StoreDataLoader(api.permissions.getAll), new CachedStorage());

export const permissionsObjectsStore = new CollectionStore<
  mpg.api.permissions.PermissionId,
  mpg.api.permissions.Permission,
  mpg.api.permissions.GetParams
>(new StoreDataLoader(api.permissionsObjects.getAll), new CachedStorage());

export const employeeStore = new CollectionStore<
  mpg.api.employees.EmployeeId,
  mpg.api.employees.Employee,
  mpg.api.employees.GetParams
>(new StoreDataLoader(api.employees.getAll), new CachedStorage());

export const employeesSyncErrorsStore = new CollectionStore<
  mpg.api.employeesSyncErrors.EmployeesSyncErrorsId,
  mpg.api.employeesSyncErrors.EmployeesSyncErrors,
  mpg.api.employeesSyncErrors.GetParams
>(new StoreDataLoader(api.employeesSyncErrors.getAll), new CachedStorage());

export const workingLogStore = new CollectionStore<
  mpg.api.workingLogs.WorkingLogId,
  mpg.api.workingLogs.WorkingLog,
  mpg.api.workingLogs.GetParams
>(new StoreDataLoader(api.workingLogs.getAll), new CachedStorage());

export const billingStore = new CollectionStore<
  mpg.api.workingLogs.WorkingLogId,
  mpg.api.workingLogs.WorkingLogReport,
  mpg.api.workingLogs.GetParams
>(new StoreDataLoader(api.workingLogs.getReportAll), new CachedStorage());

export const languagesStore = new CollectionStore<
  mpg.api.languages.LanguageId,
  mpg.api.languages.Language,
  mpg.api.languages.GetParams
>(new StoreDataLoader(api.languages.getAll), new CachedStorage());

export const agenciesStore = new CollectionStore<
  mpg.api.agencies.AgencyId,
  mpg.api.agencies.Agency,
  mpg.api.agencies.GetParams
>(new StoreDataLoader(api.agencies.getAll), new CachedStorage(60 * 5));
export const agenciesMarkupStore = new CollectionStore<
  mpg.api.agenciesMarkup.AgencyMarkupId,
  mpg.api.agenciesMarkup.AgencyMarkup,
  mpg.api.agenciesMarkup.GetParams
>(new StoreDataLoader(api.agenciesMarkup.getAll), new CachedStorage(60 * 5));

export const approverConfigsStore = new CollectionStore<
  mpg.api.approverConfigs.ApproverId,
  mpg.api.approverConfigs.ApproverConfig,
  mpg.api.approverConfigs.GetParams
>(new StoreDataLoader(api.approverConfigs.getAll), new CachedStorage(60 * 5));

export const areasStore = new CollectionStore<mpg.api.areas.AreaId, mpg.api.areas.Area, mpg.api.areas.GetParams>(
  new StoreDataLoader(api.areas.getAll),
  new CachedStorage(60 * 5),
);

export const buildingsAreasStore = new CollectionStore<
  mpg.api.buildingsAreas.BuildingsAreasId,
  mpg.api.buildingsAreas.BuildingsAreas,
  mpg.api.buildingsAreas.GetParams
>(new StoreDataLoader(api.buildingsAreas.getAll), new CachedStorage(60 * 5));

export const auditStore = new CollectionStore<mpg.api.audit.AuditId, mpg.api.audit.Audit, mpg.api.audit.GetParams>(
  new StoreDataLoader(api.audit.getAll),
  new CachedStorage(60 * 5),
);

export const breaktimesStore = new CollectionStore<
  mpg.api.breaktimes.BreaktimeId,
  mpg.api.breaktimes.Breaktime,
  mpg.api.breaktimes.GetParams
>(new StoreDataLoader(api.breaktimes.getAll), new CachedStorage(60 * 5));
export const buildingPairsStore = new CollectionStore<
  mpg.api.buildingPairs.BuildingPairId,
  mpg.api.buildingPairs.BuildingPair,
  mpg.api.buildingPairs.GetParams
>(new StoreDataLoader(api.buildingPairs.getAll), new CachedStorage(60 * 5));

export const buildingsStore = new CollectionStore<
  mpg.api.buildings.BuildingId,
  mpg.api.buildings.Building,
  mpg.api.buildings.GetParams
>(new StoreDataLoader(api.buildings.getAll), new CachedStorage(60 * 5));

export const buildingConfigsStore = new CollectionStore<
  mpg.api.buildingConfigs.BuildingConfigId,
  mpg.api.buildingConfigs.BuildingConfig,
  mpg.api.buildingConfigs.GetParams
>(new StoreDataLoader(api.buildingConfigs.getAll), new CachedStorage(60 * 5));

export const colorsStore = new CollectionStore<
  mpg.api.colors.ColorsId,
  mpg.api.colors.Colors,
  mpg.api.colors.GetParams
>(new StoreDataLoader(api.colors.getAll), new CachedStorage(60 * 5));

export const colorsCalendarStore = new CollectionStore<
  mpg.api.colorCalendar.ColorCalendarId,
  mpg.api.colorCalendar.ColorCalendar,
  mpg.api.colorCalendar.GetParams
>(new StoreDataLoader(api.colorCalendar.getAll), new CachedStorage(60 * 5));

export const employeesStore = new CollectionStore<
  mpg.api.employees.EmployeeId,
  mpg.api.employees.Employee,
  mpg.api.employees.GetParams
>(new StoreDataLoader(api.employees.getAll), new CachedStorage(60 * 5));

export const positionsStore = new CollectionStore<
  mpg.api.positions.PositionId,
  mpg.api.positions.Position,
  mpg.api.positions.GetParams
>(new StoreDataLoader(api.positions.getAll), new CachedStorage(60 * 5));

export const shiftsStore = new CollectionStore<mpg.api.shifts.ShiftId, mpg.api.shifts.Shift, mpg.api.shifts.GetParams>(
  new StoreDataLoader(api.shifts.getAll),
  new CachedStorage(60 * 5),
);

export const supervisorsStore = new CollectionStore<
  mpg.api.supervisors.SupervisorId,
  mpg.api.supervisors.Supervisor,
  mpg.api.supervisors.GetParams
>(new StoreDataLoader(api.supervisors.getAll), new CachedStorage(60 * 5));

export const settingsStore = new SettingsStore();

export const musterStationsStore = new CollectionStore<
  mpg.api.musterStations.MusterStationId,
  mpg.api.musterStations.MusterStation,
  mpg.api.musterStations.GetParams
>(new StoreDataLoader(api.musterStations.getAll), new CachedStorage(60 * 5));

export const musterStationCaptainsStore = new (class extends CollectionStore<
  mpg.api.musterStationCaptains.MusterStationCaptainId,
  mpg.api.musterStationCaptains.MusterStationCaptain,
  mpg.api.musterStationCaptains.GetParams
> {
  constructor() {
    super(new StoreDataLoader(api.musterStationCaptains.getAll), new CachedStorage(60 * 5));
  }

  fillStore(
    items: mpg.api.musterStationCaptains.MusterStationCaptain[],
    count?: number,
  ): mpg.api.musterStationCaptains.MusterStationCaptain[] {
    if (Array.isArray(items)) {
      return super.fillStore([...items, { id: null, name: 'N/A' }], (count || items.length) + 1);
    }
    return super.fillStore(items, count);
  }
})();

export const linesStore = new CollectionStore<mpg.api.lines.LineId, mpg.api.lines.Line, mpg.api.lines.GetParams>(
  new StoreDataLoader(api.lines.getAll),
  new CachedStorage(60 * 5),
);

export const lockersStore = new CollectionStore<
  mpg.api.lockers.LockerId,
  mpg.api.lockers.Locker,
  mpg.api.lockers.GetParams
>(new StoreDataLoader(api.lockers.getAll), new CachedStorage(60 * 5));
