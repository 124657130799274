import { UIViewInjectedProps } from '@uirouter/react';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { api } from '@/api';
import { DrawerForm } from '@/components/drawer-form';
import { permissionsObjectsStore, permissionsStore, rolesStore } from '@/stores';
import { getTranslatedString } from '@/utils';
import { withoutRepeatValue } from '@/utils/controller';
import { useMassUpdateCashedStores } from '@/utils/store';

import { getFormFields } from './setup';

export const RolesForm = observer(({ transition }: UIViewInjectedProps) => {
  const isCreate = transition.router.globals.current.name === 'base-layout.roles.create';
  const { roleId } = transition.router.globals.params;
  const role = rolesStore.items.find(({ id }: any) => id === +roleId);

  const [currentPermissions, setCurrentPermissions] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(!isCreate);

  useMassUpdateCashedStores([permissionsObjectsStore, permissionsStore]);

  useEffect(() => {
    if (!isCreate) {
      api.roles.getOne(roleId).source
        .then(({ data }) => {
          const perm = data.permissions.map((item) => item.id);
          setCurrentPermissions(perm);
          setLoading(false);
        });
    }
  }, []);

  const onClose = () => {
    transition.router.stateService.go('base-layout.roles');
  };

  if (loading) {
    return (<FormattedMessage id="common.loading" />);
  }

  const initValues = !isCreate && role
    ? {
      ...role,
      permissions: currentPermissions,
    }
    : {};

  const title = isCreate
    ? getTranslatedString('roles.create-new')
    : getTranslatedString('roles.role-number', { 0: roleId });

  const formFields = getFormFields().map((field) => ({
    ...field,
    params: {
      ...field.params,
      disabled: role && role.isSystem,
    },
  }));

  const getNormalizedValuesInCreate = (values: any) => ({
    ...values,
    permissions: undefined,
  });

  const getNormalizedValuesInUpdate = (values: any) => ({
    ...values,
  });

  const resourceController = withoutRepeatValue(initValues, {
    create: async (values: any) => {
      const newRoleResponse = await api.roles.create(getNormalizedValuesInCreate(values)).source;
      const { id } = newRoleResponse.data;

      if (values.permissions && values.permissions.length) {
        await api.roles.assignPermissions(id, values.permissions).source;
      }
      await rolesStore.refresh();
      transition.router.stateService.go('base-layout.roles.edit', { roleId: id });
    },
    update: async (values: any) => {
      await api.roles.update(role.id, getNormalizedValuesInUpdate(values)).source;

      if (values.permissions && values.permissions.length) {
        await api.roles.updatePermissions(role.id, values.permissions).source;
      }

      await rolesStore.refresh();
    },
  });

  return (
    <DrawerForm
      resourceId={roleId}
      title={title}
      initValues={initValues}
      formFields={formFields}
      onClose={onClose}
      onFinish={onClose}
      resourceController={resourceController}
      loaderCondition={!isCreate && !role}
    />
  );
});
