import { request } from '@/utils/request';
import { ApiResponse, WrappedResult } from './types';

export default {
  getAll(params?: mpg.api.audit.GetParams): ApiResponse<WrappedResult<mpg.api.audit.Audit[]>> {
    return request({
      method: 'get',
      url: '/audit',
      params,
    });
  },
  get(id): ApiResponse<WrappedResult<mpg.api.audit.Audit>> {
    return request({
      method: 'get',
      url: `/audit/${id}`,
    });
  },
};
