import React from 'react';
import { UISref } from '@uirouter/react';
import { FormattedMessage } from 'react-intl';
import { Layout as AntdLayout, Menu as AntdMenu } from '@/components/antd';

import { menuItems, IMenuItem } from '@/constants/menu';
import { authStore } from '@/stores';

const { Sider } = AntdLayout;
const { SubMenu } = AntdMenu;

interface MenuProps {
  collapsed: boolean;
  defaultSelectedKey?: string;
  transition?: any;
  menuItems?: any;
}

export interface BaseMenuItemProps {
  state: string;
  icon?: any;
  messageId: string;
  className?: string;
}

const BaseMenuItem = ({
  state, icon, messageId, ...rest
}: BaseMenuItemProps) => (
  <AntdMenu.Item icon={icon} {...rest}>
    <UISref to={state}>
      <a>
        <FormattedMessage id={messageId} />
      </a>
    </UISref>
  </AntdMenu.Item>
);

const renderMenuItem = ({
  key, state, messageId, permissions, icon,
}: IMenuItem) => {
  if (!authStore.hasPermissions(permissions)) {
    return null;
  }

  return (
    <BaseMenuItem
      key={key}
      state={state}
      messageId={messageId}
      icon={icon}
      className="menu-modified"
    />
  );
};

const renderSubMenuItem = ({
  key, messageId, permissions, icon, style,
}: IMenuItem, children: any) => {
  const additionalPermissions = children.map((item) => item.permissions);

  if (!authStore.hasAtLeastOnePermission([...permissions, ...additionalPermissions])) {
    return null;
  }

  return (
    <SubMenu
      key={key}
      title={<FormattedMessage id={messageId} />}
      icon={icon}
      className="sub-menu"
      style={style}
    >
      {children.map(renderMenuItem)}
    </SubMenu>
  );
};

export const Menu = (props: MenuProps) => {
  const { transition } = props;
  const currentState = transition.router.globals.current.name;
  const selectedMenuItem = menuItems.find(({ state }) => currentState.startsWith(state));

  return (
    <Sider width={250} collapsible>

      <div className="logo">
        <UISref to="base-layout.home">
          <span className="c-icon c-icon--logo" />
        </UISref>
      </div>

      <AntdMenu className="c-menu" theme="dark" mode="inline" defaultSelectedKeys={[selectedMenuItem && selectedMenuItem.key]}>
        {menuItems.filter((menuItem) => menuItem.parent === 'root').map((menuItem) => {
          if (menuItem.menuType && menuItem.menuType === 'group') {
            const subMenuItems = menuItems.filter((menu) => menu.parent === menuItem.key);

            return renderSubMenuItem(menuItem, subMenuItems);
          }
          return renderMenuItem(menuItem);
        })}
      </AntdMenu>
    </Sider>
  );
};
