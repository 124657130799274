import { request } from '@/utils/request';
import { ApiResponse, WrappedResult } from './types';

export default {
  getAll(params?: mpg.api.workingLogs.GetParams): ApiResponse<WrappedResult<mpg.api.workingLogs.WorkingLog[]>> {
    return request({
      method: 'get',
      url: '/working-log',
      params,
    });
  },
  get(id): ApiResponse<WrappedResult<mpg.api.workingLogs.WorkingLog>> {
    return request({
      method: 'get',
      url: `/working-log/${id}`,
    });
  },
  create(data: mpg.api.workingLogs.WorkingLogParams): ApiResponse<WrappedResult<mpg.api.workingLogs.WorkingLog>> {
    return request({
      method: 'post',
      url: '/working-log',
      data,
    });
  },
  update(id: number, data: mpg.api.workingLogs.WorkingLogParams): ApiResponse<WrappedResult<mpg.api.workingLogs.WorkingLog>> {
    return request({
      method: 'patch',
      url: `/working-log/${id}`,
      data,
    });
  },
  delete(id: number): ApiResponse<WrappedResult<any>> {
    return request({
      method: 'delete',
      url: `/working-log/${id}`,
    });
  },
  bulkCreate(data: { items: mpg.api.workingLogs.WorkingLog[] }): ApiResponse<WrappedResult<mpg.api.workingLogs.WorkingLog>> {
    return request({
      method: 'post',
      url: '/working-log/bulk/create',
      data,
    });
  },

  bulkUpdate(data: { items: mpg.api.workingLogs.WorkingLog[] }): ApiResponse<WrappedResult<mpg.api.workingLogs.WorkingLog>> {
    return request({
      method: 'post',
      url: '/working-log/bulk/update',
      data,
    });
  },

  bulkDelete(data: { ids: string[] }): ApiResponse<WrappedResult<mpg.api.workingLogs.WorkingLog>> {
    return request({
      method: 'post',
      url: '/working-log/bulk/delete',
      data,
    });
  },
  getReportAll(params?: mpg.api.workingLogs.GetParams): ApiResponse<WrappedResult<mpg.api.workingLogs.WorkingLogReport[]>> {
    return request({
      method: 'get',
      url: '/working-log/report',
      params,
    });
  },
  getReport(params: mpg.api.workingLogs.GetParams): ApiResponse<WrappedResult<mpg.api.workingLogs.WorkingLogReport>> {
    return request({
      method: 'get',
      url: 'working-log/report/byKey',
      params,
    });
  },
  getReportWeekOfs(): ApiResponse<WrappedResult<string[]>> {
    return request({
      method: 'get',
      url: 'working-log/report/weekOfs',
    });
  },
};
