import axios from 'axios';

import { API_URL, AUTH_URL } from '@/constants';
import { request } from '@/utils/request';

interface LoginData {
  email: string;
  password: string;
}

interface RegisterData {
  email: string;
  password: string;
  repeatPassword: string;
}

interface LoginAsData {
  roleIds: number[];
}

export function login(data: LoginData) {
  return axios({
    url: `${AUTH_URL}/login`,
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  });
}

export function changePassword(data: LoginData) {
  return request({
    url: `${AUTH_URL}/changePassword`,
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  });
}

export function loginAs(data: LoginAsData, accessToken: string) {
  return axios({
    url: `${API_URL}/auth/login-as`,
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    data,
  });
}

export function register(data: RegisterData) {
  return axios({
    url: `${AUTH_URL}/register`,
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  });
}

export function session(accessToken: string) {
  return axios({
    url: `${API_URL}/auth/session`,
    method: 'get',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

export async function permissions(accessToken: string) {
  const { data } = await axios({
    url: `${API_URL}/auth/permissions`,
    method: 'get',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });

  return data.data;
}

export async function samlProviders() {
  const { data } = await axios({
    url: `${AUTH_URL}/saml/providers`,
    method: 'get',
  });

  return data.providers;
}
