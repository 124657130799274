import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Space } from '@/components/antd';

import { api } from '@/api';
import { getTranslatedString } from '@/utils';
import { changePassword } from '@/api/auth';
import { withStores } from '@/components/stores-provider';
import { DrawerForm } from '@/components/drawer-form';
import { GenericForm } from '@/components/generic-form';

import { fields, passwordFields } from './setup';

export const Profile = withStores('authStore')(
  observer((
    {
      authStore: {
        profile: {
          id,
          email,
        },
      },
    }: any,
  ) => {
    const userId = id;
    const [currentRoles, setCurrentRoles] = useState<any[]>([]);
    const [isFormVisible, setIsFormVisible] = useState<any>(false);
    const [isLoading, setIsLoading] = useState<any>(true);

    useEffect(() => {
      api.users.get(userId).source
        .then(({ data }) => {
          const roles = data.roles.map((item) => item.name);
          setCurrentRoles(roles);
          setIsLoading(false);
        });
    }, []);

    const resourceController = {
      create: (values: any) => changePassword(values).source,
    };

    const onClose = () => {
      setIsFormVisible(false);
    };

    const onOpen = () => {
      setIsFormVisible(true);
    };

    if (isLoading) {
      return null;
    }

    return (
      <Space direction="vertical" align="center">
        <GenericForm
          formFields={fields}
          initialValues={{ email, roles: currentRoles.join(', ') }}
        />
        {isFormVisible && (
          <DrawerForm
            title={getTranslatedString('profile.change-password')}
            visible={isFormVisible}
            initValues={{}}
            formFields={passwordFields}
            resourceController={resourceController}
            loaderCondition={false}
            onClose={onClose}
          />
        )}
        <Button type="primary" style={{ width: 300, margin: '0 auto' }} onClick={onOpen}>
          <FormattedMessage id="profile.change-password" />
        </Button>
      </Space>
    );
  }),
);
