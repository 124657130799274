import { UIView, UIViewInjectedProps } from '@uirouter/react';
import React, { useState } from 'react';
import { Layout as AntdLayout } from '@/components/antd';

import { Header } from '@/components';
import { Menu } from '@/components/menu';
import { menuItems } from '@/constants/menu';

const { Content } = AntdLayout;

export const Layout = ({ transition }: UIViewInjectedProps) => {
  const [collapsed, setCollapsed] = useState(false);

  const toggle = () => {
    setCollapsed((prevCollapsed: boolean) => !prevCollapsed);
  };

  return (
    <AntdLayout className="main-layout">
      <Menu collapsed={collapsed} transition={transition} menuItems={menuItems} />
      <AntdLayout>
        <Header collapsed={collapsed} toggle={toggle} />
        <Content className="content">
          <UIView />
        </Content>
      </AntdLayout>
    </AntdLayout>
  );
};

export const states = [
  {
    name: 'base-layout',
    component: Layout,
    data: {
      requiresAuth: true,
    },
  },
];
