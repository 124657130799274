import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { DrawerForm } from '@/components/drawer-form';
import { getTranslatedString } from '@/utils';
import { withoutRepeatValue } from '@/utils/controller';
import { getFormFields } from '../common/form-setup';

export const BuildingPairsForm = observer(
  ({
    getFields = null,
    store,
    listState,
    createState,
    bulkEditState,
    i18nPrefix,
    apiMethods,
    fields = [],
    createInitValues = {},
    transition,
  }: any) => {
    const isCreate = transition.router.globals.current.name === createState;
    const isBulkUpdate = transition.router.globals.current.name === bulkEditState;
    const { id } = transition.router.globals.params;
    const foundItem = store.items.find((item: any) => item.id === id);
    const { selected = [] } = transition.targetState().params();
    const [formValue, setFormValue] = useState(foundItem);
    const onClose = () => {
      transition.router.stateService.go(listState);
    };
    useEffect(() => {
      if (isBulkUpdate && !selected.length) {
        onClose();
      }
      setFormValue(foundItem);
    }, []);
    const initValues = foundItem || createInitValues;

    const title = isCreate
      ? getTranslatedString(`${i18nPrefix}.create-new`)
      : getTranslatedString(`${i18nPrefix}.${i18nPrefix}-number`, { 0: id });

    const formFields = getFields
      ? getFields({ isBulkUpdate, isCreate, formValue })
      : getFormFields({ isBulkUpdate, fields });

    const resourceController = withoutRepeatValue(initValues, {
      create: (values: any) => apiMethods.create(values).source.then(() => store.refresh()),
      update: (values: any) => {
        if (isBulkUpdate) {
          return apiMethods
            .bulkUpdate({
              items: selected.map(selectedItemId => ({ ...values, id: selectedItemId })),
            })
            .source.then(() => store.refresh());
        }

        return apiMethods.update(foundItem.id, values).source.then(() => store.refresh());
      },
    });
    const onChange = changedField => {
      setFormValue({ ...formValue, [changedField[0].name[0]]: changedField[0].value });
    };
    return (
      <DrawerForm
        resourceId={id || selected[0]}
        title={title}
        initValues={initValues}
        formFields={formFields}
        onClose={onClose}
        resourceController={resourceController}
        loaderCondition={id && !foundItem}
        onChange={onChange}
      />
    );
  },
);
