import React from 'react';

import { Checkbox } from '@/components/antd';

const getRenderFn = ({ value, setValue }) => (_: any, { visible, dataIndex }: any) => {
  const checked = visible;
  const onChange = ({ target }) => {
    const newValue = value.map((c) => {
      if (dataIndex === c.dataIndex) {
        c.visible = target.checked;
      }
      return c;
    });

    setValue(newValue);
  };

  return <Checkbox onChange={onChange} checked={checked} />;
};

export const getColumns = ({ value, setValue }) => [
  {
    title: 'Column',
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: 'Visible',
    dataIndex: 'visible',
    key: 'visible',
    render: getRenderFn({ value, setValue }),
  },
];
