import { states as agenciesStates } from './agencies';
import { states as agenciesMarkupStates } from './agencies-markup';
import { states as areasStates } from './areas';
import { states as approverConfigsStates } from './approver-configs';
import { states as breaktimesStates } from './breaktimes';
import { states as buildingPairStates } from './building-pairs';
import { states as buildingConfigsStates } from './building-configs';
import { states as buildingsStates } from './buildings';
import { states as buildingsAreasStates } from './buildings-areas';
import { states as colorsStates } from './colors';
import { states as languagesStates } from './languages';
import { states as lines } from './lines';
import { states as lockersStates } from './lockers';
import { states as musterStationsStates } from './muster-stations';
import { states as positionsStates } from './positions';
import { states as shiftsStates } from './shifts';
import { states as supervisorsStates } from './supervisors';

export const states = [
  ...colorsStates,
  ...agenciesStates,
  ...agenciesMarkupStates,
  ...approverConfigsStates,
  ...positionsStates,
  ...languagesStates,
  ...areasStates,
  ...buildingPairStates,
  ...buildingConfigsStates,
  ...breaktimesStates,
  ...buildingsAreasStates,
  ...buildingsStates,
  ...shiftsStates,
  ...supervisorsStates,
  ...musterStationsStates,
  ...lines,
  ...lockersStates,
];
