import { request } from '@/utils/request';
import { ApiResponse, WrappedResult } from './types';

export default {
  getAll(params?: mpg.api.employeesSyncErrors.GetParams): ApiResponse<WrappedResult<mpg.api.employeesSyncErrors.EmployeesSyncErrors[]>> {
    return request({
      method: 'get',
      url: '/friendly-way-sync-errors',
      params,
    });
  },
  get(id): ApiResponse<WrappedResult<mpg.api.employeesSyncErrors.EmployeesSyncErrors>> {
    return request({
      method: 'get',
      url: `/friendly-way-sync-errors/${id}`,
    });
  },
  create(data: mpg.api.employeesSyncErrors.EmployeesSyncErrorsParams): ApiResponse<WrappedResult<mpg.api.employeesSyncErrors.EmployeesSyncErrors>> {
    return request({
      method: 'post',
      url: '/friendly-way-sync-errors',
      data,
    });
  },
  update(id: number, data: mpg.api.employeesSyncErrors.EmployeesSyncErrorsParams): ApiResponse<WrappedResult<mpg.api.employeesSyncErrors.EmployeesSyncErrors>> {
    return request({
      method: 'patch',
      url: `/friendly-way-sync-errors/${id}`,
      data,
    });
  },
  delete(id: number): ApiResponse<WrappedResult<any>> {
    return request({
      method: 'delete',
      url: `/friendly-way-sync-errors/${id}`,
    });
  },
  bulkCreate(data: { items: mpg.api.employeesSyncErrors.EmployeesSyncErrors[] }): ApiResponse<WrappedResult<mpg.api.employeesSyncErrors.EmployeesSyncErrors>> {
    return request({
      method: 'post',
      url: '/friendly-way-sync-errors/bulk/create',
      data,
    });
  },

  bulkUpdate(data: { items: mpg.api.employeesSyncErrors.EmployeesSyncErrors[] }): ApiResponse<WrappedResult<mpg.api.employeesSyncErrors.EmployeesSyncErrors>> {
    return request({
      method: 'post',
      url: '/friendly-way-sync-errors/bulk/update',
      data,
    });
  },

  bulkDelete(data: { ids: string[] }): ApiResponse<WrappedResult<mpg.api.employeesSyncErrors.EmployeesSyncErrors>> {
    return request({
      method: 'post',
      url: '/friendly-way-sync-errors/bulk/delete',
      data,
    });
  },
};
