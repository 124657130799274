import { Col, Row, Select } from 'antd';
import React from 'react';
import RightOutlined from '@ant-design/icons/RightOutlined';
import LeftOutlined from '@ant-design/icons/LeftOutlined';
import { Button, Tooltip } from '@/components/antd';
import { getTranslatedString } from '@/utils';

export const CustomHeader = ({ value, onChange, extra }) => {
  const start = 0;
  const end = 12;
  const monthOptions = [];

  const current = value.clone();
  const localeData = value.localeData();
  const months = [];
  for (let i = 0; i < 12; i++) {
    current.month(i);
    months.push(localeData.monthsShort(current));
  }

  for (let i = start; i < end; i++) {
    monthOptions.push(
      <Select.Option key={i} value={i} className="month-item">
        {months[i]}
      </Select.Option>,
    );
  }

  const year = value.year();
  const month = value.month();
  const options = [];
  for (let i = year - 10; i < year + 10; i += 1) {
    options.push(
      <Select.Option key={i} value={i} className="year-item">
        {i}
      </Select.Option>,
    );
  }
  return (
    <div style={{ padding: 8, display: 'flex', justifyContent: 'end' }}>
      <Row gutter={8}>
        {extra}
        <Col>
          <Tooltip placement="topLeft" title={getTranslatedString('color-calendar.prevMonth')}>
            <Button
              onClick={() => {
                const previousMonth = value.month() - 1;
                const momentObj = value.clone().month(previousMonth);
                onChange(momentObj);
              }}
              icon={<LeftOutlined />}
            />
          </Tooltip>

          <Select
            dropdownMatchSelectWidth={false}
            value={year}
            onChange={newYear => {
              const now = value.clone().year(newYear);
              onChange(now);
            }}
          >
            {options}
          </Select>
        </Col>
        <Col>
          <Select
            dropdownMatchSelectWidth={false}
            value={month}
            onChange={newMonth => {
              const now = value.clone().month(newMonth);
              onChange(now);
            }}
          >
            {monthOptions}
          </Select>
          <Tooltip placement="topLeft" title={getTranslatedString('color-calendar.nextMonth')}>
            <Button
              onClick={() => {
                const nextMonth = value.month() + 1;
                const momentObj = value.clone().month(nextMonth);
                onChange(momentObj);
              }}
              icon={<RightOutlined />}
            />
          </Tooltip>
        </Col>
      </Row>
    </div>
  );
};
