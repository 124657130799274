import React, { useEffect, useState } from 'react';
import {
  Form,
  FormItemProps,
  Modal,
  Upload as AntdUpload,
  UploadChangeParam,
  UploadFile,
  UploadProps as AntdUploadProps,
} from '@/components/antd';

import { PlusOutlined } from '@/components/icons';
import { Ii18nLabel } from '@/types/common';

import { uploadAndGetId } from '@/utils';

import { api } from '@/api';
import { WrappedResult } from '@/api/types';
import { getFormItemLabel } from '@/components/form-fields/utils';
import { showError } from '@/utils/common';

interface InputProps extends FormItemProps, Ii18nLabel, AntdUploadProps {
  name: string;
  children: any;
  onReset: any;
  formState: any;
  objectId: string;
  hasLabel?: boolean;
}

export const Upload = (componentProps: InputProps) => {
  const {
    name,
    label,
    i18nLabel,
    rules,
    disabled,
    formState,
    objectId,
    hasLabel = true,
    ...props
  } = componentProps;

  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [previewVisible, setPreviewVisible] = useState<boolean>(false);
  const [previewImage, setPreviewImage] = useState<string>('');
  const [previewTitle, setPreviewTitle] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);



  const setValue = (value) => {
    formState.form.setFieldsValue({ [name]: value });
  };

  const getValue = () => formState.form.getFieldValue(name);

  const sync = () => {
    const value = getValue();

    if (value) {
      setLoading(true);
      api.attachments.get(value).source
        .then(({ data }: WrappedResult<{ url: string }>) => {
          const { url } = data;

          setFileList([{
            uid: '-1',
            name: '',
            status: 'done',
            url,
          }]);

          setPreviewImage(url);
        })
        .catch(showError)
        .finally(() => setLoading(false));
    } else {
      setFileList([]);
      setPreviewImage('');
      setLoading(false);
    }
  };

  const handleChange = ({ fileList: currentFileList }: UploadChangeParam) => {
    if (currentFileList.length) {
      setLoading(true);
      uploadAndGetId(currentFileList, objectId)
        .then((id) => {
          setValue(id);
        })
        .catch((err) => {
          showError(err);
          setValue(null);
        })
        .finally(sync);
    } else {
      setValue(null);
      sync();
    }
  };

  const handleCancel = () => setPreviewVisible(false);

  const handlePreview = async (file: UploadFile) => {
    setPreviewImage(file.url);
    setPreviewVisible(true);
    setPreviewTitle(getValue());
  };
  useEffect(() => {
    sync();
  }, []);
  const fieldLabel = getFormItemLabel(i18nLabel, label);

  return (
    <>
      <Form.Item
        {...props}
        name={name}
        rules={rules}
        label={hasLabel ? fieldLabel : null}
        messageVariables={{ label: fieldLabel as string }}
      >
        <AntdUpload
          beforeUpload={() => false}
          fileList={fileList}
          onChange={handleChange}
          onPreview={handlePreview}
          listType="picture-card"
          maxCount={1}
          disabled={disabled}
          accept={'image/*'}
        >
          {loading
            ? (
              <div>
                <div style={{ marginTop: 8 }}>Loading...</div>
              </div>
            )
            : (
              !fileList.length && (
                <div>
                  <PlusOutlined />
                  <div style={{ marginTop: 8 }}>Upload Photo</div>
                </div>
              )
            )}
        </AntdUpload>
      </Form.Item>
      <Modal
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt="photo" style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </>
  );
};
