import { password, toBeEqual } from '@/utils/form-rules';

export const fields = [
  {
    name: 'email',
    componentName: 'input',
    params: {
      i18nLabel: 'profile.email',
      readOnly: true,
    },
  },
  {
    name: 'roles',
    componentName: 'textarea',
    params: {
      i18nLabel: 'profile.roles',
      readOnly: true,
    },
  },
];
export const passwordFields: any = [
  {
    name: 'oldPassword',
    componentName: 'password',
    params: {
      i18nLabel: 'profile.oldPassword',
    },
    rules: [{ required: true }],
  },
  {
    name: 'newPassword',
    componentName: 'create-password',
    params: {
      i18nLabel: 'profile.newPassword',
    },
    rules: [
      { required: true },
      password,
    ],
  },
  {
    name: 'repeatNewPassword',
    componentName: 'password',
    params: {
      i18nLabel: 'profile.repeatNewPassword',
    },
    rules: [
      { required: true },
      toBeEqual('newPassword', 'Should match New Password'),
    ],
  },
];
