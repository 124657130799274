import { FormattedMessage } from 'react-intl';

import { api } from '@/api';
import { PERMISSION_OBJ_BUILDING_CONFIGS } from '@/constants/permissions';
import { buildingsStore, buildingConfigsStore } from '@/stores';
import { buildStoreOptions, getStorePropById } from '@/utils/common';
import { getTimeFromMinutes } from '@/utils/moment';

export const createState = 'base-layout.buildingConfig.create';
export const editState = 'base-layout.buildingConfig.edit';
export const bulkEditState = 'base-layout.buildingConfig.bulk-edit';
export const listState = 'base-layout.buildingConfig';

export const i18nPrefix = 'buildingConfigs';

export const mainUrl = '/building-configs';

export const permissionObj = PERMISSION_OBJ_BUILDING_CONFIGS;
export const store = buildingConfigsStore;
export const apiMethods = api.buildingConfigs;

export const columns = () => [
  {
    title: 'id',
    dataIndex: 'id',
    sorter: true,
  },
  {
    title: <FormattedMessage id="buildingConfigs.buildingId" />,
    dataIndex: 'buildingId',
    filters: buildStoreOptions(buildingsStore, 'name'),
    filterMultiple: true,
    sorter: true,
    render: (itemId: string) => getStorePropById<mpg.api.buildings.Building>(itemId, buildingsStore),
  },

  {
    title: <FormattedMessage id="buildingConfigs.productionFloorBathPaidTime" />,
    dataIndex: 'productionFloorBathPaidTime',
    sorter: true,
    render: (itemId: number) => getTimeFromMinutes(itemId),
  },
  {
    title: <FormattedMessage id="buildingConfigs.productionFloorBreakPaidTime" />,
    dataIndex: 'productionFloorBreakPaidTime',
    sorter: true,
    render: (itemId: number) => getTimeFromMinutes(itemId),
  },
  {
    title: <FormattedMessage id="buildingConfigs.productionFloorLaunchPaidTime" />,
    dataIndex: 'productionFloorLaunchPaidTime',
    sorter: true,
    render: (itemId: number) => getTimeFromMinutes(itemId),
  },
  {
    title: <FormattedMessage id="buildingConfigs.productionFloorMinBillableTime" />,
    dataIndex: 'productionFloorMinBillableTime',
    sorter: true,
    render: (itemId: number) => getTimeFromMinutes(itemId),
  },
  {
    title: <FormattedMessage id="buildingConfigs.billingOnPremiseLaunchUnpaidTime" />,
    dataIndex: 'billingOnPremiseLaunchUnpaidTime',
    sorter: true,
    render: (itemId: number) => getTimeFromMinutes(itemId),
  },
  {
    title: <FormattedMessage id="buildingConfigs.billingOnPremiseMinBillableTime" />,
    dataIndex: 'billingOnPremiseMinBillableTime',
    sorter: true,
    render: (itemId: number) => getTimeFromMinutes(itemId),
  },
  {
    title: <FormattedMessage id="buildingConfigs.effectiveDate" />,
    dataIndex: 'effectiveDate',
    sorter: true,
  },
];

export default {
  listState,
  createState,
  bulkEditState,
  editState,
  i18nPrefix,
  permissionObj,
  store,
  apiMethods,
  columns,
  mainUrl,
  additionalStore: buildingsStore,
};
