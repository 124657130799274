import { request } from '@/utils/request';
import { ApiResponse, WrappedResult } from './types';

export default {
  getAll(params?: mpg.api.employees.GetParams): ApiResponse<WrappedResult<mpg.api.employees.Employee[]>> {
    return request({
      method: 'get',
      url: '/employees',
      params,
    });
  },
  get(id): ApiResponse<WrappedResult<mpg.api.employees.Employee>> {
    return request({
      method: 'get',
      url: `/employees/${id}`,
    });
  },
  create(data: mpg.api.employees.EmployeeParams): ApiResponse<WrappedResult<mpg.api.employees.Employee>> {
    return request({
      method: 'post',
      url: '/employees',
      data,
    });
  },
  update(id: number, data: mpg.api.employees.EmployeeParams): ApiResponse<WrappedResult<mpg.api.employees.Employee>> {
    return request({
      method: 'patch',
      url: `/employees/${id}`,
      data,
    });
  },
  delete(id: number): ApiResponse<WrappedResult<any>> {
    return request({
      method: 'delete',
      url: `/employees/${id}`,
    });
  },
  bulkCreate(data: { items: mpg.api.employees.Employee[] }): ApiResponse<WrappedResult<mpg.api.employees.Employee>> {
    return request({
      method: 'post',
      url: '/employees/bulk/create',
      data,
    });
  },

  bulkUpdate(data: { items: mpg.api.employees.Employee[] }): ApiResponse<WrappedResult<mpg.api.employees.Employee>> {
    return request({
      method: 'post',
      url: '/employees/bulk/update',
      data,
    });
  },

  bulkDelete(data: { ids: string[] }): ApiResponse<WrappedResult<mpg.api.employees.Employee>> {
    return request({
      method: 'post',
      url: '/employees/bulk/delete',
      data,
    });
  },
  exportToExcel(params?: mpg.api.employees.GetParams): ApiResponse<WrappedResult<Blob>> {
    return request({
      method: 'post',
      url: '/employees/export',
      params,
      config: {
        responseType: 'blob',
      },
    });
  },
};
