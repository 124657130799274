import { getFormattedEffectiveDate, getFormattedMinutes } from '@/utils/moment';
import { request } from '@/utils/request';
import { ApiResponse, WrappedResult } from './types';

const getFormattedData = data => {
  const { effectiveDate, buildingId, ...timeData } = data;
  const formattedTimeData = timeData;

  for (const key in formattedTimeData) {
    if (formattedTimeData[key]) {
      const formattedMinutes = formattedTimeData[key].format('HH:mm');
      formattedTimeData[key] = getFormattedMinutes(formattedMinutes);
    }
  }

  return {
    effectiveDate: getFormattedEffectiveDate(effectiveDate),
    buildingId,
    ...formattedTimeData,
  };
};

export default {
  getAll(
    params?: mpg.api.buildingConfigs.GetParams,
  ): ApiResponse<WrappedResult<mpg.api.buildingConfigs.BuildingConfig[]>> {
    return request({
      method: 'get',
      url: '/building-configs',
      params,
    });
  },
  get(id): ApiResponse<WrappedResult<mpg.api.buildingConfigs.BuildingConfig>> {
    return request({
      method: 'get',
      url: `/building-configs/${id}`,
    });
  },
  create(
    data: mpg.api.buildingConfigs.BuildingConfigParams,
  ): ApiResponse<WrappedResult<mpg.api.buildingConfigs.BuildingConfig>> {
    const formattedData = getFormattedData(data);
    return request({
      method: 'post',
      url: '/building-configs',
      data: formattedData,
    });
  },
  update(
    id: number,
    data: mpg.api.buildingConfigs.BuildingConfigParams,
  ): ApiResponse<WrappedResult<mpg.api.buildingConfigs.BuildingConfig>> {
    const formattedData = getFormattedData(data);

    return request({
      method: 'patch',
      url: `/building-configs/${id}`,
      data: formattedData,
    });
  },
  delete(id: number): ApiResponse<WrappedResult<any>> {
    return request({
      method: 'delete',
      url: `/building-configs/${id}`,
    });
  },
};
