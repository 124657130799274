import React, { useState } from 'react';
import {
  Checkbox,
  Form,
  Select,
  Space,
  Table,
} from '@/components/antd';

import { getFormItemLabel } from '@/components/form-fields/utils';
import { usePermissionsData } from '@/hooks';
import { permissionsStore } from '@/stores';

const getRenderFn = (action, { value, setValue, disabled }) => (_: any, { permissionsObject }: any) => {
  let id;
  let checked;

  try {
    id = permissionsObject[action] && permissionsObject[action].id;

    if (!id) {
      return '---';
    }
    checked = value.includes(id);
  } catch (err) {
    return '---';
  }

  const onChange = ({ target }) => {
    if (target.checked) {
      if (!value.includes(id)) {
        setValue([...value, id]);
      }
    } else {
      setValue(value.filter((e) => e !== id));
    }
  };

  return <Checkbox onChange={onChange} checked={checked} style={{ margin: 'auto' }} disabled={disabled} />;
};

const getTitle = (action, tableData, value, setValue, disabled) => () => {
  const filteredActionIds = tableData.map((item) => {
    const { id } = (item.permissionsObject && item.permissionsObject[action]) || {};
    return id;
  });

  const checked = filteredActionIds.every((id) => value.includes(id));

  const onChange = (e) => {
    if (e.target.checked) {
      const newValue = [...value];

      filteredActionIds.forEach((id) => {
        if (!value.includes(id)) {
          newValue.push(id);
        }
      });
      setValue(newValue);
    } else {
      setValue(value.filter((id) => !filteredActionIds.includes(id)));
    }
  };

  return (
    <Space style={{ marginRight: '1rem', marginLeft: '1rem' }}>
      <Space>
        {action}
        <Checkbox disabled={disabled} onChange={onChange} checked={checked} />
      </Space>
    </Space>
  );
};

const getColumns = ({
  value, setValue, tableData, disabled,
}) => [
  {
    title: 'Permission',
    dataIndex: 'name',
  },
  ...['Create', 'Read', 'Update', 'Delete'].map((method) => ({
    title: getTitle(method, tableData, value, setValue, disabled),
    dataIndex: method,
    render: getRenderFn(method, { value, setValue, disabled }),
    align: 'center',
  })),
];

export const PermissionTable = (componentProps: any) => {
  const {
    name,
    label,
    i18nLabel,
    rules,
    formState,
    disabled,
    ...props
  } = componentProps;

  const [count, setCount] = useState(true);
  const {
    selectedTab, onChange, permissionsData, options,
  } = usePermissionsData();

  const setValue = (newValue) => {
    setTimeout(() => {
      formState.form.setFieldsValue({ [name]: newValue });
      // to trigger rendering the table
      setCount(!count);
    }, 0);
  };

  const value = formState.form.getFieldValue(name) || [];
  const fieldLabel = getFormItemLabel(i18nLabel, label);
  const tableData = permissionsData[selectedTab] || [];
  const columns: any = getColumns({
    value, setValue, tableData, disabled,
  });
  const getValueFromEvent = () => value;

  return (
    <Form.Item
      {...props}
      name={name}
      label={fieldLabel}
      rules={rules}
      getValueFromEvent={getValueFromEvent}
    >
      <Space direction="vertical">
        <Space style={{ marginTop: '0.5rem' }}>
          {['Create', 'Read', 'Update', 'Delete'].map((method) => {
            const actionIds = permissionsStore.items
              .filter(({ action }: any) => action === method)
              .map(({ id }: any) => id);

            const checked = actionIds.every((id) => value.includes(id));

            const onChangeValue = (e) => {
              if (e.target.checked) {
                const newValue = [...value];

                actionIds.forEach((id) => {
                  if (!value.includes(id)) {
                    newValue.push(id);
                  }
                });
                setValue(newValue);
              } else {
                setValue(value.filter((id) => !actionIds.includes(id)));
              }
            };

            return (
              <Space key={method} style={{ marginRight: '2rem' }}>
                <span>
                  {method}
                  {' '}
                  all
                </span>
                <Checkbox checked={checked} onChange={onChangeValue} disabled={disabled} />
              </Space>
            );
          })}
        </Space>
        <Select
          showSearch
          placeholder="Select an entity"
          onChange={onChange}
          filterOption={(input, option: any) => option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          options={options}
          value={selectedTab}
          style={{ width: 200 }}
        />
        <Table rowKey="id" columns={columns} dataSource={tableData} pagination={false} />
      </Space>
    </Form.Item>
  );
};
