import { UIViewInjectedProps } from '@uirouter/react';
import { observer } from 'mobx-react-lite';
import moment from 'moment';

import { DrawerForm } from '@/components/drawer-form';
import { employeesSyncErrorsStore } from '@/stores';
import { getTranslatedString } from '@/utils';

import { getFormFields } from './setup';

export const Form = observer(({ transition }: UIViewInjectedProps) => {
  const { employeesSyncErrors } = transition.router.globals.params;
  const foundItem = employeesSyncErrorsStore.items.find(({ id }: any) => id === employeesSyncErrors);

  const { selected = [] } = transition.targetState().params();

  const onClose = () => {
    transition.router.stateService.go('base-layout.employeesSyncErrors');
  };

  const initValues = foundItem && {
    ...foundItem,
    params: foundItem.params && JSON.stringify(foundItem.params, null, 2),
    ranAt: foundItem.params && moment(foundItem.ranAt),
  };

  const title = getTranslatedString('employeesSyncErrors.employeesSyncErrors-number', { 0: employeesSyncErrors });

  const formFields = getFormFields().map((item) => ({
    ...item,
    params: {
      ...item.params,
      disabled: true,
    },
  }));

  return (
    <DrawerForm
      resourceId={employeesSyncErrors || selected[0]}
      title={title}
      initValues={initValues}
      formFields={formFields}
      onClose={onClose}
      loaderCondition={employeesSyncErrors && !foundItem}
    />
  );
});
