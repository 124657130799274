import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Space } from '@/components/antd';
import { ReadAction } from '@/components/table/actions';
import { PERMISSION_OBJ_AUDIT, PERMISSION_READ } from '@/constants/permissions';
import { withPermissions } from '@/hocs/permission';
import { usersStore } from '@/stores';
import { getFormattedDate } from '@/utils/moment';

const actions = {
  C: 'Create',
  R: 'Read',
  U: 'Update',
  D: 'Delete',
};
const PermissionedReadAction = withPermissions([[PERMISSION_OBJ_AUDIT, PERMISSION_READ]])(ReadAction);

const Action = (_: any, { action }: any) => <Space>{actions[action] || ''}</Space>;

const Actions = (_: any, { id }: any) => (
  <Space>
    <PermissionedReadAction uiSref={{ to: 'base-layout.audit.edit', params: { itemId: id } }} />
  </Space>
);

const Difference = (_: any, { newData: recordNewData, originalData: recordOriginalData }: any) => {
  const newData = recordNewData || {};
  const originalData = recordOriginalData || {};

  const keys = Object.keys({ ...newData, ...originalData });

  const changedKeys = keys.filter(key => newData[key] !== originalData[key]).sort();

  return (
    <Space
      style={{
        width: '100%',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
    >
      {keys.length === changedKeys.length ? 'All' : changedKeys.join(', ')}
    </Space>
  );
};

const DateComponent = (value: any) => <Space>{getFormattedDate(value)}</Space>;

const User = (value: any) => {
  const user = usersStore.items.find(({ id }) => id === value);

  return <Space>{user ? user.name || user.email : 'deleted'}</Space>;
};

export const columns = [
  {
    title: 'id',
    dataIndex: 'id',
    sorter: true,
  },
  {
    title: <FormattedMessage id="audit.user" />,
    dataIndex: 'userId',
    sorter: true,
    render: User,
  },
  {
    title: <FormattedMessage id="audit.tableName" />,
    dataIndex: 'tableName',
    sorter: true,
  },
  {
    title: <FormattedMessage id="audit.action" />,
    dataIndex: 'action',
    sorter: true,
    render: Action,
  },
  {
    title: <FormattedMessage id="audit.createdAt" />,
    dataIndex: 'createdAt',
    sorter: true,
    render: DateComponent,
  },
  {
    title: <FormattedMessage id="audit.changedFields" />,
    dataIndex: 'changedFields',
    render: Difference,
  },
  {
    title: <FormattedMessage id="table.actions" />,
    render: Actions,
  },
];
