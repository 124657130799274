export enum AddressType {
  Factory = 'Factory',
  Invoicing = 'Invoicing',
  Store = 'Store',
}

export enum ProductType {
  Ordinary = 'Ordinary',
  Utz = 'Utz',
  FairTrade = 'Fair trade',
  RainforestAlliance = 'Rainforest alliance',
}

export enum CounterpartyType {
  Bank = 'Bank',
  Customer = 'Customer',
  Forwarder = 'Forwarder',
  ShippingLine = 'Shipping line',
  Verification = 'Verification',
}

export enum BVStatus {
  BA = 'BA',
  BV = 'BV',
  BVRequestMade = 'BV request made',
  BVToAsk = 'BV to ask',
  RequestForExtension = 'Request for extension',
  ReworkRequestMade = 'Rework request made',
}

export enum AnalysisClassification {
  GoodFermented = 'Good fermented',
  FairFermented = 'Fair fermented',
}

export enum LotEventType {
  Update = 'update',
  Analysis = 'analysis',
  Weighing = 'weighing',
  BV = 'bv',
  Fumigation = 'fumigation',
  Proposal = 'proposal',
}

export enum ProposalStatus {
  Withdrawn = 'withdrawn',
  Pending = 'pending',
  Accepted = 'accepted',
  Rejected = 'rejected',
}

export enum AttachmentType {
  Contract = 'Contract',
  Other = 'Other',
  PersonPhoto = 'PersonPhoto',
}

export enum ContainerType {
  Twenty = '20',
  Forty = '40',
  Conventional = 'conventional',
}

export enum ProductionStatus {
  ToProduce = 'To produce',
  UnderProduction = 'Under production',
  Produced = 'Produced',
}

export enum ProductQuality {
  Low = 'Low',
  Medium = 'Medium',
  High = 'High',
}

export enum TransferStatus {
  Departed = 'departed',
  InTransit = 'in transit',
  Arrived = 'arrived',
}

export enum NoteObjectType {
  Activity = 'Activity',
  Address = 'Address',
  Boarding = 'Boarding',
  BV = 'BV',
  Campaign = 'Campaign',
  Container = 'Container',
  ContractLineItem = 'ContractLineItem',
  Counterparty = 'Counterparty',
  CounterpartyContract = 'CounterpartyContract',
  CV = 'CV',
  CVExecution = 'CVExecution',
  Lot = 'Lot',
  LotAnalysis = 'LotAnalysis',
  LotFumigation = 'LotFumigation',
  LotPledge = 'LotPledge',
  LotProposal = 'LotProposal',
  LotWeighing = 'LotWeighing',
  Product = 'Product',
  Transfer = 'Transfer',
  TransitOrder = 'TransitOrder',
}

export enum UserRole {
  MPG = 'MPG',
  Agency = 'Agency',
}

export enum EmployeeStatus {
  New = 'new',
  Temp = 'temporary',
  Perm = 'permanent',
  Term = 'terminated',
  TermAgency = 'terminated_agency',
}

export enum StateStatus {
  Unavailable = 'unavailable',
}

export enum AreaType {
  Working = 'working',
  Idle = 'idle',
}

export enum BuildingAreaType {
  Active = 'active',
  Archived = 'archived',
}

export enum ShiftType {
  Day = 'day',
  Night = 'night',
}
