import axios from 'axios';
import qs from 'querystring-es3';

import { API_URL, LOCAL_STORAGE_KEY_ACCESS_TOKEN } from '@/constants';
import { authStore } from '@/stores';

const defaultTimeout = 180000;
const defaultBaseURL = API_URL;

export const request = (settings: any) => {
  const {
    method = 'get',
    url,
    baseURL = null,
    data = {},
    headers = {},
    timeout = defaultTimeout,
    params = {},
    withCredentials = false,
    config = {},
    customToken,
  } = settings;

  let cancelToken: any;
  const token = customToken || localStorage.getItem(LOCAL_STORAGE_KEY_ACCESS_TOKEN);

  const instance = axios.create({
    baseURL: baseURL || defaultBaseURL,
    headers: {
      'Content-Type': 'application/json',
      ...headers,
      Authorization: `Bearer ${token}`,
    },
    timeout,
    withCredentials,
    cancelToken: new axios.CancelToken((c) => cancelToken = c),
    paramsSerializer: (params1: any): string => qs.stringify(params1),
    ...config,
  });

  return {
    cancel: cancelToken,
    source: instance.request({
      url, method, params, data,
    })
      .then(({ data: respData }: any) => respData)
      .catch((e) => {
        if (e && e.response) {
          const { response } = e;

          if (response.data && response.data.error) {
            const { error } = response.data;
            // Session Expired
            if (error.code === 6) {
              window.location.href = '';
            }
          }

          if (response.status === 401) {
            authStore.logout();
            window.location.href = '';
          }
        }
        throw e;
      }),
  };
};
