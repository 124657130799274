import {
  PERMISSION_CREATE,
  PERMISSION_OBJ_PERMISSION,
  PERMISSION_OBJ_PERMISSION_OBJECT,
  PERMISSION_READ,
  PERMISSION_UPDATE,
} from '@/constants/permissions';

import { PermissionForm } from './components/form';
import { Permissions } from './components/table';

export const states = [
  {
    name: 'base-layout.permissions-objects',
    url: '/permissions-objects',
    component: Permissions,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_PERMISSION_OBJECT, PERMISSION_READ],
        [PERMISSION_OBJ_PERMISSION, PERMISSION_READ],
      ],
    },
  },
  {
    name: 'base-layout.permissions-objects.create',
    url: '/new',
    component: PermissionForm,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_PERMISSION_OBJECT, PERMISSION_CREATE],
        [PERMISSION_OBJ_PERMISSION, PERMISSION_CREATE],
      ],
    },
  },
  {
    name: 'base-layout.permissions-objects.edit',
    url: '/:permissionObjectId/edit',
    component: PermissionForm,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_PERMISSION_OBJECT, PERMISSION_UPDATE],
        [PERMISSION_OBJ_PERMISSION, PERMISSION_UPDATE],
      ],
    },
  },
];
