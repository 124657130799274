import {
  ColumnsType,
  ColumnType,
  SorterResult,
  TablePaginationConfig,
} from 'antd/lib/table/interface';

import { ITypedKeyValueObject } from '@/types/common';

export const toQueryFormat = (
  genericFilters,
  pagination: TablePaginationConfig,
  filters: ITypedKeyValueObject<any>,
  sorter: SorterResult<any>,
) => {
  const { pageSize, current }: TablePaginationConfig = pagination;
  const { field, order }: SorterResult<any> = sorter;
  const normalizedField = Array.isArray(field) ? field.join('.') : field;
  const sort = order ? { sort: `${normalizedField}:${order === 'ascend' ? 1 : -1}` } : {};

  return {
    limit: pageSize,
    skip: pageSize * ((current < 1) ? 0 : current - 1),
    ...sort,
    ...genericFilters(filters),
  };
};

export const getGenericFilters = (columns: ColumnsType) => (filtersValue: ITypedKeyValueObject<any>) => columns
  .filter(({ filters, filterDropdown, dataIndex }: ColumnType<any>) => (filters || filterDropdown)
      && filtersValue[String(dataIndex)])
  .reduce((prev: ITypedKeyValueObject<any>, { dataIndex }: ColumnType<any>) => ({
    ...prev,
    [String(dataIndex)]: filtersValue[String(dataIndex)],
  }), {});

export const saveSettingToLocalStorage = (id, data) => {
  try {
    localStorage.setItem(id, JSON.stringify(data));
  } catch (err) {
    return null;
  }
};

export const getSettingFromLocalStorage = (id) => {
  try {
    return JSON.parse(localStorage.getItem(id));
  } catch (err) {
    return null;
  }
};

export const defaultMinWidth = 100;

export const getDefaultSettings = (finalColumns) => finalColumns
  .map(({ dataIndex, width, minWidth }) => ({
    dataIndex,
    visible: true,
    width: width || minWidth || defaultMinWidth,
  }));

export const findByDataIndex = (arr, dataIndex) => arr.find((item) => item.dataIndex === dataIndex);

export const isValidSavedSettings = (settings, finalColumns) => {
  if (!settings) {
    return false;
  }

  const mapToString = (arr) => arr.map(({ dataIndex }) => dataIndex).sort().join();

  if (mapToString(settings) !== mapToString(finalColumns)) {
    // columns settings were changed
    return false;
  }

  return settings.every((col) => {
    const found = findByDataIndex(finalColumns, col.dataIndex);
    return col.width >= (found.minWidth || defaultMinWidth);
  });
};

export const getInitialTableSettings = (localStorageKey, finalColumns) => {
  const savedSettings = getSettingFromLocalStorage(localStorageKey);

  if (isValidSavedSettings(savedSettings, finalColumns)) {
    return savedSettings;
  }
  return getDefaultSettings(finalColumns);
};
