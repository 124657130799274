import { UIViewInjectedProps } from '@uirouter/react';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { api } from '@/api';
import { DrawerForm } from '@/components/drawer-form';
import { permissionsObjectsStore, permissionsStore } from '@/stores';
import { getTranslatedString } from '@/utils';
import { useMassUpdateCashedStores } from '@/utils/store';
import { getValuesDifference } from '@/utils/common';

import { getFormFields } from './setup';

export const PermissionForm = observer(({ transition }: UIViewInjectedProps) => {
  const isCreate = transition.router.globals.current.name === 'base-layout.permissions-objects.create';
  const { permissionObjectId } = transition.router.globals.params;
  const permissionObject = permissionsObjectsStore.items.find(({ id }: any) => id === +permissionObjectId);
  const { loading } = useMassUpdateCashedStores([permissionsObjectsStore, permissionsStore]);

  const onClose = () => {
    transition.router.stateService.go('base-layout.permissions-objects');
  };

  if (loading) {
    return (<FormattedMessage id="common.loading" />);
  }

  const initValues: any = !isCreate && permissionObject
    ? {
      ...permissionObject,
      methods: permissionsStore.items
        .filter((permission: any) => permission.object.id === permissionObject.id)
        .map(({ action }: any) => action),
    }
    : {};

  const title = isCreate
    ? getTranslatedString('permissions-objects.create-new')
    : getTranslatedString('permissions-objects.permission-object-number', { 0: permissionObjectId });

  const formFields = getFormFields().map((field) => ({
    ...field,
    params: {
      ...field.params,
      disabled: permissionObject && permissionObject.isSystem,
    },
  }));
  const resourceController = {
    create: async (values: any) => {
      const { data } = await api.permissionsObjects.create(values).source;

      const toAdd = (values.methods || []).filter((m) => !(initValues.methods || []).includes(m));
      for (const action of toAdd) {
        await api.permissions.create({ objectId: data.id, name: values.name, action });
      }

      await permissionsObjectsStore.refresh();
      await permissionsStore.refresh();
      transition.router.stateService.go('base-layout.permissions-objects');
    },
    update: async (values: any) => {
      const { changedProperties, changedItem } = getValuesDifference(initValues, values, ['methods']);
      const wasPropertiesChanged = !!Object.keys(changedProperties).length;

      if (wasPropertiesChanged) {
        await api.permissionsObjects.update(permissionObject.id, changedItem).source;
      }

      const toAdd = values.methods.filter((m) => !(initValues.methods || []).includes(m));
      const toDelete = initValues.methods.filter((m) => !(values.methods || []).includes(m));

      const toDeleteIds = permissionsStore.items
        .filter((permission: any) => permission.object.id === permissionObject.id && toDelete.includes(permission.action))
        .map(({ id }) => id);

      for (const id of toDeleteIds) {
        await api.permissions.delete(id);
      }

      for (const action of toAdd) {
        await api.permissions.create({ objectId: permissionObject.id, name: values.name, action });
      }

      await permissionsObjectsStore.refresh();
      await permissionsStore.refresh();
      transition.router.stateService.go('base-layout.permissions-objects');
    },
  };

  return (
    <DrawerForm
      resourceId={permissionObjectId}
      title={title}
      initValues={initValues}
      formFields={formFields}
      onClose={onClose}
      resourceController={resourceController}
      loaderCondition={!isCreate && !permissionObject}
      useDisableSubmit={false}
    />
  );
});
