import { useState } from 'react';
import { FormInstance } from 'antd';
import { Form } from '@/components/antd';

export const useDrawerForm = <T = any>(
  onChange?: (changedFields: T) => void,
  propsForm?: FormInstance,
) => {
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
  const [disableSubmit, setDisableSubmit] = useState<boolean>(true);
  const [formRef] = Form.useForm();
  const form = propsForm || formRef;

  const onStartSubmit = () => {
    setLoadingSubmit(true);
  };

  const onFinishSubmit = () => {
    setLoadingSubmit(false);
    setDisableSubmit(true);
  };
  const onFieldsChange = (changedFields: any, all: any[]) => {
    if (onChange) {
      onChange(changedFields);
    }
    const disable = all.some(({ errors }: any) => Boolean(errors.length));
    if (disableSubmit !== disable) {
      setDisableSubmit(disable);
    }
  };
  return {
    loadingSubmit,
    disableSubmit,
    form,
    onStartSubmit,
    onFinishSubmit,
    onFieldsChange,
  };
};
