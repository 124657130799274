import { useEffect, useState } from 'react';

import { permissionsObjectsStore, permissionsStore } from '@/stores';
import { capitalizeFirstLetter } from '@/utils/common';

export const usePermissionsData = () => {
  const permissionsData = permissionsObjectsStore.items
    .map((item) => {
      const items = permissionsStore.items
        .filter((permission: any) => permission.object.id === item.id);

      return {
        ...item,
        methods: items
          .map(({ action }: any) => action),
        methodsIds: items
          .map(({ id }: any) => id),
        permissionsObject: items
          .reduce((acc, permission: any) => {
            acc[permission.action] = permission;
            return acc;
          }, {}),
      };
    })
    .reduce((acc, item: any) => {
      const { slugname } = item;
      const key = slugname.split('.')[0];

      acc[key] = acc[key] || [];
      acc[key].push(item);

      return acc;
    }, {});

  const tabs = Object.keys(permissionsData);
  const options = tabs.map((tab: any) => ({ label: capitalizeFirstLetter(tab), value: tab }));

  const [selectedTab, setSelectedTab] = useState<any>();

  useEffect(() => {
    if (!selectedTab && tabs[0]) {
      setSelectedTab(tabs[0]);
    }
  }, [tabs]);

  const onChange = (value) => {
    setSelectedTab(value);
  };

  return {
    selectedTab,
    permissionsData,
    onChange,
    tabs,
    options,
  };
};
