import { api } from '@/api';
import { WrappedResult } from '@/api/types';

export const uploadAndGetId = (files, objectId?: string) => {
  const formData = new FormData();
  formData.append('file', files[0].originFileObj);

  if (objectId) {
    formData.append('objectId', objectId);
  }

  formData.append('type', 'PersonPhoto');

  return api.attachments.attachFile(formData).source
    .then(({ data }: WrappedResult<mpg.api.attachments.Attachment>) => data.key);
};

export const downloadBlobFile = (data, fileName) => {
  const blob = new Blob([data]);
  const link = document.createElement('a');
  link.download = fileName;
  link.href = URL.createObjectURL(blob);
  link.click();
  URL.revokeObjectURL(link.href);
};
