import { getFormattedEffectiveDate } from '@/utils/moment';
import { request } from '@/utils/request';
import { ApiResponse, WrappedResult } from './types';

export default {
  getAll(params?: mpg.api.agenciesMarkup.GetParams): ApiResponse<WrappedResult<mpg.api.agenciesMarkup.AgencyMarkup[]>> {
    return request({
      method: 'get',
      url: '/agency-markups',
      params,
    });
  },
  get(id): ApiResponse<WrappedResult<mpg.api.agenciesMarkup.AgencyMarkup>> {
    return request({
      method: 'get',
      url: `/agency-markups/${id}`,
    });
  },
  create(data: mpg.api.agenciesMarkup.AgencyMarkupParams): ApiResponse<WrappedResult<mpg.api.agenciesMarkup.AgencyMarkup>> {
    return request({
      method: 'post',
      url: '/agency-markups',
      data: {
        ...data,
        effectiveDate: getFormattedEffectiveDate(data.effectiveDate),
      },
    });
  },
  update(id: number, data: mpg.api.agenciesMarkup.AgencyMarkupParams): ApiResponse<WrappedResult<mpg.api.agenciesMarkup.AgencyMarkup>> {
    return request({
      method: 'patch',
      url: `/agency-markups/${id}`,
      data,
    });
  },
  delete(id: number): ApiResponse<WrappedResult<any>> {
    return request({
      method: 'delete',
      url: `/agency-markups/${id}`,
    });
  },
};
