import { FormattedMessage } from 'react-intl';

import { api } from '@/api';
import { PERMISSION_OBJ_LOCKERS } from '@/constants/permissions';
import { buildingsStore, lockersStore } from '@/stores';
import { StateStatus } from '@/types/enums';
import { buildDropdownOptionsFromState, buildStoreOptions, getStorePropById } from '@/utils/common';

export const createState = StateStatus.Unavailable;
export const editState = 'base-layout.lockers.edit';
export const bulkEditState = 'base-layout.lockers.bulk-edit';
export const listState = 'base-layout.lockers';

export const i18nPrefix = 'lockers';

export const mainUrl = '/lockers';

export const permissionObj = PERMISSION_OBJ_LOCKERS;
export const store = lockersStore;
export const additionalStore = buildingsStore;
export const apiMethods = api.lockers;

export const getFields = (isBulkUpdate: boolean) => {
  const fields = [
    {
      name: 'buildingId',
      componentName: 'dropdown',
      params: {
        i18nLabel: 'lockers.building',
        options: buildDropdownOptionsFromState(buildingsStore.items),
      },
      rules: [{ required: true }],
    },
    {
      name: 'count',
      componentName: 'input-number',
      params: {
        i18nLabel: 'lockers.count',
        min: 0,
      },
      rules: [{ required: true }],
    },
  ];

  return fields
    .map((field) => {
      if (isBulkUpdate) {
        field.rules = field.rules.filter(({ required }) => !required);
      }
      return field;
    });
};

export const columns = () => [
  {
    title: 'id',
    dataIndex: 'id',
    sorter: true,
  },
  {
    title: <FormattedMessage id="lockers.count" />,
    dataIndex: 'count',
    sorter: true,
  },
  {
    title: <FormattedMessage id="lockers.building" />,
    dataIndex: 'buildingId',
    filters: buildStoreOptions(buildingsStore, 'name'),
    filterMultiple: true,
    sorter: true,
    render: (itemId: string) => getStorePropById<mpg.api.buildings.Building>(itemId, buildingsStore),
  },
];

export default {
  useCustomClass: true,
  listState,
  createState,
  bulkEditState,
  editState,
  i18nPrefix,
  permissionObj,
  store,
  apiMethods,
  columns,
  mainUrl,
  additionalStore,
  getFields,
};
