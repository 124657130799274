export const getFormFields = () => [
  {
    name: 'name',
    componentName: 'input',
    params: {
      i18nLabel: 'permissions.name',
    },
    rules: [
      { required: true },
    ],
  },
  {
    name: 'slugname',
    componentName: 'input',
    params: {
      i18nLabel: 'permissions.slugname',
    },
    rules: [
      { required: true },
    ],
  },
  {
    name: 'methods',
    componentName: 'permissions',
    params: {
      i18nLabel: 'permissions.methods',
    },
    rules: [
    ],
  },
];
