import { FormattedMessage } from 'react-intl';

import { api } from '@/api';
import { PERMISSION_OBJ_APPROVER_CONFIGS } from '@/constants/permissions';
import { usersStore, approverConfigsStore } from '@/stores';
import {
  buildDropdownOptionsFromState, buildStateForApprovers, buildStoreOptions, getStorePropById,
} from '@/utils/common';

export const createState = 'base-layout.approverConfigs.create';
export const editState = 'base-layout.approverConfigs.edit';
export const listState = 'base-layout.approverConfigs';

export const i18nPrefix = 'approverConfigs';

export const mainUrl = '/approver-configs';

export const permissionObj = PERMISSION_OBJ_APPROVER_CONFIGS;
export const store = approverConfigsStore;
export const apiMethods = api.approverConfigs;

export const getFields = ({ isCreate, formValue }) => {
  const fields = [
    {
      name: 'approverUserId',
      componentName: 'dropdown',
      params: {
        i18nLabel: 'approverConfigs.approverUserId',
        options: buildDropdownOptionsFromState(buildStateForApprovers(usersStore.items)),
        disabled: false,
      },
      rules: [{ required: true }, {
        message: "Approver shouldn't be blocked",
        validator: (_, value) => {
          if (buildStateForApprovers(usersStore.items).some((user) => user.id === value)) {
            return Promise.resolve();
          }
          return Promise.reject("Approver shouldn't be blocked");
        },
      }],
    },
    {
      name: 'substitutorUserId',
      componentName: 'dropdown',
      params: {
        i18nLabel: 'approverConfigs.substitutorUserId',
        options: buildDropdownOptionsFromState(usersStore.items),
        disabled: false,
      },
      rules: [{ required: true }, {
        message: "Substitutor shouldn't be blocked",
        validator: (_, value) => {
          if (buildStateForApprovers(usersStore.items).some((user) => user.id === value)) {
            return Promise.resolve();
          }
          return Promise.reject("Substitutor shouldn't be blocked");
        },
      }],
    },
  ];
  if (!isCreate) {
    const currentApprover = usersStore.items.find((user) => user.id === formValue?.approverUserId);
    const currentSubstitutor = usersStore.items.find((user) => user.id === formValue?.substitutorUserId);
    if (currentApprover?.blocked) {
      fields[0].params.options.push({ value: currentApprover.id, label: currentApprover.name });
    }
    if (currentSubstitutor?.blocked) {
      fields[1].params.options.push({ value: currentSubstitutor.id, label: currentSubstitutor.name });
    }
  }
  return fields.map((field) => {
    if (field.name === 'approverUserId' && formValue) {
      return {
        ...field,
        params: {
          ...field.params,
          options: field.params.options.filter((option) => option.value !== formValue.substitutorUserId),
        },
      };
    }
    if (field.name === 'substitutorUserId' && formValue) {
      return {
        ...field,
        params: {
          ...field.params,
          options: field.params.options.filter((option) => option.value !== formValue.approverUserId),
        },
      };
    }
    return field;
  });
};

export const columns = () => [
  {
    title: 'id',
    dataIndex: 'id',
    sorter: true,
  },
  {
    title: <FormattedMessage id="approverConfigs.approverUserId" />,
    dataIndex: 'approverUserId',
    sorter: true,
    filters: buildStoreOptions(usersStore, 'name'),
    filterMultiple: true,
    render: (itemId: string) => getStorePropById<mpg.api.users.User>(itemId, usersStore),
  },
  {
    title: <FormattedMessage id="approverConfigs.substitutorUserId" />,
    dataIndex: 'substitutorUserId',
    sorter: true,
    filters: buildStoreOptions(usersStore, 'name'),
    filterMultiple: true,
    render: (itemId: string) => getStorePropById<mpg.api.users.User>(itemId, usersStore),
  },
];

export default {
  listState,
  createState,
  editState,
  i18nPrefix,
  permissionObj,
  store,
  apiMethods,
  columns,
  additionalStore: usersStore,
  mainUrl,
  getFields,
};
