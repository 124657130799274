import { GenericFormResourceController } from '@/components/generic-form';
import { getValuesDifference } from '@/utils/common';

export const withoutRepeatValue = (
  initValues: any,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  { create, update = (values: any) => Promise.resolve() },
): GenericFormResourceController => ({
  create,
  update: (values: any) => {
    const { changedItem } = getValuesDifference(initValues, values);
    return update(changedItem);
  },
});
