import { request } from '@/utils/request';
import { ApiResponse, WrappedResult } from './types';

export default {
  getAll(params?: mpg.api.positions.GetParams): ApiResponse<WrappedResult<mpg.api.positions.Position[]>> {
    return request({
      method: 'get',
      url: '/positions',
      params,
    });
  },
  get(id): ApiResponse<WrappedResult<mpg.api.positions.Position>> {
    return request({
      method: 'get',
      url: `/positions/${id}`,
    });
  },
  create(data: mpg.api.positions.PositionParams): ApiResponse<WrappedResult<mpg.api.positions.Position>> {
    return request({
      method: 'post',
      url: '/positions',
      data,
    });
  },
  update(id: number, data: mpg.api.positions.PositionParams): ApiResponse<WrappedResult<mpg.api.positions.Position>> {
    return request({
      method: 'patch',
      url: `/positions/${id}`,
      data,
    });
  },
  delete(id: number): ApiResponse<WrappedResult<any>> {
    return request({
      method: 'delete',
      url: `/positions/${id}`,
    });
  },
  bulkCreate(data: { items: mpg.api.positions.Position[] }): ApiResponse<WrappedResult<mpg.api.positions.Position>> {
    return request({
      method: 'post',
      url: '/positions/bulk/create',
      data,
    });
  },

  bulkUpdate(data: { items: mpg.api.positions.Position[] }): ApiResponse<WrappedResult<mpg.api.positions.Position>> {
    return request({
      method: 'post',
      url: '/positions/bulk/update',
      data,
    });
  },

  bulkDelete(data: { ids: string[] }): ApiResponse<WrappedResult<mpg.api.positions.Position>> {
    return request({
      method: 'post',
      url: '/positions/bulk/delete',
      data,
    });
  },
};
