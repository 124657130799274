import TextArea from 'antd/es/input/TextArea';
import { InputNumber, Input } from '@/components/antd';

const inputValidator = (_, inputValue) => {
  if (inputValue >= 0) {
    return Promise.resolve();
  }
  return Promise.reject('Value should be non negative');
};

export const formSetup = [
  {
    sectionLabel: 'Application settings',
    items: [
      {
        label: `application-settings.ALLOW_CHECK_IN_BEFORE_EVENT_START_MINUTES`,
        name: 'ALLOW_CHECK_IN_BEFORE_EVENT_START_MINUTES',
        formItem: <InputNumber name="setting" className="user-settings-input" />,
        rules: [
          {
            validator: inputValidator,
            message: 'Value should be non negative',
          },
        ],
      },
      {
        label: `application-settings.ARCHIVE_DATA_INTERVAL_DAYS`,
        name: 'ARCHIVE_DATA_INTERVAL_DAYS',
        formItem: <InputNumber name="setting" className="user-settings-input" />,
        rules: [
          {
            validator: inputValidator,
            message: 'Value should be non negative',
          },
        ],
      },
      {
        label: `application-settings.FW_REQUEST_BATCH_SIZE`,
        name: 'FW_REQUEST_BATCH_SIZE',
        formItem: <InputNumber name="setting" className="user-settings-input" />,
        rules: [
          {
            validator: inputValidator,
            message: 'Value should be non negative',
          },
        ],
      },
      {
        label: `application-settings.UPDATE_WEEK_TIMESHEET_TIMEOUT`,
        name: 'UPDATE_WEEK_TIMESHEET_TIMEOUT',
        formItem: <InputNumber name="setting" className="user-settings-input" />,
        rules: [
          {
            validator: inputValidator,
            message: 'Value should be non negative',
          },
        ],
      },
      {
        label: `application-settings.WF_SYNC_TIMEOUT`,
        name: 'WF_SYNC_TIMEOUT',
        formItem: <InputNumber name="setting" className="user-settings-input" />,
        rules: [
          {
            validator: inputValidator,
            message: 'Value should be non negative',
          },
        ],
      },
    ],
  },
  {
    sectionLabel: 'Timesheet settings',
    items: [
      {
        label: `application-settings.TIMESHEET_APPROVAL_THRESHOLD_IN_DAYS`,
        name: 'TIMESHEET_APPROVAL_THRESHOLD_IN_DAYS',
        formItem: <InputNumber name="setting" className="user-settings-input" />,
        rules: [
          {
            validator: inputValidator,
            message: 'Value should be non negative',
          },
        ],
      },
      {
        label: `application-settings.TIMESHEET_APPROVING_NOTIFICATION_THRESHOLD_IN_DAYS`,
        name: 'TIMESHEET_APPROVING_NOTIFICATION_THRESHOLD_IN_DAYS',
        formItem: <InputNumber name="setting" className="user-settings-input" />,
        rules: [
          {
            validator: inputValidator,
            message: 'Value should be non negative',
          },
        ],
      },
      {
        label: `application-settings.TIMESHEET_AUTO_APPROVAL_INTERVAL_DAYS`,
        name: 'TIMESHEET_AUTO_APPROVAL_INTERVAL_DAYS',
        formItem: <InputNumber name="setting" className="user-settings-input" />,
        rules: [
          {
            validator: inputValidator,
            message: 'Value should be non negative',
          },
        ],
      },
    ],
  },
  {
    sectionLabel: 'SFTP URLs configuration',
    items: [
      {
        label: `application-settings.host`,
        name: 'host',
        formItem: <Input className="user-settings-input" />,
      },
      {
        label: `application-settings.port`,
        name: 'port',
        formItem: <Input className="user-settings-input" />,
      },
      {
        label: `application-settings.privateKey`,
        name: 'privateKey',
        formItem: <TextArea rows={6} className="user-settings-textArea" />,
      },

      {
        label: `application-settings.username`,
        name: 'username',
        formItem: <Input className="user-settings-input" />,
      },
      {
        label: `application-settings.password`,
        name: 'password',
        formItem: <Input className="user-settings-input" />,
      },
      {
        label: `application-settings.dir`,
        name: 'dir',
        formItem: <Input className="user-settings-input" />,
      },
    ],
  },
];
