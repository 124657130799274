import { UIViewInjectedProps } from '@uirouter/react';
import React, { useEffect, useState } from 'react';

import { Moment } from 'moment/moment';
import { colorsCalendarStore, colorsStore } from '@/stores';
import { getTranslatedString } from '@/utils';
import { DrawerForm } from '@/components/drawer-form';
import { Form as AntdForm } from '@/components/antd';

import { getFormFields } from './setup';
import { api } from '@/api';

const entity = 'base-layout.color-calendar';

export const BulkAssignColorsForm = ({ transition }: UIViewInjectedProps) => {
  const { selectedDates } = transition.targetState().params();
  const [formRef] = AntdForm.useForm();
  const [loaderCondition, setLoaderCondition] = useState(false);
  const { items } = colorsCalendarStore;
  const onClose = () => {
    transition.router.stateService.go(entity);
  };
  const title = getTranslatedString('color-calendar.bulk-assign-colors');
  const selectedColors = items.filter(item => item.date === selectedDates[0].format('YYYY-MM-DD'));
  const initValues = selectedDates?.length === 1 ? { colors: selectedColors.map(item => item.colorId) } : {};
  const allowedFields = getFormFields(
    selectedColors.map(color => {
      if (color.color.scheduleType !== 'calendar') {
        return {
          label: color.color.name,
          value: color.colorId,
          disabled: true,
        };
      }
      return {
        label: color.color.name,
        value: color.colorId,
      };
    }),
  );

  const resourceController = {
    create: values => {
      const colorsIds = values.colors;
      const data = [];
      selectedDates.forEach((date: Moment) => {
        colorsIds.forEach((colorId: string) => {
          data.push({ date: date.format('YYYY-MM-DD'), colorId });
        });
      });
      const filteredInitialValues = items.filter(
        item => !selectedDates.find(date => item.date === date.format('YYYY-MM-DD')),
      );

      const mappedInitValues = filteredInitialValues.map(initialValueItem => ({
        date: initialValueItem.date,
        colorId: initialValueItem.colorId,
      }));
      const updatedData = [...data, ...mappedInitValues];
      return api.colorCalendar.create({ items: updatedData }).source.then(() => {
        colorsCalendarStore.refresh();
      });
    },
  };

  useEffect(() => {
    if (!selectedDates?.length) {
      onClose();
    }
    Promise.all([
      colorsStore.forceLoad({ limit: 1000, sort: 'name:1' }),
      colorsCalendarStore.forceLoad({ limit: 1000, sort: 'name:1' }),
    ]).then(() => {
      setLoaderCondition(false);
    });
  }, []);

  return (
    <DrawerForm
      form={formRef}
      onClose={onClose}
      title={title}
      initValues={initValues}
      formFields={allowedFields}
      resourceController={resourceController}
      loaderCondition={loaderCondition}
      width={(window.innerWidth - 300) / 2}
    />
  );
};
