import React from 'react';
import { FormattedMessage } from 'react-intl';
import { notification } from '@/components/antd';

import { CheckOutlined, CloseOutlined } from '@/components/icons';
import { IKeyValueObject } from '@/types/common';

export const showError = (err: any) => {
  notification.error({
    message: err.message,
    description: err.response?.data?.error?.message,
    duration: 3,
  });
};

export const booleanFormatted = (value: boolean) =>
  value ? <CheckOutlined style={{ color: 'green' }} /> : <CloseOutlined style={{ color: 'red' }} />;

export const buildDropdownOptionsFromEnum = (enumObject: IKeyValueObject, prefix: string = '') =>
  Object.values(enumObject).map((item: string) => ({
    value: item,
    label: prefix ? <FormattedMessage id={`${prefix}${item}`} /> : item,
  }));

export const buildDropdownOptionsFromState = (items: any) =>
  items.map((item: any) => ({ value: item.id, label: `${item.name}` }));

export const buildStateForApprovers = (items: any) => items.filter((item: any) => !item.blocked);

/**
 * Copy/Paste from fw-ext-frontend
 */
export const buildFilterOptionsFromEnum = (enumObject: IKeyValueObject, i18nPrefix: string) =>
  Object.values(enumObject).map((item: string) => ({
    text: <FormattedMessage id={`${i18nPrefix}${item}`} />,
    value: item,
  }));

export const buildStoreOptions = (store: any, labelKey: string) =>
  [...store.items]
    .sort((aValue, bValue) => {
      const a = (aValue[labelKey] || '').toLowerCase();
      const b = (bValue[labelKey] || '').toLowerCase();

      if (b === 'n/a') {
        return -1;
      }

      return a > b ? 1 : a < b ? -1 : 0;
    })
    .map((item: any) => ({ value: item.id, label: item[labelKey], text: item[labelKey] }));

export const buildFilteredStoreOptions = (store: any, labelKey: string, filter) =>
  [...store.items]
    .sort((aValue, bValue) => {
      const a = (aValue[labelKey] || '').toLowerCase();
      const b = (bValue[labelKey] || '').toLowerCase();

      if (b === 'n/a') {
        return -1;
      }

      return a > b ? 1 : a < b ? -1 : 0;
    })
    .filter(filter)
    .map((item: any) => ({ value: item.id, label: item[labelKey], text: item[labelKey] }));

export function getStorePropById<T extends { id: string }>(uid: string, store: any, labelKey: string = 'name') {
  const foundItem = store.items.find(({ id }: T) => id === uid);
  return foundItem && foundItem.hasOwnProperty(labelKey) ? foundItem[labelKey] : uid;
}

export const findItemIdByLabel = (store: any, labelKey: string, value: any) => {
  const foundedItem = store.items.find((item: any) => item[labelKey] === value);
  return foundedItem && foundedItem.id;
};

export function formatBytes(bytes: number, decimals?: number) {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const dm = decimals || 2;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  const size = parseFloat((bytes / k ** i).toFixed(dm));

  return `${size} ${sizes[i]}`;
}

const getValue = (value: any) => {
  if (typeof value === 'undefined' || value === '') {
    return null;
  }
  return value;
};

export const getValues = (values: any) =>
  Object.keys(values).reduce(
    (acc: any, key: string) => ({
      ...acc,
      [key]: getValue(values[key]),
    }),
    {},
  );

export function getValuesDifference(oldValues: any, newValues: any, skipProperties: string[] = []) {
  const changedProperties = Array.from(new Set([...Object.keys(newValues)]))
    .filter((key: string) => newValues[key] !== oldValues[key])
    .filter((key: string) => !skipProperties.includes(key));

  const originalItem = changedProperties.reduce(
    (acc: any, key: string) => ({
      ...acc,
      [key]: getValue(oldValues[key]),
    }),
    {},
  );

  const changedItem = changedProperties.reduce(
    (acc: any, key: string) => ({
      ...acc,
      [key]: getValue(newValues[key]),
    }),
    {},
  );

  return {
    originalItem,
    changedProperties,
    changedItem,
  };
}

export const onPrint = () => {
  const root = document.getElementById('root');
  root.classList.add('print');
  window.print();
  root.classList.remove('print');
};

export const capitalizeFirstLetter = (string: string) => string.charAt(0).toUpperCase() + string.slice(1);

export const mergeTwoArrays = (initialValues, filteredValues) => {
  const optionsMap = new Map();
  const mergedOptions = [...initialValues, ...filteredValues];
  mergedOptions.forEach(item => {
    if (!optionsMap.has(item.value)) {
      optionsMap.set(item.value, item);
    }
  });
  return Array.from(optionsMap.values());
};
