import { FormattedMessage } from 'react-intl';
import { Space } from '@/components/antd';

import { ReadAction } from '@/components/table/actions';
import {
  PERMISSION_READ,
  PERMISSION_OBJ_EMPLOYEES_SYNC_ERRORS,
} from '@/constants/permissions';
import { withPermissions } from '@/hocs/permission';
import { getFormattedDate } from '@/utils/moment';

const PermissionedReadAction = withPermissions([[PERMISSION_OBJ_EMPLOYEES_SYNC_ERRORS, PERMISSION_READ]])(ReadAction);

const Actions = (_: any, { id }: any) => (
  <Space>
    <PermissionedReadAction
      uiSref={{ to: 'base-layout.employeesSyncErrors.edit', params: { employeesSyncErrors: id } }}
    />
  </Space>
);

const DateComponent = (value: any) => (
  <Space>
    {getFormattedDate(value)}
  </Space>
);

export const columns = [
  {
    title: 'id',
    dataIndex: 'id',
    sorter: true,
  },
  {
    title: <FormattedMessage id="employeesSyncErrors.params" />,
    dataIndex: 'params',
    sorter: true,
    render: (value) => JSON.stringify(value, null, 2),
  },
  {
    title: <FormattedMessage id="employeesSyncErrors.errorResponse" />,
    dataIndex: 'errorResponse',
    sorter: true,
  },
  {
    title: <FormattedMessage id="employeesSyncErrors.type" />,
    dataIndex: 'type',
    sorter: true,
  },
  {
    title: <FormattedMessage id="employeesSyncErrors.ranAt" />,
    dataIndex: 'ranAt',
    sorter: true,
    render: DateComponent,
  },
  {
    title: <FormattedMessage id="table.actions" />,
    render: Actions,
  },
];
