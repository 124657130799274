import { buildFilteredStoreOptions, mergeTwoArrays } from '@/utils/common';
import { colorsStore } from '@/stores';

export const getFormFields = initialValues => [
  {
    name: 'colors',
    componentName: 'dropdown',
    isDisabledTagsRemovable: true,
    params: {
      i18nLabel: 'color-calendar.colors',
      options: mergeTwoArrays(
        initialValues,
        buildFilteredStoreOptions(colorsStore, 'name', item => item?.scheduleType === 'calendar'),
      ),
      mode: 'multiple',
    },
  },
];
