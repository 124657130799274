import { useState, useEffect, useCallback } from 'react';
import moment, { Moment } from 'moment/moment';

enum Key {
  Shift = 'Shift',
  Command = 'Meta',
  Control = 'Control',
}

function getArrayDaysFromRange(startDate: Moment, endDate: Moment) {
  const diff = moment.duration(startDate.diff(endDate)).asDays();
  const momentMethod = diff > 0 ? 'subtract' : 'add';
  const days = [];

  for (let i = 0; i <= Math.abs(diff); i++) {
    const date = moment(startDate)[momentMethod](i, 'days');
    days.push(date);
  }

  return days;
}

export const useKeysPressed = () => {
  const [isShiftPressed, setIsShiftPressed] = useState<boolean>(false);
  const [isCtrlPressed, setIsCtrlPressed] = useState<boolean>(false);

  useEffect(() => {
    const selectStartFn = (event: KeyboardEvent) => {
      document.onselectstart = () => !(event.key === Key.Shift && event.shiftKey);
    };

    const handleKey = (event: KeyboardEvent, isPressed: boolean) => {
      if (event.key === Key.Shift) {
        setIsShiftPressed(isPressed);
      }

      if (event.key === Key.Command || event.key === Key.Control) {
        setIsCtrlPressed(isPressed);
      }

      selectStartFn(event);
    };

    document.addEventListener('keydown', e => handleKey(e, true));
    document.addEventListener('keyup', e => handleKey(e, false));

    return () => {
      document.removeEventListener('keydown', e => handleKey(e, true));
      document.removeEventListener('keyup', e => handleKey(e, false));
    };
  }, []);

  return {
    isCtrlPressed,
    isShiftPressed,
  };
};
interface UseSelectProps {
  isCtrlPressed: boolean;
  isShiftPressed: boolean;
  onMultiSelect?: (selectedDates: Array<Moment>) => void;
}

export const useSelect = ({ isCtrlPressed, isShiftPressed, onMultiSelect }: UseSelectProps) => {
  const [startDate, setStartDate] = useState<Moment | null>(null);
  const [selectedDates, setSelectedDates] = useState<Array<Moment>>([]);

  useEffect(() => {
    onMultiSelect && onMultiSelect(selectedDates);
  }, [selectedDates]);

  useEffect(() => {
    const cells = document.getElementsByClassName('ant-picker-cell') as HTMLCollectionOf<
      Element & { title: string; date: string }
    >;

    for (let i = 0; i < cells.length; i++) {
      if (cells[i].hasAttribute('title')) {
        cells[i].setAttribute('date', cells[i]?.title);
        cells[i].removeAttribute('title');
      }

      const isSelectedCell = selectedDates.some(selectedDate =>
        selectedDate.isSame(moment(cells[i]?.getAttribute('date')), 'day'),
      );

      const classListFn = isSelectedCell ? 'add' : 'remove';
      cells[i].classList[classListFn]('ant-picker-cell-selected');
    }
  }, [selectedDates, startDate]);

  const onSelect = useCallback(
    (selectedDate: Moment) => {
      if (isShiftPressed && startDate) {
        const days = getArrayDaysFromRange(startDate, selectedDate);
        return setSelectedDates(days);
      }

      if (isCtrlPressed) {
        const alreadySelected = selectedDates.some(date => date.isSame(selectedDate));
        return setSelectedDates(prev =>
          alreadySelected ? prev.filter(date => !date.isSame(selectedDate)) : [...prev, selectedDate],
        );
      }

      setStartDate(selectedDate);
      setSelectedDates([selectedDate]);
    },
    [isShiftPressed, isCtrlPressed, startDate, selectedDates],
  );

  return { onSelect };
};
