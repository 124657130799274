import { FormattedMessage } from 'react-intl';

import { api } from '@/api';
import { BooleanValueComponent, BooleanValueRender } from '@/components/table/renders';
import { PERMISSION_OBJ_SHIFT } from '@/constants/permissions';
import { buildingsStore, shiftsStore } from '@/stores';
import {
  buildDropdownOptionsFromState,
  buildFilterOptionsFromEnum,
  buildStoreOptions,
  getStorePropById,
} from '@/utils/common';
import { getColumnBooleanFields, getColumnSearchFields } from '@/components/table/filters';
import { ShiftType } from '@/types/enums';
import { getTranslatedString } from "@/utils";

export const createState = 'base-layout.shifts.create';
export const editState = 'base-layout.shifts.edit';
export const bulkEditState = 'base-layout.shifts.bulk-edit';
export const listState = 'base-layout.shifts';

export const i18nPrefix = 'shifts';

export const mainUrl = '/shifts';

export const permissionObj = PERMISSION_OBJ_SHIFT;
export const store = shiftsStore;
export const apiMethods = api.shifts;
const createInitValues = {
  enableShiftPlanning: false,
};

const weekOptions = [
  { label: 'S', value: 'sunday', text: 'Sunday' },
  { label: 'M', value: 'monday', text: 'Monday' },
  { label: 'T', value: 'tuesday', text: 'Tuesday' },
  { label: 'W', value: 'wednesday', text: 'Wednesday' },
  { label: 'T', value: 'thursday', text: 'Thursday' },
  { label: 'F', value: 'friday', text: 'Friday' },
  { label: 'S', value: 'saturday', text: 'Saturday' },
];

export const getFields = ({ isBulkUpdate = false, isCreate = true }) => {
  const fields = [
    {
      name: 'name',
      componentName: 'input',
      params: {
        i18nLabel: 'shifts.name',
      },
      rules: [{ required: true }],
    },
    {
      name: 'start',
      componentName: 'datepicker',
      params: {
        i18nLabel: 'shifts.start',
        picker: 'time',
        format: 'HH:mm',
      },
      rules: [{ required: true }],
    },
    {
      name: 'end',
      componentName: 'datepicker',
      params: {
        i18nLabel: 'shifts.end',
        picker: 'time',
        format: 'HH:mm',
      },
      rules: [{ required: true }],
    },
    {
      name: 'type',
      componentName: 'dropdown',
      params: {
        i18nLabel: 'shifts.type',
        options: [
          { value: 'day', label: 'Day' },
          { value: 'night', label: 'Night' },
        ],
      },
      rules: [{ required: true }],
    },
    {
      name: 'buildingId',
      componentName: 'dropdown',
      params: {
        i18nLabel: 'shifts.building',
        options: buildDropdownOptionsFromState(buildingsStore.items),
        disabled: false,
      },
      rules: [{ required: true }],
    },
    {
      name: 'enableShiftPlanning',
      componentName: 'switch',
      params: {
        i18nLabel: 'shifts.enableShiftPlanning',
      },
      rules: [{ required: true }],
    },
    {
      name: 'active',
      componentName: 'switch',
      params: {
        i18nLabel: 'shifts.status',
        initialValue: true,
      },
      rules: [{ required: true }],
    },
    {
      name: 'weekDays',
      componentName: 'checkbox-group',
      params: {
        initialValue: true,
        i18nLabel: 'shifts.weekDays',
        options: weekOptions,
      },
    },
  ];
  return fields.map(field => {
    if (isBulkUpdate) {
      field.rules = field.rules.filter(({ required }) => !required);
    }
    if (field.name === 'buildingId' && !isCreate) {
      return {
        ...field,
        params: {
          ...field.params,
          disabled: true,
        },
      };
    }
    return field;
  });
};

export const columns = () => [
  {
    title: 'id',
    dataIndex: 'id',
    sorter: true,
  },
  {
    title: <FormattedMessage id="shifts.name" />,
    dataIndex: 'name',
    ...getColumnSearchFields(),
    sorter: true,
  },
  {
    title: <FormattedMessage id="shifts.start" />,
    dataIndex: 'start',
    ...getColumnSearchFields(),
    sorter: true,
  },
  {
    title: <FormattedMessage id="shifts.end" />,
    dataIndex: 'end',
    ...getColumnSearchFields(),
    sorter: true,
  },
  {
    title: <FormattedMessage id="shifts.type" />,
    dataIndex: 'type',
    filters: buildFilterOptionsFromEnum(ShiftType, 'shifts.type.'),
    filterMultiple: true,
    sorter: true,
    render: (item: string) => (item === 'day' ? 'Day' : 'Night'),
  },
  {
    title: <FormattedMessage id="shifts.building" />,
    dataIndex: 'buildingId',
    filters: buildStoreOptions(buildingsStore, 'name'),
    filterMultiple: true,
    sorter: true,
    render: (itemId: string) => getStorePropById<mpg.api.buildings.Building>(itemId, buildingsStore),
  },
  {
    title: <FormattedMessage id="shifts.enableShiftPlanning" />,
    dataIndex: 'enableShiftPlanning',
    ...getColumnBooleanFields(),
    sorter: true,
    render: BooleanValueRender,
  },
  {
    title: <FormattedMessage id="shifts.status" />,
    dataIndex: 'active',
    ...getColumnBooleanFields(),
    sorter: true,
    render: value => <BooleanValueComponent value={value} />,
  },
  {
    title: <FormattedMessage id="shifts.weekDays" />,
    dataIndex: 'weekDays',
    render: (values: string[] | null) => {
      const fixedValues = values?.map(item => getTranslatedString(`shifts.${item}`));
      return fixedValues?.join(', ');
    },
    filters: weekOptions,
  },
];

export default {
  listState,
  createState,
  bulkEditState,
  editState,
  i18nPrefix,
  permissionObj,
  store,
  apiMethods,
  columns,
  additionalStore: buildingsStore,
  mainUrl,
  createInitValues,
  getFields,
};
