import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, notification } from '@/components/antd';
import { api } from '@/api';
import { AUTHORIZED_USER_INITIAL_STATE } from '@/constants';
import { authStore } from '@/stores';

export const Agreement = ({ transition }) => {
  const [agreementUrl, setAgreementUrl] = useState<undefined | string>();
  const [agreement, setAgreement] = useState<mpg.api.agreements.Agreement | undefined>();
  const handleAgree = async () => {
    try {
      await api.agreements.signAgreement(agreement.id);
      authStore.setIsAgreementSigned(true);
      transition.router.stateService.go(AUTHORIZED_USER_INITIAL_STATE);
    } catch (e) {
      notification.error({ message: e });
    }
  };
  const getAgreement = async () => {
    const resp = await api.agreements.get().source;
    setAgreement(resp.data);
    const pdfResp = await api.agreements.getPdf(resp.data.id).source;
    const file = new Blob([pdfResp], { type: 'application/pdf' });
    const fileURL = URL.createObjectURL(file);
    setAgreementUrl(fileURL);
  };
  useEffect(() => {
    getAgreement();
  }, []);
  return (
    <>
      {agreementUrl && (
        <iframe
          style={{ height: '90vh' }}
          title="agreement"
          src={`${agreementUrl}#toolbar=0`}
          width="100%"
          height="100%"
        />
      )}
      <div style={{ display: 'flex', justifyContent: 'flex-end', height: '9vh' }}>
        <Button style={{ margin: '20px 40px' }} type="primary" onClick={() => handleAgree()}>
          <FormattedMessage id="agreement-page.agree-button" />
        </Button>
      </div>
    </>
  );
};
