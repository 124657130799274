import { action, makeAutoObservable, observable } from 'mobx';

import notification from 'antd/lib/notification';
import { isObject } from '@uirouter/core';
import { api } from '@/api';
import { showError } from '@/utils/common';
import { getTranslatedString } from '@/utils';

export class ApplicationSettingsStore {
  @observable items;

  @observable loading;

  constructor() {
    makeAutoObservable(this);
  }

  @action getApplicationSettings = async () => {
    try {
      this.loading = true;
      const resp = await api.applicationSettings.getAll().source;
      const { items } = resp.data;
      const initialValues = {};
      items.forEach(item => {
        initialValues[item.key] = item.value;
      });
      this.items = initialValues;
    } catch (e) {
      showError(e);
    } finally {
      this.loading = false;
    }
  };

  @action postApplicationSettings = async values => {
    const formValues = Object.entries(values);

    const modifiedSettings = formValues.reduce((acc, [key, value]) => {
      const currentValue = this.items[key];
      if (isObject(currentValue)) {
        if (JSON.stringify(currentValue) !== JSON.stringify(value)) {
          acc.push(() =>
            api.applicationSettings.update({
              key,
              value: values[key],
            }),
          );
          return acc;
        }
      }

      if (currentValue !== value) {
        acc.push(() =>
          api.applicationSettings.update({
            key,
            value: values[key],
          }),
        );
      } else if (currentValue === undefined) {
        acc.push(() =>
          api.applicationSettings.update({
            key,
            value: values[key],
          }),
        );
      }
      return acc;
    }, []);
    if (Object.keys(modifiedSettings).length > 0) {
      try {
        await Promise.all(modifiedSettings.map((modifiedSetting)=>modifiedSetting()));
        notification.success({ message: getTranslatedString('application-settings.successful-submit') });
      } catch (e) {
        notification.error({ message: e });
      } finally {
        await this.getApplicationSettings();
      }
    }
  };
}
