import { FormattedMessage } from 'react-intl';

import { api } from '@/api';
import { PERMISSION_OBJ_BUILDINGS_AREAS } from '@/constants/permissions';
import { buildingsAreasStore } from '@/stores';
import { getColumnSearchFields } from '@/components/table/filters';
import { buildFilterOptionsFromEnum } from '@/utils/common';
import { AreaType, BuildingAreaType } from '@/types/enums';

export const createState = 'base-layout.buildingsAreas.create';
export const editState = 'base-layout.buildingsAreas.edit';
export const bulkEditState = 'base-layout.buildingsAreas.bulk-edit';
export const listState = 'base-layout.buildingsAreas';

export const i18nPrefix = 'buildingsAreas';

export const mainUrl = '/buildingsAreas';

export const permissionObj = PERMISSION_OBJ_BUILDINGS_AREAS;
export const store = buildingsAreasStore;
export const apiMethods = api.buildingsAreas;

export const fields = [
  {
    name: 'areaName',
    componentName: 'input',
    params: {
      disabled: true,
      i18nLabel: 'buildingsAreas.areaName',
    },
    rules: [{ required: true }],
  },
  {
    name: 'buildingName',
    componentName: 'input',
    params: {
      disabled: true,
      i18nLabel: 'buildingsAreas.buildingName',
    },
    rules: [{ required: true }],
  },
  {
    name: 'areaType',
    componentName: 'dropdown',
    params: {
      i18nLabel: 'buildingsAreas.areaType',
      options: [
        { value: 'working', label: `Billable` },
        { value: 'idle', label: `Non-Billable` },
      ],
    },
    rules: [{ required: true }],
  },
];

export const columns = [
  {
    title: 'id',
    dataIndex: 'id',
    sorter: true,
  },
  {
    title: <FormattedMessage id="buildingsAreas.areaName" />,
    dataIndex: 'areaName',
    ...getColumnSearchFields(),
    sorter: true,
  },
  {
    title: <FormattedMessage id="buildingsAreas.areaType" />,
    dataIndex: 'areaType',
    filters: buildFilterOptionsFromEnum(AreaType, 'buildingsAreas.areaType.'),
    render: value =>
      value === 'working' ? (
        <FormattedMessage id="buildingsAreas.areaType.working" />
      ) : (
        <FormattedMessage id="buildingsAreas.areaType.idle" />
      ),
    filterMultiple: true,
    sorter: true,
  },
  {
    title: <FormattedMessage id="buildingsAreas.buildingAreaType" />,
    dataIndex: 'buildingAreaType',
    filters: buildFilterOptionsFromEnum(BuildingAreaType, 'buildingsAreas.buildingAreaType.'),
    filterMultiple: true,
    sorter: true,
  },
  {
    title: <FormattedMessage id="buildingsAreas.buildingName" />,
    dataIndex: 'buildingName',
    ...getColumnSearchFields(),
    sorter: true,
  },
];

export default {
  listState,
  createState,
  bulkEditState,
  editState,
  i18nPrefix,
  permissionObj,
  store,
  apiMethods,
  columns,
  fields,
  mainUrl,
};
