import React, { useState } from 'react';
import { Resizable } from 'react-resizable';
import { Table as AntdTable, TableProps as AntdTableProps } from '@/components/antd';

import { Drawer } from '@/components/drawer';
import { CollectionStore, RelCollectionStore } from '@/stores/collection';

import { usePermissions, useStoreIntegration, useTableSettings } from './hooks';
import { SettingsTable } from './settings-table';

interface TableProps extends AntdTableProps<any> {
  tableRef?: any;
  defaultQueryParams?: any;
  store?: RelCollectionStore<string | number, any, any> | CollectionStore<string | number, any, any>;
  fillStore?: (params: any) => Promise<any>;
  checkPermissions?: boolean;
  permissionObj?: string;
  relId?: string | number;
  localStorageKey?: string;
}

const ResizableTitle = (props) => {
  const {
    onResize, width, onClick, ...restProps
  } = props;
  const [resizing, setResizing] = useState(false);

  if (!width) {
    return <th {...restProps} />;
  }

  return (
    <Resizable
      width={width}
      height={0}
      handle={(
        <span
          className="react-resizable-handle"
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      )}
      onResizeStart={() => setResizing(true)}
      onResizeStop={() => {
        setTimeout(() => {
          setResizing(false);
        });
      }}
      onResize={onResize}
      draggableOpts={{ enableUserSelectHack: false }}
    >
      <th
        onClick={(...args) => {
          if (!resizing && onClick) {
            onClick(...args);
          }
        }}
        {...restProps}
      />
    </Resizable>
  );
};

export const Table = (props: TableProps) => {
  const { onChange, pagination } = useStoreIntegration(props);
  const { allowedColumns } = usePermissions(props);
  const {
    closeModal,
    isModalOpen,
    defaultSettings,
    settings,
    setSettings,
    shownColumns,
  } = useTableSettings({ ...props, allowedColumns });

  return (
    <>
      <Drawer
        onClose={closeModal}
        visible={isModalOpen}
        title="Table settings"
      >
        <SettingsTable
          value={settings}
          setValue={setSettings}
          columns={allowedColumns}
          defaultSettings={defaultSettings}
        />
      </Drawer>
      <AntdTable
        className="c-table"
        showSorterTooltip={false}
        {...props}
        columns={shownColumns}
        onChange={onChange}
        pagination={pagination}
        bordered
        components={{
          header: {
            cell: ResizableTitle,
          },
        }}
      />
    </>
  );
};
