import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Form, Input } from '@/components/antd';
import { LockOutlined, UserOutlined } from '@/components/icons';

import { AUTHORIZED_USER_INITIAL_STATE } from '@/constants';
import { authStore } from '@/stores';
import { getTranslatedString } from '@/utils';

import { getResponseError } from '../handlers';
import { AuthLayout } from './auth-layout';

const Login = ({ onSuccessLogin }: any) => {
  const [state, setState] = useState({
    email: '',
    password: '',
    error: null,
  });

  const setStateValue = (key: string, value: string) => {
    setState((prev) => ({ ...prev, [key]: value }));
  };

  const onSignInClick = async () => {
    try {
      await authStore.loginWithEmail(state.email, state.password);
      onSuccessLogin();
    } catch (err) {
      setStateValue('error', getResponseError(err));
    }
  };

  const onEmailChange = (event) => setStateValue('email', event.target.value);
  const onPasswordChange = (event) => setStateValue('password', event.target.value);

  return (
    <AuthLayout>
      <Form
        name="login"
        requiredMark="optional"
      >
        <Form.Item
          name="email"
          rules={[{ required: true, message: getTranslatedString('auth.message.input-email') }]}
        >
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            name="email"
            type="email"
            id="email"
            value={state.email}
            onChange={onEmailChange}
          />
        </Form.Item>

        <Form.Item
          name="password"
          rules={[{ required: true, message: getTranslatedString('auth.message.input-password') }]}
        >
          <Input.Password
            prefix={<LockOutlined className="site-form-item-icon" />}
            name="password"
            type="password"
            id="password"
            value={state.password}
            onChange={onPasswordChange}
          />
        </Form.Item>

        {
          state && state.error
            ? (
              <Form.Item>
                <div className="auth-error-text">
                  {state.error}
                </div>
              </Form.Item>
            )
            : null
        }

        <Form.Item>
          <Button className="auth-button" onClick={onSignInClick} type="primary" htmlType="submit">
            <FormattedMessage id="auth.button.sing-in" />
          </Button>
        </Form.Item>
      </Form>
    </AuthLayout>
  );
};

export const AuthLoginForm = ({ transition }: any) => {
  const onSuccessLogin = () => transition.router.stateService.go(AUTHORIZED_USER_INITIAL_STATE);
  return (<Login onSuccessLogin={onSuccessLogin} />);
};
