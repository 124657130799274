import { Switch } from '@/components/form-fields/switch';
import { TextArea } from '@/components/form-fields/textarea';
import { Transfer } from '@/components/form-fields/transfer';
import { CreatePassword } from './create-password';
import { DateRangePicker } from './date-range-picker';
import { DatePicker } from './datepicker';
import { Dropdown } from './dropdown';
import { Input } from './input';
import { InputNumber } from './input-number';
import { InputWithDropdown } from './input-with-dropdown';
import { LabeledSpan } from './labeled-span';
import { Password } from './password';
import { Permissions } from './permissions';
import { PermissionTable } from './permissions-table';
import { Phone } from './phone';
import { TimePicker } from './timepicker';
import { Upload } from './upload';
import { CheckboxGroup } from './checkbox-group';

const fieldsFactory = (fieldType?: string): any => {
  switch (fieldType) {
    case 'input':
      return Input;
    case 'dropdown':
      return Dropdown;
    case 'input-number':
      return InputNumber;
    case 'input-with-dropdown':
      return InputWithDropdown;
    case 'datepicker':
      return DatePicker;
    case 'timepicker':
      return TimePicker;
    case 'labeled-span':
      return LabeledSpan;
    case 'transfer':
      return Transfer;
    case 'date-range-picker':
      return DateRangePicker;
    case 'switch':
      return Switch;
    case 'textarea':
      return TextArea;
    case 'permissions-table':
      return PermissionTable;
    case 'upload':
      return Upload;
    case 'phone':
      return Phone;
    case 'permissions':
      return Permissions;
    case 'create-password':
      return CreatePassword;
    case 'password':
      return Password;
    case 'checkbox-group':
      return CheckboxGroup;
    default:
      throw new Error(`Unknown field type ${fieldType}`);
  }
};

export {
  fieldsFactory,
  Phone,
  Upload,
  PermissionTable,
  Input,
  Dropdown,
  InputNumber,
  InputWithDropdown,
  DatePicker,
  LabeledSpan,
  Transfer,
  DateRangePicker,
  Switch,
  TextArea,
};
