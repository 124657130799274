import applicationSettings from '@/api/application-settings';
import agencies from './agencies';
import agenciesMarkup from './agencies-markup';
import approverConfigs from './approver-configs';
import areas from './areas';
import attachments from './attachments';
import audit from './audit';
import agreements from './agreements';
import breaktimes from './breaktimes';
import buildingConfigs from './building-configs';
import buildingPairs from './building-pairs';
import buildings from './buildings';
import buildingsAreas from './buildings-areas';
import colors from './colors';
import employees from './employees';
import employeesSyncErrors from './employees-sync-errors';
import languages from './languages';
import lines from './lines';
import lockers from './lockers';
import musterStationCaptains from './muster-station-captains';
import musterStations from './muster-stations';
import permissions from './permissions';
import permissionsObjects from './permissions-objects';
import positions from './positions';
import roles from './roles';
import search from './search';
import shifts from './shifts';
import supervisors from './supervisors';
import users from './users';
import workingLogs from './working-log';
import colorCalendar from './color-calendar';

export const api: { [key: string]: any } = {
  users,
  approverConfigs,
  applicationSettings,
  agreements,
  roles,
  permissions,
  search,
  colors,
  agencies,
  agenciesMarkup,
  positions,
  languages,
  areas,
  buildingPairs,
  buildingsAreas,
  breaktimes,
  buildings,
  buildingConfigs,
  employees,
  shifts,
  workingLogs,
  supervisors,
  attachments,
  permissionsObjects,
  audit,
  employeesSyncErrors,
  musterStations,
  musterStationCaptains,
  lines,
  lockers,
  colorCalendar
};
