import { FormattedMessage } from 'react-intl';

import { api } from '@/api';
import { PERMISSION_OBJ_BUILDING_PAIRS } from '@/constants/permissions';
import { buildingsStore, buildingPairsStore } from '@/stores';
import { buildDropdownOptionsFromState, buildStoreOptions, getStorePropById } from '@/utils/common';

export const createState = 'base-layout.buildingPairs.create';
export const editState = 'base-layout.buildingPairs.edit';
export const bulkEditState = 'base-layout.buildingPairs.bulk-edit';
export const listState = 'base-layout.buildingPairs';

export const i18nPrefix = 'buildingPairs';

export const mainUrl = '/building-pairs';

export const permissionObj = PERMISSION_OBJ_BUILDING_PAIRS;
export const store = buildingPairsStore;
export const apiMethods = api.buildingPairs;
const createInitValues = {
  enableShiftPlanning: false,
};
export const getFields = ({ formValue }) => {
  const fields = [
    {
      name: 'building1Id',
      componentName: 'dropdown',
      params: {
        i18nLabel: 'buildingPairs.building1Id',
        options: buildDropdownOptionsFromState(buildingsStore.items),
        disabled: false,
      },
      rules: [{ required: true }],
    },
    {
      name: 'building2Id',
      componentName: 'dropdown',
      params: {
        i18nLabel: 'buildingPairs.building2Id',
        options: buildDropdownOptionsFromState(buildingsStore.items),
        disabled: false,
      },
      rules: [{ required: true }],
    },
  ];
  return fields
    .map((field) => {
      if (field.name === 'building1Id' && formValue) {
        return {
          ...field,
          params: {
            ...field.params,
            options: field.params.options.filter((option) => option.value !== formValue.building2Id),
          },
        };
      }
      if (field.name === 'building2Id' && formValue) {
        return {
          ...field,
          params: {
            ...field.params,
            options: field.params.options.filter((option) => option.value !== formValue.building1Id),
          },
        };
      }
      return field;
    });
};

export const columns = () => [
  {
    title: 'id',
    dataIndex: 'id',
    sorter: true,
  },
  {
    title: <FormattedMessage id="buildingPairs.building1Id" />,
    dataIndex: 'building1Id',
    sorter: true,
    filters: buildStoreOptions(buildingsStore, 'name'),
    filterMultiple: true,
    render: (itemId: string) => getStorePropById<mpg.api.buildings.Building>(itemId, buildingsStore),
  },
  {
    title: <FormattedMessage id="buildingPairs.building2Id" />,
    dataIndex: 'building2Id',
    sorter: true,
    filters: buildStoreOptions(buildingsStore, 'name'),
    filterMultiple: true,
    render: (itemId: string) => getStorePropById<mpg.api.buildings.Building>(itemId, buildingsStore),
  },
];

export default {
  listState,
  createState,
  bulkEditState,
  editState,
  i18nPrefix,
  permissionObj,
  store,
  apiMethods,
  columns,
  additionalStore: buildingsStore,
  mainUrl,
  createInitValues,
  getFields,
};
