import { FormattedMessage } from 'react-intl';

import { api } from '@/api';
import { breaktimesStore } from '@/stores';
import { PERMISSION_OBJ_BREAKTIMES } from '@/constants/permissions';

export const createState = 'base-layout.breaktimes.create';
export const editState = 'base-layout.breaktimes.edit';
export const bulkEditState = 'base-layout.breaktimes.bulk-edit';
export const listState = 'base-layout.breaktimes';

export const i18nPrefix = 'breaktimes';

export const mainUrl = '/breaktimes';

export const permissionObj = PERMISSION_OBJ_BREAKTIMES;
export const store = breaktimesStore;
export const apiMethods = api.breaktimes;

export const fields = [
  {
    name: 'name',
    componentName: 'input',
    params: {
      i18nLabel: 'breaktimes.name',
    },
    rules: [{ required: true }],
  },
  {
    name: 'start',
    componentName: 'input',
    params: {
      i18nLabel: 'breaktimes.start',
    },
    rules: [{ required: true }],
  },
  {
    name: 'end',
    componentName: 'input',
    params: {
      i18nLabel: 'breaktimes.end',
    },
    rules: [{ required: true }],
  },

];

export const columns = [
  {
    title: 'id',
    dataIndex: 'id',
    sorter: true,
  },
  {
    title: <FormattedMessage id="breaktimes.name" />,
    dataIndex: 'name',
    sorter: true,
  },
  {
    title: <FormattedMessage id="breaktimes.start" />,
    dataIndex: 'start',
    sorter: true,
  },
  {
    title: <FormattedMessage id="breaktimes.end" />,
    dataIndex: 'end',
    sorter: true,
  },
];

export default {
  listState,
  createState,
  bulkEditState,
  editState,
  i18nPrefix,
  permissionObj,
  store,
  apiMethods,
  columns,
  fields,
  mainUrl,
};
