import { FormattedMessage } from 'react-intl';

import { api } from '@/api';
import { PERMISSION_OBJ_LINES } from '@/constants/permissions';
import { buildingsStore, linesStore } from '@/stores';
import { buildDropdownOptionsFromState, buildStoreOptions, getStorePropById } from '@/utils/common';
import { getColumnSearchFields } from '@/components/table/filters';

export const createState = 'base-layout.lines.create';
export const editState = 'base-layout.lines.edit';
export const bulkEditState = 'base-layout.lines.bulk-edit';
export const listState = 'base-layout.lines';

export const i18nPrefix = 'lines';

export const mainUrl = '/lines';

export const permissionObj = PERMISSION_OBJ_LINES;
export const store = linesStore;
export const additionalStore = buildingsStore;
export const apiMethods = api.lines;

export const getFields = (isBulkUpdate: boolean) => {
  const fields = [
    {
      name: 'name',
      componentName: 'input',
      params: {
        i18nLabel: 'buildings.name',
      },
      rules: [{ required: true }],
    },
    {
      name: 'buildingId',
      componentName: 'dropdown',
      params: {
        i18nLabel: 'musterStations.building',
        options: buildDropdownOptionsFromState(buildingsStore.items),
      },
      rules: [{ required: true }],
    },
  ];

  return fields
    .map((field) => {
      if (isBulkUpdate) {
        field.rules = field.rules.filter(({ required }) => !required);
      }
      return field;
    });
};

export const columns = () => [
  {
    title: 'id',
    dataIndex: 'id',
    sorter: true,
  },
  {
    title: <FormattedMessage id="lines.name" />,
    dataIndex: 'name',
    ...getColumnSearchFields(),
    sorter: true,
  },
  {
    title: <FormattedMessage id="lines.building" />,
    dataIndex: 'buildingId',
    filters: buildStoreOptions(buildingsStore, 'name'),
    filterMultiple: true,
    sorter: true,
    render: (itemId: string) => getStorePropById<mpg.api.buildings.Building>(itemId, buildingsStore),
  },
];

export default {
  listState,
  createState,
  bulkEditState,
  editState,
  i18nPrefix,
  permissionObj,
  store,
  apiMethods,
  columns,
  mainUrl,
  additionalStore,
  getFields,
};
