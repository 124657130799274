import { useEffect, useState } from 'react';
import { columns } from './setup';
import { Table } from '@/components/table';
import { api } from '@/api';

interface IProps {
  userId: number;
}
const SignedAgreements = ({ userId }: IProps) => {
  const [agreements, setAgreements] = useState<mpg.api.agreements.SignedAgreementResponse>(undefined);
  const getSignedAgreements = async () => {
    const resp = await api.agreements.getSignedAgreements(userId).source;
    setAgreements(resp.data);
  };
  useEffect(() => {
    getSignedAgreements();
  }, []);
  return (
    <Table
      loading={!agreements}
      rowKey="id"
      columns={columns()}
      dataSource={agreements ? agreements.items.map(i => ({ ...i })) : []}
      scroll={{ y: '100%' }}
    />
  );
};
export default SignedAgreements;
